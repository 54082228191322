import { Menu as HUI_Menu, MenuButton, MenuItems } from '@headlessui/vue'
import { vOptT } from 'src/helpers/utils'
import { Transition, defineComponent } from 'vue'

export { MenuItem } from "@headlessui/vue"

export interface MenuItemSlots {
  button: () => JSX.Element,
  menuItems: () => JSX.Element
}

export const Menu = defineComponent({
  props: {
    /**
     * adjust absolute pos of popover to be "left:0;" or "right:0" accordingly
     */
    anchor: vOptT<"left" | "right">("right")
  },
  setup(props, {slots}) {
    return () => {
      return (
        <div>
          <HUI_Menu as="div" class="relative inline-block text-left">
            <div>
              <MenuButton>
                {slots?.button?.()}
              </MenuButton>
            </div>

            <Transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class={`absolute ${props.anchor}-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none`}
                style="z-index:9999"
                data-MenuItems // can use `querySelector("some-root [data-MenuItems]")` to test for visibility
              >
                <div class="px-1 py-1">
                  {slots.menuItems?.()}
                </div>
              </MenuItems>
            </Transition>
          </HUI_Menu>
        </div>
      )
    }
  }
})


export const DefaultMenuButton = defineComponent({
  emits: {
    // yuck, not part of "IntrinisicProps" or whatever the builtin JSX type for a vue component is
    click: () => true
  },
  setup(_, {emit, slots}) {
    return () => (
      <button onClick={() => emit("click")} type="button" class="hover:bg-slate-100 active:bg-slate-200 group flex w-full items-center rounded-md px-2 py-2 text-sm">
        {slots.default?.()}
      </button>
    )
  }
})
