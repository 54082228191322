import { defineComponent, reactive, ref } from "vue";
import { FormKit } from "@formkit/vue";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { axiosInstance } from "src/boot/AxiosInstances";
import { exhaustiveCaseGuard, useIziToast } from "src/helpers/utils";
import { useRouter } from "vue-router";


import * as ilpublic from "src/composables/InleagueApiV1.Public"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import * as R_Self from "./R_TournamentTeamCreate.route"
import { routeToLoginForEmail } from "../Navigational/LoginModal.utils";
import { Client } from "src/store/Client";
import { Integerlike } from "src/interfaces/InleagueApiV1";
import { SendEmailToUserRequiringAysoID } from "./TournamentTeamCreate.loggedOut.sendEmail";

import { aysoIdLenientFormPattern } from "./TournamentTeamConfigurator.shared";

/**
 * The "logged out" version of "create tournament team" only offers the user a way to login via their ayso ID;
 *  - if we recognize the user by their Ayso ID, we show a login modal and on succesfull login, reroute back to the logged-in version
 *  - if we don't recognize the user, offer the user a way to create an account using their aysoID
 */
export const CreateTournamentTeamLoggedOut = defineComponent({
  name: "CreateTournamentTeamLoggedOut",
  setup() {
    const iziToast = useIziToast();

    const router = useRouter();

    const form = reactive({aysoID: "", lastName: ""})

    type LocalState =
      | null
      | {readonly phase: "lookup-result", readonly data: ilpublic.InleagueUserViaAyso}
      | {readonly phase: "did-send-email", readonly emailRecipient: string}

    const state = ref<LocalState>(null)

    const submit = async () : Promise<void> => {
      try {
        const data = await ilpublic.getInleagueUserViaAyso(axiosInstance, form);
        state.value = {phase: "lookup-result", data}
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    async function routeToLogin(email: string) : Promise<void> {
      if (state.value?.phase !== "lookup-result" || state.value.data.type !== "ayso-hit/inleague-users") {
        throw Error("wrong lookup result type");
      }

      await routeToLoginForEmail(
        router,
        email,
        {
          type: "vue-router-route",
          value: R_Self.routeDetailToRouteLocation({name: R_Self.RouteNames.ChooseSeason})
        }
      )
    }

    async function doSendNewUserViaAysoIdClaimEmail(region: Integerlike) : Promise<void> {
      if (state.value?.phase !== "lookup-result" || state.value.data.type !== "ayso-hit/no-inleague-user") {
        throw Error("wrong lookupResult type");
      }

      try {
        await ilpublic.sendNewUserViaAysoIdClaimEmail(
          axiosInstance, {
            aysoID: state.value.data.partialAysoUserData.aysoID,
            lastName: state.value.data.partialAysoUserData.lastName,
            region
          });
          iziToast.success({message: `An email was sent to ${state.value.data.partialAysoUserData.email} with a link to create your AYSOID-connected inLeague account.`})
          state.value = {
            phase: "did-send-email",
            emailRecipient: state.value.data.partialAysoUserData.email
          }
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
    }

    return () => (
      <div data-test="CreateTournamentTeamLoggedOut">
        <h2 class="mb-2">
          <FontAwesomeIcon icon={["fas", "users-cog"]}/>
          <span class="ml-2">Tournament Team Registration Portal</span>
          <p class="mt-2 mb-2">Tournament team registration is open to administrators authorized by their region to submit teams for tournaments.</p>
          <p class="mt-2 mb-2">If you have previously registered a tournament team or have an inLeague account with Region {Client.value.instanceConfig.region}, you can use the 'Login' button and then navigate to Tournament Team Admin to manage your teams.</p>
          <p class="mt-2 mb-4">To register a tournament team, start by entering your 11-digit AYSO ID number and your last name. </p>
        </h2>
        <FormKit type="form" onSubmit={submit} actions={false} data-test="lookup">
          <FormKit
            type="text"
            v-model={form.aysoID}
            validation={[["required"], ["matches", aysoIdLenientFormPattern.pattern]]}
            validationMessages={{matches: aysoIdLenientFormPattern.msg("AYSOID")}}
            label="AYSOID"
            data-test="aysoID"
          />
          <FormKit type="text" v-model={form.lastName} validation={[["required"]]} label="Last Name" data-test="lastName"/>
          <t-btn type="submit" margin={false} data-test="submit">Submit</t-btn>
        </FormKit>
        <div>
          {
            !state.value
              ? null
              : state.value.phase === "lookup-result"
              ? (
                state.value.data.type === "ayso-hit/inleague-users"
                  ? <OneOrSomeMatches lookup={state.value.data}/>
                  : state.value.data.type === "ayso-hit/no-inleague-user"
                  ? (
                    <div class="my-4">
                      <SendEmailToUserRequiringAysoID
                        aysoLookupResult={state.value.data}
                        onDoSendNewUserViaAysoIdClaimEmail={doSendNewUserViaAysoIdClaimEmail}
                      />
                    </div>
                  )
                  : state.value.data.type === "ayso-miss"
                  ? (
                    <div class="mt-2">
                      No volunteer matching that AYSO ID and last name could be found. To register a tournament team, you must be a current AYSO volunteer registered in one or more regions through an authorized registration platform (inLeague or SportsConnect).
                    </div>
                  )
                  : exhaustiveCaseGuard(state.value.data)
              )
              : state.value.phase === "did-send-email"
              ? <div>Email sent to {state.value.emailRecipient}</div>
              : exhaustiveCaseGuard(state.value)
          }
        </div>
      </div>
    )

    function OneOrSomeMatches({lookup}: {lookup: ilpublic.InleagueUserViaAyso_InleagueHit}) {
      if (lookup.users.length === 0) {
        // see api definition
        throw Error("illegal empty users array")
      }

      if (lookup.users.length === 1) {
        return (
          <div class="flex flex-col items-center justify-center">
            <div>The volunteer record matching this AYSO ID and last name has an inLeague account with this region. Please log in to that account to continue.</div>
            <t-btn data-test={`login/${lookup.users[0].email}`} type="button" margin={false} onClick={() => routeToLogin(lookup.users[0].email)}>Login as&nbsp;<u>{lookup.users[0].email}</u></t-btn>
          </div>
        )
      }
      else {
        return (
          <div class="flex flex-col items-center justify-center gap-2">
            <div>There are multiple volunteer records matching this AYSO ID and last name. Please log in to one of the following to continue.</div>
            {
              lookup.users.map(user => <t-btn data-test={`login/${user.email}`} type="button" margin={false} onClick={() => routeToLogin(user.email)}>Login as&nbsp;<u>{user.email}</u></t-btn>)
            }
          </div>
        )
      }
    }

  }
})
