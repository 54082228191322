import { boot } from 'quasar/wrappers'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faBell, faBellSlash, faClipboardUser, faUserXmark } from '@fortawesome/free-solid-svg-icons'

import {
  faGraduationCap,
  faTrashAlt,
  faThermometerEmpty,
  faEnvelopeOpen,
  faBellExclamation,
  faBookmark,
  faMeh,
  faPencilAlt,
  faTimesCircle,
  faCalendarDay,
  faSpinner,
  faMapMarkerAlt,
  faLock,
  faUsersCog,
  faUserPlus,
  faSearch,
  faArrowRight,
  faComments,
  faPlusCircle,
  faArrowLeft,
  faFlag,
  faUsers,
  faEnvelope,
  faEdit,
  faQuestion,
  faHome,
  faDollarSign,
  faPercent,
  faCog,
  faReceipt,
  faCreditCard,
  faFileInvoice,
  faIdBadge,
  faIdCard,
  faUserLock,
  faClipboardCheck,
  faTools,
  faCalendar,
  faBan,
  faEnvelopeOpenText,
  faCalendarAlt,
  faFileAlt,
  faPencil,
  faQuestionCircle,
  faStarHalfAlt,
  faStarHalf,
  faStar,
  faInfoCircle,
  faAngleDoubleRight,
  faAngleDoubleUp,
  faSyncAlt,
  faBook,
  faHomeAlt,
  faArrowCircleLeft,
  faArrowCircleRight,
  faPlusSquare,
  faMinusSquare,
  faUser,
  faCircle,
  faFutbol,
  faClipboardList,
  faAngleLeft,
  faAngleRight,
  faMapMarkedAlt,
  faChild,
  faFileCertificate,
  faCheckSquare,
  faCheck,
  faBallotCheck,
  faFileCheck,
  faHand,
  faCircleQuestion,
  faTriangleExclamation,
  faTable,
  faArrowUp,
  faArrowDown,
  faClone,
  faArrowsUpDown,
  faChevronRight,
  faFileExport,
  faTags,
  faHandsHelping,
  faTrafficLightStop,
  faTrafficLightGo,
  faTrophy,
  faListCheck,
  faPeopleGroup,
  faMerge,
  faTrophyStar,
  faGift,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faCircleQuestion as faCircleQuestion_regular,
} from '@fortawesome/pro-regular-svg-icons'
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome'
// import { faCheckSquare,   } from '@fortawesome/pro-regular-svg-icons'
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
} from '@fortawesome/free-brands-svg-icons'

export default boot(({ app }) => {
  library.add(
    faAngleDoubleUp,
    faCircleQuestion_regular,
    faGraduationCap,
    faTrashAlt,
    faChild,
    faThermometerEmpty,
    faEnvelopeOpen,
    faQuestionCircle,
    faPencil,
    faCircle,
    faFutbol,
    faBook,
    faBell,
    faBellSlash,
    faBellExclamation,
    faBookmark,
    faMeh,
    faPencilAlt,
    faTimesCircle,
    faCalendarDay,
    faSpinner,
    faMapMarkerAlt,
    faLock,
    faUsersCog,
    faUserPlus,
    faSearch,
    faArrowRight,
    faComments,
    faPlusCircle,
    faArrowLeft,
    faFlag,
    faUsers,
    faEnvelope,
    faEdit,
    faQuestion,
    faHome,
    faDollarSign,
    faPercent,
    faCog,
    faReceipt,
    faCreditCard,
    faFileInvoice,
    faCalendar,
    faCheckSquare,
    faCheck,
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faIdCard,
    faUserLock,
    faClipboardCheck,
    faTools,
    faBan,
    faEnvelopeOpenText,
    faCalendarAlt,
    faFileAlt,
    faStarHalfAlt,
    faStarHalf,
    faStar,
    faInfoCircle,
    faAngleDoubleRight,
    faSyncAlt,
    faHomeAlt,
    faArrowCircleLeft,
    faArrowCircleRight,
    faPlusSquare,
    faMinusSquare,
    faUser,
    faClipboardList,
    faAngleLeft,
    faAngleRight,
    faMapMarkedAlt,
    faFileCertificate,
    faIdBadge,
    faBallotCheck,
    faFileCheck,
    faHand,
    faCircleQuestion,
    faTriangleExclamation,
    faTable,
    faArrowUp,
    faArrowDown,
    faClone,
    faFileExport,
    faArrowsUpDown,
    faChevronRight,
    faFileExport,
    faUserXmark,
    faTags,
    faHandsHelping,
    faTrafficLightStop,
    faTrafficLightGo,
    faTrophy,
    faListCheck,
    faPeopleGroup,
    faClipboardUser,
    faMerge,
    faTrophyStar,
    faGift,
  )
  dom.watch()

  app.component('font-awesome-icon', FontAwesomeIcon)
  app.component('font-awesome-layers', FontAwesomeLayers)
  app.component('font-awesome-layers-text', FontAwesomeLayersText)
})
