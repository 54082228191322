import dayjs, { Dayjs } from "dayjs"
import { dayjsOr } from "src/helpers/formatDate"
import { Datelike, Guid, Integerlike } from "src/interfaces/InleagueApiV1"
import { Client } from "src/store/Client"

export function teamScheduleURL(args: {teamID: Guid, competitionID: Integerlike, division: string, seasonStart: Dayjs | Datelike | undefined, seasonWeeks: number | undefined}) {
  const seasonEnd = (() => {
    const DEFAULT_SEASON_WEEKS = 18
    const seasonStart = dayjsOr(args.seasonStart) ?? dayjs()
    return seasonStart.add(args.seasonWeeks ?? DEFAULT_SEASON_WEEKS, "weeks")
  })();

  const queryString = [
    `startDate=${encodeURIComponent(dayjs().format("MM/DD/YY"))}`,
    `endDate=${encodeURIComponent(seasonEnd.format("MM/DD/YY"))}`,
    `team=${encodeURIComponent(args.teamID)}`,
    `division=${encodeURIComponent(args.division)}`,
    `competition_id=${encodeURIComponent(args.competitionID)}`
  ].join("&")

  return `https://${Client.value.instanceConfig.appdomain}/gameSchedules/teamSchedule/?${queryString}`
}
