import dayjs from "dayjs";
import authService from "src/helpers/authService";
import { dayjsOr } from "src/helpers/formatDate";
import { RiskStatusType } from "src/interfaces/InleagueApiV1";
import { User } from "src/store/User";

export function hasSomeRoutePermission() : boolean {
  return currentUserIsReflikeUser() || (User.isLoggedIn && User.value.someCompThatDoesNotLimitRefAccessHasUpcomingGames)
}

export function currentUserIsReflikeUser() : boolean {
  return authService(User.userData?.roles ?? [], "webmaster", "refScheduler", "compManager", "ref", "ChiefRef");
}

export function currentUserHasValidRiskStatus() {
  const VALID = true;
  const INVALID = false;

  const canonical_disallowedRiskStatusColors : string[] = ([
    RiskStatusType.None,
    RiskStatusType.Red,
  ]).map(RiskStatusType.canonicalize)

  const canonical_userRiskStatus = RiskStatusType.canonicalize(User.userData?.RiskStatus.toLowerCase() || "")
  const hasNoRiskStatus = !canonical_userRiskStatus
  const hasDisallowedRiskStatus = canonical_disallowedRiskStatusColors.includes(canonical_userRiskStatus)
  // if there is no risk status expiration, treat it as expired
  const riskStatusExpired = dayjsOr(User.userData?.RiskStatusExpiration)?.isBefore(dayjs()) ?? true;

  if (hasNoRiskStatus || hasDisallowedRiskStatus || riskStatusExpired) {
    return INVALID;
  }

  return VALID;
}
