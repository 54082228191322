import { PropType, defineComponent, ref, watch } from 'vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

interface TabDef_ {
  label: string | {key: string, render: () => JSX.Element | null},
  ["data-test"]?: string,
  render: () => JSX.Element | null,
  /**
   * keep the tab element alive when navigating away from its tab
   * Not providing this is the same as keepAlive=false
   */
  keepAlive?: boolean
}

// T will usually be some enum, but many existing callers at the time of introducing a generic ID param
// do not provide such a thing. So in the default case, there is no `id` property; but one may be added
// by specifying an id type.
export type TabDef<T extends string | void = void> = TabDef_ & (T extends void ? {} : {id: T})

function isKeepAliveOnNavAway(tabDef: TabDef) : boolean {
  return tabDef.keepAlive === true;
}

function isUnmountOnNavAway(tabDef: TabDef) : boolean {
  return !isKeepAliveOnNavAway(tabDef); // unmount if we're not keepalive
}

export const Tabs = defineComponent({
  props: {
    tabDefs: {
      required: true,
      type: Array as PropType<TabDef<any>[]>
    },
    selectedIndex: {
      required: false,
      type: Number
    }
  },
  emits: {
    changeSelectedIndex: (idx: number) => true,
  },
  setup(props, {emit}) {
    const selectedIndex = ref(props.selectedIndex ?? 0);

    watch(() => props.selectedIndex, () => {
      if (typeof props.selectedIndex === "number") {
        selectedIndex.value = props.selectedIndex;
      }
    })

    const handleLocalSelectedIndexChange = (idx: number) => {
      selectedIndex.value = idx;
      emit("changeSelectedIndex", idx);
    }

    const tabKey = (tabDef: TabDef) => typeof tabDef.label === "string" ? tabDef.label : tabDef.label.key

    return () => (
      <div>
        <TabGroup selectedIndex={selectedIndex.value} onChange={handleLocalSelectedIndexChange}>
          <TabList class="flex space-x-1 rounded-xl bg-green-700 p-1">
            {
              props.tabDefs.map(tabDef => (
                <Tab
                  class="focus:outline-none"
                  data-test={tabDef["data-test"]}
                  key={tabKey(tabDef)}
                >
                  {
                    ({selected}: {selected:boolean}) => (
                      <div
                        class={`
                          w-full rounded-lg p-2.5 text-sm font-medium leading-5 text-blue-700

                          ${selected ? 'bg-white shadow' : 'text-white hover:bg-white/[0.12]'}`
                        }
                      >
                        {typeof tabDef.label === "string" ? tabDef.label : tabDef.label.render()}
                      </div>
                    )
                  }
                </Tab>
              ))
            }
          </TabList>
          <TabPanels class="mt-2" key={`panelsForIdx/${selectedIndex.value}`}>
            {
              props.tabDefs.map(tabDef => {
                return (
                  <TabPanel key={tabKey(tabDef)} unmount={isUnmountOnNavAway(tabDef)}>
                    {tabDef.render()}
                  </TabPanel>
                )
              })
            }
          </TabPanels>
        </TabGroup>
      </div>
    )
  }
})
