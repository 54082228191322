import { defineComponent } from "vue";

import { propsDef } from "./R_TournamentTeamCreate.route"
import { CreateTournamentTeamLoggedOut } from "./TournamentTeamCreate.loggedOut";
import { CreateTournamentTeamLoggedIn } from "./TournamentTeamCreate.loggedIn";
import { User } from "src/store/User";

export default defineComponent({
  name: "R_CreateTournamentTeam",
  props: propsDef,
  setup(props) {
    return () => User.isLoggedIn
      ? <CreateTournamentTeamLoggedIn {...props}/>
      : <CreateTournamentTeamLoggedOut/>
  }
})
