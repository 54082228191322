/**
 * This file represents api response shapes as received from the server,
 * prior to any required transformations required for use in the application
 */

import { Field } from "src/composables/InleagueApiV1";
import { UiOption } from "src/helpers/utils";
import { LineItem } from "src/interfaces/Store/checkout"; // warn: circular

//
// fixme -- move external api-centric the definitions into this file, beware circular dependencies
//
export type { VolunteerDetails, VolunteerPref } from "src/interfaces/volunteer"
export type { Invoice, LineItem as InvoiceLineItem, LineItemSpecialization as InvoiceLineItemOf } from "src/interfaces/Store/checkout"

// maybe bring these into this file
export type {
  ExtendedInfo,
  LatestMessage,
  ConversationInterface,
  MessageThreadMessage,
  MessageThreadExtendedInfo,
  MessageThreadLatestMessage,
  MessageThreadConversation,
  MessageThread,
} from "src/interfaces/messages";

export interface InleagueApiResponse<T> {
  error: boolean
  messages: string[],
  data: T,
}

interface CbValidationErrorResult {
  errorMetadata: any,
  field: string,
  message: string,
  rejectedValue: any,
  validationData: any,
  validationType: any,
}

export interface APIError extends InleagueApiResponse<any> {
  error: true,
}

/**
 * A weak informative alias is just an alias (meaning during typechecking it is always assignable to/from it's Base type)
 * But, it shuttles around information about what it represents, so in types like Record<Foo, Bar>, `Foo` can be informative like "string but represents a CompetitionUID" or etc.
 * It nests so it can represent things like "a basetype string that is a Guid that is a ChildID"
 */
type il_WeakInformativeAlias<Base extends string | number | symbol | boolean, T extends string> = Base & { __il_weakInformativeAlias__?: void } & { [k in T]?: void }

//
// Useful alias in cases where a value-name is available to express what the Guid represents
// e.g. `function foo(competitionUID: Guid)`
//
export type Guid = il_WeakInformativeAlias<string, "Guid">;
/** A date with indeterminate formatting; usually the "CF default", for ex. "September 28, 2022 10:48:16" */
export type Datelike = il_WeakInformativeAlias<string, "Datelike">;
/** A date with indeterminate formatting; usually the "CF default", for ex. "September 28, 2022 10:48:16" */
export type DateTimelike = il_WeakInformativeAlias<string, "DateTimelike">;
/** A date that should be in exactly Iso8601 format */
export type Iso8601String = il_WeakInformativeAlias<string, "Iso8601String">;
/**
 * Any integer or stringly representation thereof.
 *
 * Effectively
 * ```
 * number | `${number}` // e.g. `1 | "1"` but not `1 | "1" | " 1" | "b"`.
 *
 * ```
 *
 * The intent being:
 * ```
 * let v: Integerlike = ...;
 * // all Integerlike values parse as ints
 * !isNaN(parseInt(v as string)) === true;
 * ```
 */
export type Integerlike<T extends number = number> = il_WeakInformativeAlias<_NumberlikeHelper<T>, "Integerlike">
export type Floatlike<T extends number = number> = il_WeakInformativeAlias<_NumberlikeHelper<T>, "Floatlike">
// fixme: we want to support string here right? as in IntegerlikeHelper<T extends string | number> ...
type _NumberlikeHelper<T extends number> = number | `${T}` // help support `1` -> `1` | `"1"`

export type int = il_WeakInformativeAlias<number, "int">

/** any number or stringly representation thereof */
export type Numeric = il_WeakInformativeAlias<string | number, "Numeric">;
/**
 * A number representing a boolean.
 * This is often the result of a bit column in the db being serialized as a number.
 * Depending on endpoint, it may be serialized as a real boolean.
 */
export type Numbool = il_WeakInformativeAlias<number | boolean, "Numbool">;

/**
 * cfnull is not, within the type system, by-default assignable to string, even though at runtime it is a string
 * at api boundaries we might want to convert to a real js null and then we don't need to do this dance in logic
 *
 * ```
 * declare const nullish : cfnull | string;
 * // error, type 'string | cfnull' is not assignable to string
 * const s : string = nullish;
 * // ok, narrow away the cfnull
 * const t = isCfNull(nullish) ? "" : nullish;
 * // ok, cast away the cfnull
 * const u : string = nullish as string;
 * ```
 * @deprecated Just use the literal type `""`. If you are working on code that uses an instance of this, try to replace it with the literal type `""`.
 */
export type cfnull = { __cfnull__: [never] };

/**
 * typeguard to check if (cfnull | T) is just (cfnull)
 *
 * The return signature is a bit misleading but the intent is as per above;
 * (requires the intersection with `T` to satisfy the checker, something about RHS to `is` must be subtype of `typeof T` ... ?)
 *
 * An argument that is not possibly `cfnull` intentionally produces an error, so this can be caught if used unecessarily
 * @deprecated `cfnull` is deprecated; prefer using plain' ol literal type `""`
 */
export function isCfNull<T extends (cfnull extends T ? unknown : never)>(v: T): v is cfnull & T {
  return (typeof v === "string") && v === "";
}

/**
 * @deprecated `cfnull` is deprecated; prefer using plain' ol literal type `""`
 */
export function cfNullGetOr<T extends (cfnull extends T ? unknown : never), U>(v: T, _default: U) : Exclude<T, cfnull> | U {
  return v === "" ? _default : v as any;
}

//
// Useful aliases in cases where only a type-name name can be present
// e.g. `Record<CompetitionUID, boolean>`
//
// In an interface definition that contains such a value/type pair, prefer the "Guid" alias, because the value-name is available.
// e.g. `interface Foo { competitionUID: Guid }` rather than `interface Foo { competitionUID: CompetitionUID }`
//
export type UserID = il_WeakInformativeAlias<Guid, "UserID">;
export type ChildID = il_WeakInformativeAlias<Guid, "ChildID">;
export type PlayerID = il_WeakInformativeAlias<Guid, "PlayerID">;
export type CompetitionSeasonUID = il_WeakInformativeAlias<Guid, "CompetitionSeasonUID">;
export type CompetitionRegistrationID = il_WeakInformativeAlias<Integerlike, "CompetitionRegistrationID">;
export type DivisionID = il_WeakInformativeAlias<Guid, "DivisionID">;

// Guid is probably enough, we can just alias it
export type GameID = Guid;
export type SeasonUID = Guid;
export type CompetitionUID = Guid;
export type DivID = Guid;
export type TeamID = Guid;
export type RegistrationID = Guid

export type WithDefinite<T, K extends keyof T> = T & Required<Pick<T, K>>

export interface VolunteerRoles {
  flex: { codeID: string }[],
  referee: { divID: string }[],
  coach: { divID: string, headCoach: boolean }[]
}

export interface VolunteerComments {
  volunteerComments: string
}

export enum VolunteerRequirementKey { ANY = "*", HEAD_COACH = "Head Coach", ASST_COACH = "Asst. Coach", REFEREE = "Referee" };

// exact-optional-property types candidate; if `instance[P]` exists, it is "number", not "number | undefined"
/**
 * At this time, lists FROM THE SERVER are always expected to have a single key, e.g.
 * {"*": 4} is valid, but {"*": 4, "Head Coach": 3} is not expected.
 * But, we merge them on the client to possibly have
 */
export type CompositeVolunteerRequirement = { [P in VolunteerRequirementKey]?: number };

export interface FamilySeasonCompetitionVolunteerRequirementsCheck {
  satisfiesVolunteerRequirement: boolean,
  // listing of rulesets; each record in the list is atomic;
  // the list is disjunctive, i.e. [A,B,C] is either (A) or (B) or (C)
  // [
  //    {"*": 2}, // needs 2 of any code
  //    {"Head Coach": 1} // or 1 Head Coach
  // ]
  volunteerRequirements: CompositeVolunteerRequirement[],
  codesByUser: {
    [userID: string]: {
      [VolunteerRequirementKey.HEAD_COACH]: boolean,
      [VolunteerRequirementKey.ASST_COACH]: boolean,
      [VolunteerRequirementKey.REFEREE]: boolean,
      flex: string[]
    }
  }
}

export interface FamilyUsersNotHavingUserSeasonRecordsForSeason {
  userID: string,
  isParent1: boolean,
  isParent2: boolean
}

/*
  `User` is currently a hierarchy, where increasing permissions creates new subtypes (so the least permissioned object type is the highest supertype)
  Leaf nodes here represent exported types, unioned as `User`, with each uniquely identifiable by the `objectType` property declared in User_TaggedBase.

  User_TaggedBase
   |-> User_PrivateBase
        |-> User_Private (exported)
        |-> User_CommonBase
             |-> User_Common (exported)
             |-> User_Priveleged (exported)
*/

const UserObjectTypeTag = {
  "private-profile": "private-profile",
  "Standard User Object": "Standard User Object",
  "Privileged User Object": "Privileged User Object"
} as const;
type UserObjectTypeTag = typeof UserObjectTypeTag
type UserObjectTypeTag_t = (typeof UserObjectTypeTag)[keyof typeof UserObjectTypeTag]

interface User_TaggedBase {
  objectType: UserObjectTypeTag_t
}

interface User_PrivateBase extends User_TaggedBase {
  ID: Guid,
  firstName: string,
  middleName: string,
  lastName: string,
  email: string,
  region: Integerlike,
}

export interface User_Private extends User_PrivateBase {
  objectType: UserObjectTypeTag["private-profile"],
}

export type User = User_Private | User_Standard | User_Privileged

/**
 * todo:
 *  - dedup against src/interfaces/Store/events.ts:User
 *  - explain reasoning for optionality for optional members
 */
interface User_CommonBase extends User_PrivateBase {
  ID: Guid,
  dob: Datelike
  AYSOID: string
  firstName: string
  middleName: string
  lastName: string
  nickName: string
  clientID: Guid
  createdOn: Datelike
  gender: "M" | "F"
  email: string
  email2: string
  email3: string
  /** number representing boolean */
  isActive: number
  familyIDs: Guid[]
  street: string
  street2: string
  city: string
  state: string
  zip: string
  region: Integerlike
  /** expandable */
  regionName?: string,
  lastEAYSOYear: number
  primaryPhone: string
  homePhone: string
  workPhone: string
  workPhoneExt: string
  occupation: string
  businessEmployer: string
  workZip: string
  /** number representing boolean */
  SMSEnabled: number
  stackSID: string
  LicenseLevel: string
  LicenseLevelCode: string
  LicenseLevelExpiration: Datelike
  RefereeGrade: string
  RefereeGradeCode: string
  RefereeGradeExpiration: Datelike
  /** expandable */
  permLeagueComment?: /*cfnull? is only string when not expanded?*/ string | LeagueComment,
  stackRecordKey: string,
  privateProfile: Numbool,
}

export interface User_Standard extends User_CommonBase {
  objectType: UserObjectTypeTag["Standard User Object"]
}

/**
 * See also `RiskStatusCode`
 */
export enum RiskStatusType {
  "Green" = "Green",
  "Blue" = "Blue",
  "Disqualified" = "Disqualified",
  "Red" = "Red",
  "Brown" = "Brown",
  "None" = "None",
  "Yellow" = "Yellow",
  "Orange" = "Orange",
  "Purple" = "Purple",
  "Maroon" = "Maroon",
  "Gray" = "Gray",
  "Expired" = "Expired",
}

export namespace RiskStatusType {
  /**
   * Adds a "member" function to the RiskStatusType enum.
   * Canonicalization is desirable during comparisons to not worry about potential case differences,
   * esp. as the values are pulled in from a 3rd party service on the backend.
   */
  export const canonicalize = (v: string) : string => {
    return v.trim().toLowerCase()
  }
}

/**
 * Shorthand codes for "risk status colors".
 * TODO: clarify where using these takes precedence over risk status color (or vice versa),
 * or if they're always interchangable, or etc.
 */
export type RiskStatusCode =
  | "A" // `(Green)`
  | "B" // `(Blue)`
  | "D" // `(Disqualified)`
  | "F" // `(Red)`
  | "FC" // `(Brown)`
  | "N" // `(None)`
  | "NC" // `(Yellow)`
  | "NF" // `(Orange)`
  | "P" // `(Purple)`
  | "RC" // `(Maroon)`
  | "S" // `(Gray)`
  | "X" // `(Expired)`

/**
 * same as User_Standard, but with a different objectType tag, and additional fields.
 */
export interface User_Privileged extends User_CommonBase {
  objectType: UserObjectTypeTag["Privileged User Object"],
  /** number representing boolean */
  isBirthCertAdmin: number,
  /** number representing boolean */
  isDataReader: number,
  /** number representing boolean */
  isEmailAdmin: number,
  /** number representing boolean */
  isEventAdmin: number,
  /** number representing boolean */
  isEventReporter: number,
  /** number representing boolean */
  isGameScheduler: number,
  /** number representing boolean */
  isPlayerAdmin: number,
  /** number representing boolean */
  isRefScheduler: number,
  /** number representing boolean */
  isRegistrar: number,
  /** number representing boolean */
  isTreasurer: number,
  /** number representing boolean */
  isVolunteerAdmin: number,
  /** number representing boolean */
  isWebmaster: number,
  /** number representing boolean */
  bgCheckAllowedUniversal: number,
  /** number representing boolean */
  bgFlagged: number,
  lastLogin: Datelike
  RiskStatus: "" | RiskStatusType,
  RiskStatusCode: RiskStatusCode
  RiskStatusExpiration: Datelike
}

/**
 * This should be a supertype of Registration (i.e. Pick<Registration, CoreQuestionNames>)
 * Core question names map directly to keys of the Registration object
 * (not all elements of (keyof Registration) are core question names, but all core question names are elements of (keyof Registration))
 *
 * Many use sites want to pass the type `PlayerDetailsI`, which is almost a subtype of the record type we want
 * (note: PlayerDetailsI represents (Child | (Child & Registration)), when used in "CoreQuestion" position it implies it represents the is-Registration case)
 *
 * We almost statically know this type - it's the type provided by transforming the server's core questions yaml listing file into json
 */
export type __fixme__CoreQuestionAnswerRecord = {}

/**
 * is this unifiable with a "custom" registration question?
 */
export interface CoreQuestion {
  id: Guid,
  /**
   * Programmatic name
   *
   * Unique such that `id` implies `name` and vice-versa
   */
  name: string,
  /**
   * update=true -> the answer to this core question updateable by parents
   */
  update: boolean,
  datatype: "string" | "number" | "boolean",
  displayName: string,
  gateFunctionName: string,
  label: string,
  maxlength?: number,
  required: boolean
  type: "text" | "select" | "radio" | "textarea" | "html"
  options?: { value: string, label: string }[]
  autofillDisabled?: boolean,
  html?: string
  disabled?: boolean
  /**
   * If type is text, we might have still have options;
   * offer the options but allow fallback to arbitrary text input when value is `arbitraryTextInputWhenValueIs`
   */
  useOptions?: {
    arbitraryTextInputWhenValueIs: string,
    options: UiOption[],
  }
}

/**
 * enum def
 */
export const WaitlistIsFree_t = {
  WAITLIST_IS_NOT_FREE: 0,
  WAITLIST_IS_FREE: 1,
  WAITLIST_GENERATES_TENTATIVE_PAYMENT_INTENT: 2,
  0: "WAITLIST_IS_NOT_FREE",
  1: "WAITLIST_IS_FREE",
  2: "WAITLIST_GENERATES_TENTATIVE_PAYMENT_INTENT",
} as const

/**
 * for typelevel-namespaced type lookup
 */
export interface WaitlistIsFree_t {
  int: (typeof WaitlistIsFree_t)[Extract<keyof typeof WaitlistIsFree_t, string>],
  string: (typeof WaitlistIsFree_t)[Extract<keyof typeof WaitlistIsFree_t, number>]
}

export interface CompetitionSeason<null_t = cfnull> {
  competitionSeasonUID: Guid,
  competitionUID: Guid,
  seasonUID: Guid,
  registrationStart: Datelike,
  feeOverride: number | null_t,
  registrationEnd: Datelike,
  ignoreDatesIfEligible: boolean,
  fullYearCalendar: boolean,
  algorithmSplitBirthYear: number | null_t,
  eligibilityRuleID: Guid,
  waitListDate: Datelike,
  waitListIsFree: WaitlistIsFree_t["int"],
  volRequirement: number,
  /**
   * number representing boolean
   */
  volRequirementOverride: number,
  /**
   * refundHoldback must be a valid currency amount in USD, or else an empty string
   */
  refundHoldback: number | null_t,
  seasonStart: Datelike,
  seasonWeeks: number,
  /**
   * Calendar date after which division heads are copied on all registrations
   */
  ddEmailDate: Datelike | cfnull
  /**
   * Date after which profiles are locked
   * (generally this means that for some registration R, when R is
   * associated with this season by way of any one of R's active
   * competition registrations, R is locked from edits when those edits are performed by a non-admin-user)
   */
  profileLockDate: Datelike | cfnull
  invitationToCompletePendingPaymentEmailHtml: string,
  ifHasAnyWaitlistedButUnpaidCompRegThenWaitlistComputeYieldsWaitlistedButUnpaid: Numbool,
}

export enum CloseAction { DO_NOTHING = 0, WAITLIST = 1, CLOSE = 2 }

export interface CompetitionSeasonDivision {
  compSeasonDivUID: Guid,
  competitionUID: Guid,
  seasonUID: Guid,
  divID: Guid,
  /**
   * nullish or datelike (both collapse to string)
   */
  emailDate: Datelike,
  closeAction: CloseAction,
  /**
   * Max players for this (competition, season, division) (inclusive, i.e. max of 3 means there can be at most 3 players)
   * possibly nullish, otherwise a number, with 9999 serving as a sentinel value meaning "no max"
   */
  divMax: string | number,
  /**
   * number representing a boolean
   */
  isActive: number,
  /**
   * number representing a boolean
   */
  forceCoed: number
  gender: "B" | "G",
  /**
   * division name, primary choice for ui display
   */
  displayName: string
  /**
   * division name, fallback choice for ui display
   */
  division: string
  divNum: number
}

export interface Division {
  divID: Guid,
  clientID: Guid,
  /**
   * primary UI name
   */
  displayName: string,
  /**
   * fallback UI name
   */
  division: string,
  /**
   * max number of players in this division; 9999 is a sentinel value indicating "no max"
   * possibly cfnullish, which should be treated as if it were "no max"
   */
  divMax: cfnull | number,
  /**
   * ceiling on age of players in this division
   */
  divNum: number,
  /**
   * number representing boolean
   */
  forceCoed: number,
  gameHours: string | number,
  gameMinutes: string | number,
  gender: "B" | "G",
  /**
   * number representing boolean
   */
  isActive: number,
  lastSeasonEmailed: string,
  slotHours: number,
  slotMinutes: number,
}

export type CompetitionDivisionMatch =
  | CompetitionDivisionMatch_Speculative
  | CompetitionDivisionMatch_Definite

interface CompetitionDivisionMatch_Base {
  speculative: boolean,
  competitionUID: Guid,
  competitionUiName: string,
  divID: Guid
}

interface CompetitionDivisionMatch_Speculative extends CompetitionDivisionMatch_Base {
  speculative: true,
}

interface CompetitionDivisionMatch_Definite extends CompetitionDivisionMatch_Base {
  speculative: false,
  competitionRegistrationID: Integerlike,
  /**
   * If the associated competition registration is paid
   */
  paid: boolean
  /**
   * If the associated competition registration is waitlisted
   */
  waitlist: boolean,
  /**
   * If this compreg is waitlisted, but not yet paid, but has an invoice pending payment
   * (currently, this means compreg asked the user to for a payment method, and informed
   * them that they will be charged, or invited to complete this payment, at a later date, when a spot opens up).
   */
  isWaitlistedButWithUnpaidBlockedPayment: boolean
}

/**
 * For some (user, season), get a listing of their children and a subset of their children's registration info
 * Focuses on their children's division/competition info
 */
export interface ChildDivisionsForUserSeason {
  result: Record<ChildID, CompetitionDivisionMatch[]>,
  clientSupport: Record<ChildID, { firstName: string, lastName: string }>
}

export interface VolunteerCode {
  assignable: Numbool,
  clientID: string,
  codeDesc: string,
  codeField: string,
  codeID: Guid,
  codeName: string,
  codeNum: number,
  /**
   * optionally present from particular endpoints. This is the "current signup count" for some season,
   * where "some season" is expected to be contextually known by the code using the object
   */
  countForSeason?: number
  divisional: Numbool,
  isActive: Numbool,
  isCheckBox: Numbool,
  /**
   * Nullish means "no max".
   * Otherwise, a literal number indicating max allowed volunteers per season (inclusive, i.e. up-to-and-including maxCount).
   * Should never be a negative number.
   */
  maxCount: number | "",
  requireBackgroundCheck: Numbool,
}

/**
 * Type of the `type` field in a RegistrationQuestion
 */
export enum QuestionType {
  TEXT = 1,
  RADIO = 2,
  SELECT = 3,
  CHECKBOX = 4,
  TEXTAREA = 5,
}

// probaby there exists an interface in Events.ts or elsewhere for this
// this is an incomplete definition
export interface TryoutEvent {
  divisions: DivisionID[]
  eventName: string,
  eventID: Guid,
  eventStart: Datelike,
}

export interface LeagueComment {
  commentID: Guid,
  childID: Guid,
  userID: Guid,
  seasonUID: Guid,
  comment: string,
  submittedBy: Guid,
  submittedOn: Datelike,
}


export interface CompetitionSideBySide {
  id: number,
  clientID: Guid,
  comp1UID: Guid,
  comp2UID: Guid,
  canExchange: boolean,
  creditFeeForExchange: boolean,
  applicability_exclude_if_eligible: boolean,
  applicability_exclude_if_registered: boolean,
  applicability_autoeligible_if_registered: boolean,
  applicability_only_if_registered: boolean,
}

/**
 * Like a js "day of week" value, but offset by 1
 */
export enum IL_DayOfWeek {
  SUNDAY = 1,
  MONDAY = 2,
  TUESDAY = 3,
  WEDNESDAY = 4,
  THURSDAY = 5,
  FRIDAY = 6,
  SATURDAY = 7
}

export enum CompetitionScope {
  Regional = 1,
  Area = 2,
  Sectional = 3,
  National = 4,
}

export interface Competition {
  /**
   * Unique identifier for this competition
   */
  competitionUID: Guid;
  /**
   * Friendlier id for this competition; not unique across different leagues/clientIDs
   */
  competitionID: number;
  clientID: Guid,
  /**
   * number representing boolean
   */
  enableBirthCertificateCollection: number;
  hostCompetitionUID: string;
  refCanCancel: number;
  startDayOfWeek: IL_DayOfWeek;
  showRefsOnRosters: number;
  /**
   * name of competition, suitable for display in UI
   */
  competition: string;
  /**
   * optional during transitional period; ultimately should not be optional
   */
  standingsDivnum?: number;
  refType: number;
  showFamilyRosters: Numbool;
  hasPayments: Numbool;
  /**
   * This type is nullable in the DB, but it almost never should be nullish.
   */
  scopeID: CompetitionScope | null | "",
  hasAccounting: number;
  maxPoints: number | string;
  isHost: number;
  isLocked: number;
  hasRatings: number;
  requireBirthCertificate: number;
  hasRefScheduling: number;
  losePoints: number | string;
  enabled: number;
  disableVPAssign: number;
  showTeamsOnDashboards: number;
  allowPastRefScheduling: number;
  winPoints: number | string;
  tournament: number;
  maxRefRequests: number;
  useScores: number;
  limitRefAccess: Numbool;
  gatewayID: string;
  coachMayEnterGameScores: number | string;
  showRefSchedule: number;
  tiePoints: number | string;
  seasonUID: string;
  hideScores: number;
  /**
   * 0: Default to season value; 1: Birth year; 2: School year
   */
  ageCalcMethod: number;
  registrationDesc: string,
  /**
   * Scope requirements for the teams to which refs can manually assign ref credits.
   */
  creditAssignmentTeamScope: number,
  scoretype: string
  shutoutpoint: Numbool
  zerotie: Numbool
  paymentAmount: number
  hasCoachFeedback: number
  paymentDesc: string,
  useAllGamesInStandings: Numbool
  confirmationEmailCC: string
  neighborDivs: Numbool
  refSchedulerEmail: string,
  refCancelHours: number,
  defaultEligibility: Numbool
  hideIfIneligible: number
  /**
   * true="registration is open for this competition", false="no registrations should take place for this competition"
   */
  hasActiveRegistration: Numbool
  stackPlayLevelKey: Guid
  /**
   * If non-nullish, any team-level transactions before this date will not be counted toward the team ledger
   */
  excludeDate: Datelike | cfnull,
  /**
   * expandable
   */
  sideBySide?: CompetitionSideBySide[]
  /**
   * expandable
   *
   * CompetitionUIDs from which to draw the "available player pool" for programs that do not have their own registration
   * Always {implicitly,logically} (but never physically) contains "this competitionUID"
   */
  sourceCompetitionUIDs?: Guid[],
  /**
   * expandable
   * A competition season record is info linked to a (competition, season) pair
   */
  competitionSeason?: CompetitionSeason
  /**
   * The CompetitionSeason object for the "current" season for this competition.
   *
   * Expandable, though some endpoints may always return it.
   *
   * For a competition C, and Seasons S_0...S_n, where the "current season" is S_n,
   * there can be (or definitely is?!) a competitionSeason record (C, S_i) forall 0 <= i <= n
   */
  currentCompetitionSeason?: CompetitionSeason,
  competitionRegistrationReceiptEmail_header_html: string,
  competitionRegistrationReceiptEmail_footer_html: string,
  requirePlayerPhotos : number,
  requireAdminPhotos : number,
  /**
   * Comma-delimited list of ages to offer the "coed" question to female players.
   * Related: instanceConfig.coed -- if that is true, the coed question is always offered to G players;
   * This will come over the wire as a comma delimited list, but we mark it as possibly an array because it will
   * be jammed into formkit forms where it will convert to an array.
   */
  coreQuestion_coedAge_limitedToAges: string | string[],
  refAssignmentHorizonDaysFromNow: "" | Integerlike,
  refAssignmentsInstantlyApproved: Numbool,
  refSchedulerAdminModeIgnoreRiskStatus: Numbool,
}

export interface Registration {
  registrationID: string,
  childID: Guid,
  clientID: Guid,
  lBrother: string;
  playerWeight: string;
  doctorFull: string;
  registered: number;
  leagueComment: string | LeagueComment;
  moveUpStatus: number;
  playerSchool: string;
  neverPlayed: number;
  coed: number;
  batchNum: string;
  droppedComments: string;
  medCond: number;
  comments: string;
  playerEmail: string;
  refundBy: string;
  transactions: Transaction[];
  lastModifiedBy: string;
  moveUpAsked: number;
  mediProbs: string;
  donation: string;
  refund: number;
  WL: number;
  Fee: string;
  // expandable, not always present?
  player: Child;
  medicalInsCarrier: string;
  amountPaid: string;
  isAYSOExtra: number;
  playerCellPhone: string;
  VIP: number;
  moveUpOldDiv: string;
  seasonUID: string;
  sexdiv: string;
  EmergencyPhone: string;
  seasonID: number;
  /**
   * maybe expandable, not always present?
   * Note that this represents an array of "competition registration" objects,
   * rather than an array of competition objects
   */
  competitions: CompetitionRegistration[];
  // expandable, not always present?
  registrationAnswers: RegistrationAnswer[];
  noPracticeDay: string;
  invoiceNo: string;
  moveUp: number;
  seasonName: string;
  EmergencyContact: string;
  sexdivNextYear: string;
  methodOfPayment: string;
  excludeFromCaps: number;
  grade: string;
  submitType: string;
  excludeFromWaitList: number;
  playerHeight: string;
  refundDate: string;
  recentlyMoved: number;
  age_calc: number;
  stackEnrollmentDate: string;
  divID: string;
  physicianName: string;
  refundAmount: string;
  droppedDate: string;
  registrarOnly: string;
  droppedBy: string;
  email_other: string;
  dropped: number;
  submitterID: string;
  feeOverride: number;
  WLRemoveDate: string;
  registrationComments: string;
  WLRemoveBy: string;
  playerNum: number;
  div: number;
  scholarship: number;
  suspended: number;
  playerFirstName: string;
  playerLastName: string;
  dateCreated: Datelike,
  dateModified: Datelike,
  teamAssignments?: TeamAssignment[]
}

export interface RegistrationAnswer {
  userID: string,
  shortLabel: string,
  clientID: string,
  answer: string,
  id: string,
  registrationID: string,
  questionID: string,
  label: string,
}

/**
 * A single competition registration, which is a member of a "primary registration" (see "Registration")
 *
 * uniquely identified by its `competitionRegistrationID` property,
 */
export interface CompetitionRegistration {
  competitionRegistrationID: Integerlike;
  childID: Guid;
  seasonUID: Guid;
  competitionUID: Guid;
  /**
   * userID of cancelled by, possibly nullish
   */
  canceledBy: Guid;
  /**
   * fullname suitable for use in UI of the `canceledBy` user
   * nullish if `canceledBy` is nullish
   */
  canceledByFullName: string;
  divID: string;
  waitlistRemoveDate: string;
  exchangedCrID: string;
  /**
   * userID of most recent user to set the waitlist bit to zero
   */
  waitlistRemoveBy: Guid | cfnull;
  /**
   * name of user identified by `waitlistRemoveBy`
   */
  waitlistRemoveByFullName: string;
  donation: string;
  /**
   * description of registration fee, created as part of fee-generation process
   *
   * A human readable string, something like "Fee for XYZ program"; should be used only for display to users
   */
  transtype: string,
  canceledDate: string;
  submitterID: string;
  registrationDate: string;
  /**
   * number representing boolean
   */
  canceled: number;
  /**
   * integer (todo/verify: serialized as a string? this should be type number; maybe just string when it is cfnull?)
   */
  invoiceInstanceID: string | cfnull;
  waitlist: number;
  registrationID: string;
  competitionName: string;
  /**
   * number representing boolean
   */
  paid: number;
  /**
   * expandable
   */
  competitionSeason?: CompetitionSeason,
  /**
   * expandable
   */
  invoiceLineItem?: LineItem
  /**
   * true if wl=1 but payment isn't accepted until dewaitlisting
   */
  isWaitlistedButUnpaid: Numbool,
  /**
   * registration fee
   * If nullish, invoiceTemplate_invoiceID and invoiceTemplate_methodID are expected to be non-nullish.
   */
  fee: number | "",
  /**
   * associated invoice template (if non-nullish, means "is a subscription based compreg")
   * If nullish, fee is expected to be non-nullish
   */
  invoiceTemplate_invoiceID: string,
  /**
   * the "payment schedule" for the invoice template.
   * If nullish, means "one time payment", which is basically the same as "has fee" compreg
   * except the fee derives from league provided invoice template, rather than the backend fee caclulator.
   */
  invoiceTemplate_methodID: "" | Integerlike,
}

/**
 * common part of a specific competition eligibility record
 */
interface CompetitionEligibilityBase {
  competitionUID: Guid,
  seasonUID: Guid,
  /**
   * ID of user that added this record
   */
  addedBy: Guid,
  dateAdded: Datelike,
  /**
   * inline-expanded submitter info
   */
  submitterFirst: string,
  submitterLast: string,
  submitterEmail: string,
}

export interface CompetitionEligibleChild extends CompetitionEligibilityBase {
  /**
   * unique id
   * it's an integer, but is serialized to us as a string
   */
  childEligibilityID: string | number,
  /**
   * eligiblity selector
   */
  childID: Guid,
}

export interface CompetitionEligibleNameAndDOB extends CompetitionEligibilityBase {
  /**
   * unique id
   * it's an integer, but is serialized to us as a string
   */
  nameDOBID: string | number,
  /**
   * eligiblity selector
   */
  lastName: string,
  /**
   * eligiblity selector
   */
  DOB: Datelike,
}

/**
 * represents a server-side constructed union of `CompetitionEligibleChild | CompetitionEligibleNameAndDOB`
 */
export type CompetitionEligibility =
  CompetitionEligibilityBase & (
    | ({ type: "childID", eligibilityID: `c-${string | number}`, childID: Guid, DOB: Datelike, firstName: string, lastName: string })
    | ({ type: "nameDOB", eligibilityID: `n-${string | number}`, childID: cfnull, DOB: Datelike, firstName: cfnull, lastName: string })
  )

/**
 * These types are disjoint and don't share a common discriminant, but they are all conceptually related.
 * Type predicates are good enough in the face of a missing discriminant.
 */
export function isCompetitionEligibility(v: CompetitionEligibility | CompetitionEligibleChild | CompetitionEligibleNameAndDOB) : v is CompetitionEligibility {
  const discriminant = "eligibilityID";
  // Try to statically ensure the descriminant doesn't ever get added to the "other" object types
  // This discriminant wasn't originally intended to be used as a discriminant.
  type Discriminant = typeof discriminant;
  const _unused : (Discriminant extends (keyof CompetitionEligibleChild) | (keyof CompetitionEligibleNameAndDOB) ? "discriminant no longer uniquely identifying" : "ok") = "ok";
  return discriminant in v;
}

export function isCompetitionEligibleChild(v: CompetitionEligibleChild | CompetitionEligibleNameAndDOB): v is CompetitionEligibleChild {
  return !!(v as CompetitionEligibleChild).childEligibilityID;
}

export function isCompetitionEligibleNameAndDob(v: CompetitionEligibleChild | CompetitionEligibleNameAndDOB): v is CompetitionEligibleNameAndDOB {
  return !!(v as CompetitionEligibleNameAndDOB).nameDOBID;
}

// @@@@rmme
// /**
//  * dedupe this w/ Child?
//  * this is a subset of Player, specifically for the results of a search endpoint?
//  */
// export interface Player {
//   childID: Guid;
//   clientID: Guid;
//   playerLastName: string;
//   playerFirstName: string;
//   parent1FirstName: string;
//   parent1ID: Guid;
//   parent1LastName: string;
//   dateModified: Datelike;
//   parent1Phone: string;
//   parent1Email: string;
//   /////////////////@@@@@@@rmme birthCertificateFile: string;
//   birthCertificate: number;
//   modifiedBy: string;
//   blockFromRegistration: number;
//   mostRecentRegistrationID?: string;
//   dateCreated: Datelike;
//   playerBirthDate: Datelike;
//   familyID: Guid;
//   childNum: number;
//   provisionalAYSOID: number;
//   AYSOID: string;
//   playerGender: string;
//   /**
//    * expandable (maybe? fixme, wip)
//    */
//   registrations?: Registration[];
// }

/**
 * represents the "target entity type" for a custom field gate function
 */
export enum GateFunctionQuestionType {
  CLIENT_REGISTRATION_QUESTION = 1,
  CLIENT_REGISTRATION_QUESTION_OPTION = 2,
  CONTENT_CHUNK = 3
}

export enum GateFunctionMatchType { EQ = 1, NEQ = 2 };

export interface CustomFieldGateFunction {
  gateQuestionType: GateFunctionQuestionType;
  gateFunctionID?: string;
  gateQuestionID?: string;
  matchType: GateFunctionMatchType;
  gateContentID?: string;
  gateQuestionOptionID?: string;
  targetValue: string;
  customFieldID: string;
}

export interface ContentChunk {
  id: string // string | number, integer but serialized to us as string?
  overridden: boolean
  override?: ContentOverrideInterface
  defaultText: string
  startDate: Datelike | cfnull,
  endDate: Datelike | cfnull,
  clientID: string
  label: string
  shortLabel: string
  disableRich: boolean | number | string // probably "number representing boolean but sometimes cfnull"
  /**
   * associated built-in gate function name, possibly empty string meaning "no such associated gate function"
   */
  gateFunctionName: string
  /**
   * associated custom gate function, possibly empty string meaning "no such associated gate function"
   */
  gateFunctionID: string
}

export interface ContentOverrideInterface {
  id: string
  chunkID: number
  clientId: string
  overrideText: string
  startDate?: string
  endDate?: string
}

/**
 * A registration page item contains either a RegistrationQuestion or a ContentChunk
 */
export type RegistrationPageItem = RegistrationPageItem_Question | RegistrationPageItem_ContentChunk

export enum PageItemType { QUESTION = 1, CONTENT_CHUNK = 2 }

interface RegistrationPageItemBase {
  /**
   * ID of the pageitem; this is different than the ID of the contained item, which has its own ID
   */
  id: Guid,
  clientID: Guid,
  /**
   * primary discriminant
   */
  type: PageItemType;
  /**
   * redundant with `type`
   * @deprecated
   */
  pageItemType: "Question" | "Content";
  order: number;
  isDisabled: number;
  /**
   * the contained page item
   */
  pageItem: RegistrationQuestion | ContentChunk;
  itemSeasons: any[]; // Season[]? guid[]? expandable?
  itemCompetitions: {pageItemID: Guid, competition: string, competitionUID: Guid}[];
  questionID: "" | Guid // "nullish" or GUID
  contentID: "" | Integerlike // "nullish" or integerlike
}

/**
 * A registration page item which contains a RegistrationQuestion
 */
export interface RegistrationPageItem_Question extends RegistrationPageItemBase {
  type: PageItemType.QUESTION,
  /**
   * @deprecated
   */
  pageItemType: "Question",
  pageItem: RegistrationQuestion,
  /**
   * redundant with `pageItem.id`, i.e. this is the ID of the contained pageItem, which itself contains its own ID
   */
  questionID: Guid
}

/**
 * A registration page item which contains a ContentChunk
 */
export interface RegistrationPageItem_ContentChunk extends RegistrationPageItemBase {
  type: PageItemType.CONTENT_CHUNK,
  /**
   * @deprecated
   */
  pageItemType: "Content",
  pageItem: ContentChunk,
  /**
   * redundant with `pageItem.id`, i.e. this is the ID of the contained pageItem, which itself contains its own ID
   */
  contentID: number // string | number -- integer, but sometimes serialized to us as a string?
}

/**
 * A custom registration question represents a question that may be
 * rendered as an HTML select or radio or input or textarea or etc., as per its `type` property
 */
export interface RegistrationQuestion {
  id: Guid,
  clientID: Guid,
  label: string,
  shortLabel: string,
  type: QuestionType,
  isRequired: Numbool,
  isEditable: Numbool,
  /**
   * @deprecated Use the owning PageItem wrapper's `isDisabled` property
   */
  isDisabled: Numbool,
  /**
   * name of builtin gate function, empty string represents null
   */
  gateFunctionName: string | cfnull,
  /**
   * id of custom gate function, empty string represents null
   */
  gateFunctionID: Guid | cfnull,
  inLeagueQuestionGroup: number,
  questionOptions: QuestionOption[],
  order: Numeric,
  /**
   * fixme: do we have a type for a GateFunction?
   * this property is `"" | <GateFunction>` where "" is "nullish"
   */
  gateFunction: any,
  hasAnswers: Numbool,
}

export interface QuestionOption {
  gateFunctionName: string;
  order: number;
  optionValue: string;
  seasons: any[]; // FIXME: type?
  gateFunction: string;
  id: string;
  optionText: string;
  questionID: string;
  gateFunctionID: string;
  tournamentTeamReg_points: "" | Integerlike
}

/**
 * todo: dedup w/ PlayerLookupI ??? preferably this is The One True Definition
 */
export interface Child {
  childID: Guid;
  dateModified: string;
  /**
   * truthy -> there is a current "approved" birth certificate
   *
   * falsy ->
   *  - there is no current "approved" birth certificate
   *  - (there may or may not be one on file, but if there is one on file, it has not yet been approved)
   */
  birthCertificate: Numbool;
  /**
   * Aside from birthCertificate being "has a validated" birth cert,
   * there is also information regarding "we actually have a file we could show"
   * birthCertificate=false, hasSomeBirthCertificate=false --> not validated, no file
   * birthCertificate=false, hasSomeBirthCertificate=true  --> not validated, but there is a file, it is awaiting validation
   * birthCertificate=true, hasSomeBirthCertificate=false  --> validated, but the file was deleted (used to be a thing, we now keep them)
   * birthCertificate=true, hasSomeBirthCertificate=true   --> validated, and the file exists
   */
  hasSomeBirthCertificateFile: Numbool;
  modifiedBy: string;
  stackRecordKey: string;
  playerNickName: string;
  /**
   * number representing boolean
   */
  blockFromRegistration: number;
  stackFamilyID: string;
  dateCreated: string;
  familyID: string;
  provisionalAYSOID: number;
  playerGender: "B" | "G";
  stackSID: string;
  parent1Email: string;
  parent1Phone: string;
  parent1FirstName: string;
  parent1LastName: string;
  // parent2 fields are all optional
  parent2Email?: string,
  parent2Phone?: string,
  parent2FirstName?: string,
  parent2LastName?: string,
  clientID: string;
  playerBirthDate: string;
  childNum: number;
  playerLastName: string;
  playerFirstName: string;
  AYSOID: string;
  /** expandable */
  registrations?: Registration[]
  /**
   * expandable
   * Present only in response to /familySeason endpoint
   * Probably removable by way of expanding `registrations` from the same endpoint
   */
  registrationID?: Guid;
  /** expandable */
  mostRecentRegistration?: Registration,
  /** expandable */
  parent1?: string,
  /** expandable */
  parent2?: string,
  /** expandable */
  parent1ID?: string, // is this expandable? it's always included?
  /** expandable */
  parent2ID?: string, // is this expandable? it's always included?
  /** expandable */
  teamAssignmentsCurrent?: string,
  /** expandable */
  relatedUsers?: RelatedUser[]
  /** expandable */
  contactPhone?: string,
  /** expandable */
  contactName?: string,
  /** expandable */
  contact2Phone?: string,
  /** expandable */
  contact2Name?: string,
  /** expandable */
  photoURL?: string,
  /** expandable */
  permLeagueComment?: string | LeagueComment,
  /** expandable */
  familyMembers?: User[],
  /**
   * For some season (or, across every season), does this player have any complete program registration?
   */
  hasSomeCompleteProgramRegistration: {
    seasonUID: Guid | "across-all",
    value: boolean
  },
  isPhotoLocked: "" | 0 | 1 | "0" | "1" | true | false
}

export interface RelatedUser {
  userID: string;
  lastName: string;
  firstName: string;
  relationship_type_id: number;
  relationshipTypename: string;
}

/**
 * Result type of a search for volunteers
 */
export interface VolunteerSearchResult {
  /**
   * ID of volunteer (AKA user)
   */
  ID: Guid,
  firstName: string,
  lastName: string,
  email: string,
  region: Integerlike,
  RiskStatus: "" | RiskStatusType
}

/**
 * different than a Player? only returned from some particular set of endpoints?
 */
export interface PlayerSearchResult {
  parent1LastName: string,
  dateModified: Datelike,
  //////// @rmme birthCertificateFile: string,
  /**
   * number representing boolean
   */
  birthCertificate: number,
  modifiedBy: UserID,
  playerNickName: string,
  stackRecordKey: Guid | cfnull,
  /**
   * number representing boolean
   */
  blockFromRegistration: number,
  stackFamilyID: string | number,
  dateCreated: Datelike,
  familyID: Guid,
  provisionalAYSOID: string | number,
  permLeagueComment: string,
  playerGender: "B" | "G",
  stackSID: string,
  parent1Phone: string,
  parent1Email: string,
  childID: Guid,
  clientID: Guid,
  parent1FirstName: string,
  playerBirthDate: Datelike,
  childNum: number,
  AYSOID: string | number,
  playerFirstName: string,
  playerLastName: string,
  mostRecentRegistrationID: Guid,
  parent1ID: UserID,
}

/**
 * Represents the type of a relationship between a user and player
 * Example `typename` values are "Father" / "Mother" / etc.
 */
export interface RelationshipType {
  typeID: number,
  typename: string
}

export interface Season {
  /**
   * uniquely identifies this season object
   */
  seasonUID: string,
  seasonName: string,
  seasonID: Integerlike,
  registrationYear: number,
  ratingsPromptDate: string,
  pointsLockDate: string,
  fullYearCalendar: number,
  calendarSeasonID: number,
  inPersonDate: Datelike | cfnull,
  year: number,
  seasonWeeks: number,
  seasonYear: number,
  rosterDate: Datelike | cfnull,
  /**
   * @deprecated use `seasonName`
   * see modules_app/api/modules_app/v1/handlers/seasons.cfc#list
   */
  name: string,
  refundHoldback: number | string,
  seasonStart: string,
  ratingsDate: string,
  /**
   * number representing boolean
   */
  requireCoachDiv: number,
  /**
   * number representing boolean
   */
  requireRefDiv: number,
}

/**
 * Very common supertype of Season
 *  - seasonName for ui purposes
 *  - seasonUID for unique key
 *  - seasonID for sorting (higher number == more recent season)
 */
export type SeasonTriple = Pick<Season, "seasonName" | "seasonUID" | "seasonID">

//
// portlets
//

export type PortletID =
  | 'volunteerStatus'
  | 'divisionStats'
  | 'certifications'
  | 'upcomingEvents'
  | 'upcomingGames'
  | 'refAssignments'
  | 'teamAssignments'
  | 'playersWithUnmetBirthCertificateRequirement'

// common to all portlets
export interface PortletBase {
  portletID: PortletID,
  fontAwesome: string,
  timestamp: Datelike,
  title: string,
  uri: string,
}

export type Portlet =
  | VolunteerStatusPortlet
  | DivisionStatsPortlet
  | CertificationsPortlet
  | UpcomingEventsPortlet
  | UpcomingGamesPortlet
  | RefAssignmentsPortlet
  | TeamAssignmentsPortlet
  | PlayersWithUnmetBirthCertificateRequirementPortlet

export interface DivisionStatsPortlet extends PortletBase {
  portletID: "divisionStats"
  allCompetitions: {
    /**
     * suitable for use in UI
     */
    competition: string,
    /**
     * generally a GUID, may be empty string if it represents "all competitions"
     */
    competitionUID: "" | Guid
  }[],
  allDivisions: {
    /**
     * generally a GUID, may be "All"
     */
    divID: "All" | Guid,
    /**
     * string like "B6", meaning "boys, aged 6", or etc.
     */
    division: string,
    /**
     * string like "BU6", meaning "boys under 6", or etc."
     * always more specific than `division` (??)
     */
    divisionName: string
  }[],
  /**
   * ???
   */
  competitionUID: string,
  /**
   * A single division, the same shape as an entry in `allDivisions`
   */
  division: DivisionStatsPortlet["allDivisions"][number],
  /**
   * always the same as the top-level seasonID/seasonUID values?
   */
  season: {
    seasonName: string,
    seasonUID: string,
    seasonID: number
  },
  /**
   * ???
   */
  seasonID: number,
  /**
   * ???
   */
  seasonUID: string,
  statistics: {
    allRegisteredPlayers: number,
    asstCoachVols: number,
    asstCoachOnlyVols: number,
    droppedPlayers: number,
    headCoachVols: number,
    refVols: number,
    registeredPlayers: number,
    waitlistedPlayers: number,
  },
  compSeasonDivInfo: {
    competitionUID: Guid,
    seasonUID: Guid,
    divID: Guid,
    isActive: boolean
  }[]
}

export interface CertificationsPortlet extends PortletBase {
  portletID: "certifications"
  // fixme
  [key: string]: any
}
export interface UpcomingEventsPortlet extends PortletBase {
  portletID: "upcomingEvents"
  // fixme
  [key: string]: any
}

export interface UpcomingGamesPortlet_Player {
  lastName: string,
  firstName: string,
  seasonID: Integerlike
}

export interface UpcomingGamesPortlet_Coach {
  lastName: string,
  firstName: string,
  /**
   * todo: is this might be a small enumerated set of strings?
   */
  title: CoachTitle,
  seasonID: Integerlike
}

export interface UpcomingGamesPortlet_Team {
  team: string,
  players: UpcomingGamesPortlet_Player[],
  teamName: string,
  teamID: Guid,
  coaches: UpcomingGamesPortlet_Coach[],
  region: Integerlike,
}

export interface UpcomingGamesPortlet_UpcomingGame {
  /**
   * nullish here means "tbd", no team assigned yet
   */
  visitorTeam: "" | UpcomingGamesPortlet_Team,
  /**
   * nullish here means "tbd", no team assigned yet
   */
  homeTeam: "" | UpcomingGamesPortlet_Team,
  division: string,
  gameNum: Integerlike,
  assignedRefs: unknown[],
  competitionID: Integerlike,
  gameID: Guid,
  fieldName: string,
  gameEnd: Datelike,
  gameStart: Datelike,
  competitionUID: Guid,
  /**
   * empty string if no associated compSeason object exists
   */
  competitionSeason: "" | {
    seasonStart: "" | Datelike,
    seasonWeeks: "" | Integerlike
  }
}

export interface VolunteerStatusPortlet_actionStepResponse {
  /**
   * `true` means the user needs to perform some action with respect to this ActionStepResponse (via the `actionalURL` property)
   * If false, no "action link" should be displayed, even if an `actionURL` is provided.
   */
  actionIndicator: boolean,
  /**
   * Opaque string (often like "ConcussionCert" or "CardiacArrestCert" but it being a readable
   * english phrase should not be relied upon), serving as an identifier for this "type of action".
   */
  statusItemID: string,
  /**
   * String suitable for use in UI, a human readable version of `statusItemID`
   */
  requirement: string,
  /**
   * A URL at which the user may complete this action, if an action needs to be completed (if actionIndicator is false, is this an empty string?)
   *
   * Note there are two cases, conceptually "external" and "internal":
   *  - An "external" URL is guaranteed to start with the prefix "https://", and should be used verbatim in an <a href="..."> tag
   *  - An "internal" URL is a vue-app router path name, and should be passed verbatim to <RouterLink to={{name: ...}}>
   */
  actionURL: string,
  /**
   * String suitable for use in UI, a human readable "what's the status of this"
   */
  status : string,
  /**
   * Optional, if present can assist in grouping UI output based on category value. When items are grouped, all items
   * that do not have a `category` property should be considered members of the "no-group group".
   */
  category? : string
}

export interface VolunteerStatusPortlet extends PortletBase {
  portletID : "volunteerStatus",
  volunteerRoleTitles: string[],
  isAnyActionIndicated: boolean,
  actionStepResponses: VolunteerStatusPortlet_actionStepResponse[]
}

export interface UpcomingGamesPortlet extends PortletBase {
  portletID: "upcomingGames"
  upcomingGames: UpcomingGamesPortlet_UpcomingGame[]
}

export interface RefAssignmentsPortlet extends PortletBase {
  portletID: "refAssignments"
  // fixme
  [key: string]: any
}
export interface TeamAssignmentsPortlet extends PortletBase {
  portletID: "teamAssignments"
  // fixme
  [key: string]: any
}
export interface PlayersWithUnmetBirthCertificateRequirementPortlet extends PortletBase {
  portletID: "playersWithUnmetBirthCertificateRequirement",
  players: Child[],
}

export interface NextStep {
  detail:  string;
  summary: string;
}

export interface RiskStatus {
  RiskStatusCode:                  string;
  ActionableDaysPriorToExpiration: number;
  hasInleagueAccess:               boolean;
  canCoach:                        boolean;
  Name:                            string;
  LMSAccessDaysPostExpiration:     number;
  Key:                             string;
  HasLMSAAccess:                   boolean;
  IsActionable:                    boolean;
  canRef:                          boolean;
}

export interface VolunteerSeasonStatus {
  registrationRequiredCode:   number;
  BgCheckAllowedUniversal:    number;
  registrationRequiredDetail: string;
  riskStatus:                 RiskStatus;
  nextStep:                   NextStep;
  bgCheck:                    string;
  userID:                     string;
  hasValidBackgroundCheck:    boolean;
  backgroundCheckEligible:    boolean;
  stackRecordKey:             string;
  riskStatusExpiration:       string;
  registrationRequired:       boolean;
  isRegistrationCurrent:      boolean;
  registrationStatusDetail:   string;
  registrationStatusCode:     number;
  season:                     Season;
}

export interface BackgroundCheck {
  registrationRequiredCode:   number;
  BgCheckAllowedUniversal:    number;
  registrationRequiredDetail: string;
  riskStatus:                 RiskStatus;
  nextStep:                   NextStep;
  /**
   * either nullish (empty-string) or object representing a qUserMembershipYear
   * todo: elaborate the non-nullish object type
   */
  bgCheck:                    string | {vendorSubmitLink: string};
  userID:                     string;
  hasValidBackgroundCheck:    boolean;
  backgroundCheckEligible:    boolean;
  stackRecordKey:             string;
  riskStatusExpiration:       string;
  registrationRequired:       boolean;
  isRegistrationCurrent:      boolean;
  registrationStatusDetail:   string;
  registrationStatusCode:     number;
  season:                     Season;
  submittedVolunteerPrefs:    boolean;
}

/**
 * A payment gateway represents a Stripe Connect account that is linked to inLeague. Leagues have at least one and may have more than one.
 */
export interface PaymentGateway {
  /**
   * The inLeague unique ID of the payment gateway account. Foreign key for events and competitions so that inLeague knows which account to use when payments are made.
   */
  clientGatewayID: Guid
  /**
   * Unique ID of the league to which this gateway belongs.
   */
  clientID: Guid
  /**
   * The GUID of the gateway **type**, e.g. Stripe. This field exists only for disambiguation with legacy (non-Stripe) accounts as all Stripe accounts have the same gatewayID.
   * @deprecated you probably want `clientGatewayID`
   */
  gatewayID: Guid
  /**
   * The league-assigned name by which this account is referenced. Appears in selection menus for competition and event setup.
   */
  accountName: string
  /**
   * If true, account is set to 'test mode' and will not actually process transactions.
   */
  staging: Numbool
}

export interface StackPlayLevel {
  Code: string
  Name: string
  Key: string
  Type: string
}

export type StackPlayLevelListing = { [stackPlayLevelName: string]: StackPlayLevel }

export interface RefDetails {
  userID: string;
  credits: number;
  lastName: string;
  creditType: string;
  assignmentID: string;
  game: RefGame;
  creditTeam: string;
  firstname: string;
  eventID: string;
  comments: string;
  positionName: string;
  seasonUID: string;
  dateAssigned: string;
  seasonID: number;
  approverID?: string;
}

export interface RefGame {
  homeGoalsHalftime: string;
  division: string;
  fieldUID: string;
  scoreEntryDate: string;
  scoreUserLastName: string;
  home: string;
  competitionID: number;
  gameEnd: string;
  comment: string;
  ref1: string;
  scoreUserFirstName: string;
  ref3: string;
  ref3Vol: string;
  gameDate: string;
  divID: string;
  ref1Vol: string;
  ref2Vol: string;
  doPointsCount: number;
  gameNum: number;
  homeGoals: string;
  divNum: number;
  refComment: string;
  scoreComment: string;
  gameID: string;
  ref2: string;
  visitorGoals: string;
  fieldID: number;
  gameStart: string;
  ref4Vol: string;
  ref4: string;
  visitor: string;
  visitorGoalsHalftime: string;
  teamVersus: string;
  playoff: number;
  competitionUID: string;
  field: RefFieldDetails;
}

export interface RefFieldDetails {
  fieldCity: string;
  fieldUID: string;
  fieldAbbrev: string;
  fieldState: string;
  fieldName: string;
  fieldID: number;
  fieldZip: number;
  fieldStreet: string;
}

export interface Transaction {
  transactionDate: string;
  invoiceNo: string;
  refTransID: string;
  stripe_fee: number;
  gatewaySubscriptionID: string;
  seasonName: string;
  stripe_chargeID: string;
  stripe_balance_txID: string;
  customerProfileID: string;
  submitterFirstName: string;
  transactionID: string;
  checkNum: string;
  transactionType: string;
  customerPaymentProfileID: string;
  invoiceInstanceID: number;
  cardType: string;
  application_fee: string;
  submitterEmailAddr: string;
  absoluteAmount: string;
  localSubscriptionID: string;
  transactionSubmitter: string;
  transactionAmount: string;
  lastfour: string;
  chargesSynchronized: number;
  transactionDetail: string;
  gatewayTransactionID: string;
  clientID: string;
  stripe_creditNoteID: string;
  isReceipt: number;
  hostIP: string;
  application_fee_amount: string;
  submitterLastName: string;
  clientGatewayID: string;
  registrationID: string;
  gatewayInvoiceURL: string;
  seasonID: number;
}

export interface Game {
  AR2Lock: Numbool,
  AR2Region: string,
  ARLock: Numbool,
  ARRegion: string,
  blockFromMatchmaker: Numbool,
  CRLock: Numbool,
  CRRegion: string,
  clientID: Guid,
  comment: string,
  competitionID: 1,
  competitionSeasonUID: Guid,
  competitionUID: Guid,
  seasonUID: Guid,
  createdBy: UserID,
  dateCreated: Datelike,
  dateUpdated: Datelike,
  divID: Guid,
  divNum: number,
  division: string,
  doPointsCount: Numbool,
  /**
   * depends on endpoint, sometimes expandable, sometimes always included
   */
  field?: Field,
  fieldID: number,
  fieldUID: Guid,
  /**
   * will contain a time portion, but the time portion isn't meaningful;
   * generally, time will come from `gameStart` (which is itself a date+time, but the time portion is meaningful)
   */
  gameDate: Datelike,
  gameEnd: DateTimelike,
  gameID: Guid,
  gameNum: number,
  gameStart: DateTimelike,
  genderNeutral: Numbool,
  /** teamID? */
  home: Guid,
  homeGoals: cfnull | number,
  homeGoalsHalftime: string,
  /** expandable */
  homeTeam?: Team,
  MARegion: string,
  MentorLock: Numbool,
  parked: Numbool,
  playoff: Numbool,
  poolID: string,
  ref1: Guid,
  ref1Vol: string,
  ref2: string,
  ref2Vol: string,
  ref3: string,
  ref3Vol: string,
  ref4: string,
  ref4Vol: string,
  refComment: string,
  roundID: string,
  scoreComment: string,
  scoreEntryDate: Datelike,
  scoreEntryUser: UserID,
  scoreUserFirstName: string,
  scoreUserLastName: string,
  /** ??? timezone info for dates ??? */
  timeOffset: number,
  updatedBy: UserID,
  /** teamID? */
  visitor: Guid,
  visitorGoals: cfnull | number,
  visitorGoalsHalftime: string,
  /** expandable */
  visitorTeam?: Team,
}

export interface Team {
  active: Numbool,
  clientID: Guid,
  colorJersey: string,
  colorJerseySolidStripe: string,
  colorShorts: string,
  colorSocks: string,
  competitionID: number,
  competitionUID: Guid,
  contact: string,
  /**
   * expandable, represents the TeamSeason for this team where the season is
   * constrained to `this.competition.seasonUID`
   */
  currentTeamSeason?: TeamSeason,
  /**
   * Represents a teamSeason for this team and some contextually important season
   */
  seasonal?: TeamSeason,
  dateModified: Datelike,
  divID: Guid,
  division: string,
  locked: Numbool,
  placeholder: Numbool,
  pool: string,
  region: Integerlike,
  seasonUID: Guid,
  submitterComments: string,
  tbd: Numbool,
  team: string,
  teamCity: string,
  teamID: Guid,
  teamLetter: string,
  /**
   * deprecated? see qTeam.cfc
   */
  teamName: string,
  tournament: Numbool
}

export interface TeamSeason {
  teamID: Guid,
  seasonUID: Guid,
  seasonID: Integerlike,
  teamName: string,
  colorJersey: string,
  colorJerseySolidStripe: string,
  colorShorts: string,
  colorSocks: string,
  practiceTime: string,
  practiceLoc: string
}

export interface InstanceConfig {
  allcoachesassign: string;
  appdomain: string;
  areaplay: string;
  asstcoachesrate: string;
  autocalcoverallrating: Numbool;
  byefield: string;
  byeteam: string;
  canacceptchecks: string;
  clientid: string;
  clientnum: string;
  coachassign: string;
  coachesassignany: string;
  confirmwaitlist: string;
  currentseasonid: string;
  currentseasonname: string;
  currentseasonuid: string;
  ddcaneditfields: string;
  ddcaneditmatches: string;
  ddcanedittimes: string;
  ddcanschedule: string;
  ddscanrefschedule: string;
  enableadultplayers: string;
  enablebirthcertificatecollection: string;
  enablehealthaware: string;
  extradivisions: string;
  facebookurl: string;
  fieldstatusareastamp: string;
  fieldstatusarea: string;
  fieldstatusregionstamp: string;
  fieldstatusregion: string;
  googletagmanager: string;
  haschiefrefs: string;
  hascoachfeedback: string;
  hasdonations: string;
  hasgamescheduling: string;
  hasrefpoints: string;
  hasrefscheduling: string;
  hasregistration: string;
  hassponsorships: string;
  hasvip: string;
  healthawareemail: string;
  isadultleague: string;
  isproduction: string;
  lastseasonid: string;
  lastseasonname: string;
  lastseasonyear: string;
  latitude: string;
  leaguecity: string;
  leaguepaysforbg: string;
  leaguestate: string;
  leaguetype: string;
  leaguezip: string;
  longitude: string;
  /**
   * Whether mfa is enabled for this league.
   * (might get serialized as a number or a boolean, but represents a boolean in all cases)
   */
  mfa_enabled: 0 | 1 | boolean;
  nextseasonid: string;
  nextseasonname: string;
  nextseasonyear: string;
  paranoidcoaches: string;
  prosubscriptioncutoff: string;
  publicsite: string;
  ratinghigh: number;
  ratinglow: number;
  refcreditfreemonth: string;
  refcreditlimit: string;
  refdivisions: 0 | 1 | boolean;
  regionabbrev: string;
  regionarea: string;
  regionname: string;
  regionsection: string;
  region: Integerlike;
  registraremail: string;
  registrationseasonid: string;
  registrationseasonname: string;
  registrationseasonuid: string;
  regnopractice: string;
  restrictpointallocation: string;
  schoolyearcutoffmonth: string;
  seasonsperyear: string;
  seasonstart: string;
  shortname: string;
  sport: string;
  stackclubkey: string;
  stackclubsid: string;
  stackleagueid: string;
  stackleaguesid: string;
  timeoffset: string;
  tournamentseasonid: string;
  tournamentseasonname: string;
  tournamentseasonuid: string;
  treasureremail: string;
  usecoachprefs: string;
  usepledge: string;
  usetryouts: string; // really boolean?
  webmasteremail: string;
  coachweburl: string;
  refweburl: string;
  /**
   * undefined / not present => "no theme variant"
   * empty string => "no theme variant"
   * otherwise => should match some `variant` value in clientThemes
   */
  frontendThemeVariant?: string;
}

export interface TeamAssignment {
  assignedBy: string,
  assignmentID: string,
  assignmentNum: number,
  clientID: string,
  dateAssigned: string,
  dateUpdated: string,
  divisionName: string,
  emailed: number,
  registrationID: string,
  seasonID: Integerlike,
  seasonName: string,
  seasonUID: string,
  /**
   * expandable
   */
  team?: Team,
  teamDesignation: string,
  teamID: string,
  teamLetter: string,
  teamName: string,
  timeOffset: string,
  uniform: string,
}

/**
 * The minimum required detail per menu node.
 */
export interface MenuTreeNodeDetail {
  /**
   * Might come over the wire as a number, but putting it into an HTML form will stringify it
   */
  entityID: string | number,
  label: string,
}

const UNUSED_TYPE_PARAM_IS_USED = Symbol()

/**
 * `Ks` is the key names for each level, generally like "seasonUID" or "teamID"
 * `Payloads` is an arbitrary object type, optionally present at each node; all nodes have at least MenuTreeNodeDetail associated with them.
 *
 * `MenuTreeDef<"a" | "b" | "c", {b: {x: number, y: string}}` describes a menu that:
 *  - has keys named "a", "b", and "c"
 *  - all "b" nodes have, as their detail property, the type `MenuTreeNodeDetail & {x: number, y: string}`
 *
 * The order of a union type is meaningless to TS, but instantiations of this type should conventionally write the `Ks` union in the expected order of the tree levels.
 * e.g. it is implied in the above definition that the menu is a->b->c. The runtime ordering of the tree levels is driven by the `meta` array.
 */
export interface MenuTreeDef<Ks extends string = string, Payloads extends {[P in Ks]?: {}} = {}, Sentinels = string> {
  menu: MenuTree,
  /**
   * one meta entry per tree level; so meta.length === tree depth
   * a menu like season->comp->team is depth 3, so meta.length would be 3
   */
  meta: MenuTreeMeta<Ks>[],
  // `Payloads` is used only to shuttle through inference later, but it can disappear if it isn't referenced
  [UNUSED_TYPE_PARAM_IS_USED]?: Payloads | Sentinels
}

/**
 * magic ID that hopefully that never conflicts with any "actual" entityID that serves as an indicator for "select all the things"
 */
export function isMenuSentinel<Sentinels, S extends Sentinels>(menu: MenuTreeDef<any, any, Sentinels>, entityID: string | number, s: S) : boolean {
  return entityID.toString() === `__$ALL-${s}`
}

export interface MenuTree {
  detail: MenuTreeNodeDetail,
  children: MenuTree[]
}

export interface MenuTreeMeta<Ks extends string = string> {
  /**
   * user facing label for some menu item (e.g. "Season"; note that this is not the label per option)
   */
  label: string,
  /**
   * key indicating target entity type of this item. Generally should be some entity key like `seasonUID` or `tournamentID` or etc.
   */
  key: Ks
}

/**
 * check integrity of this (esp. case sensitivity) with:
 * `select distinct title from coach_assignments`
 */
export type CoachTitle =
  | "Administrator"
  | "Co-Coach"
  | "Head Coach"
  | "Assistant"
