import { NavigationGuardNext, NavigationGuardWithThis, RouteLocationNamedRaw, RouteLocationNormalized, RouteLocationPathRaw, RouteLocationRaw, RouteRecordRaw, useRoute } from 'vue-router'
import { ContactAndVolunteerDetailsUpdateFlow, RegistrationVolunteerRequirementsFlow } from 'src/composables/registration'

import { events } from "src/store/EventuallyPinia"
import { axiosInstance } from "src/boot/axios"
import { freshAxiosInstance } from "src/boot/axios"
import * as CompetitionUtils from "src/modules/CompetitionUtils"
import * as FamilyProfile from "src/components/FamilyProfile/pages/FamilyProfile.ilx"
import * as MergePlayers from "src/components/Admin/MergePlayers.ilx"

import authService from 'src/helpers/authService'
import * as AuthService from "src/helpers/authService"

import { Role, UserData } from 'src/interfaces/Store/user'

// TODO: aggregate all competition registration journey modules/routes under a single root
import * as R_SelectSeason from 'src/components/Registration/selections/R_SelectSeason.route'
import * as R_SelectPlayer from 'src/components/Registration/selections/R_SelectPlayer.route'
import * as R_ContactAndVolunteerDetailsUpdateFlow from "src/components/Registration/selections/R_ContactAndVolunteerDetailsUpdateFlow.route"
import * as R_SelectCompetitions from 'src/components/Registration/selections/R_SelectCompetitions.route'
import * as R_RegistrationRelationshipCheck from 'src/components/Registration/playerUserRelations/R_RegistrationRelationshipCheck.route'
import * as R_PlayerRegistration from 'src/components/Registration/registrationForm/R_PlayerRegistration.route'
import * as R_ConfirmRegistration from "src/components/Registration/confirmation/R_ConfirmRegistration.route"
import * as R_PlayerRegistrationWaivers from "src/components/Registration/waivers/R_PlayerRegistrationWaivers.route"
import * as R_SelectPaymentOption from "src/components/Registration/R_SelectPaymentOption.route"
import * as R_RegistrationComplete from "src/components/Registration/complete/R_RegistrationComplete.route"

import * as R_VolunteerRegistrationFlow from 'src/components/VolunteerRegistration/R_VolunteerRegistrationFlow.route'
import * as R_EventEditor from "src/components/Events/R_EventEditor.ilx"
import * as R_EventSignup from "src/components/Events/R_EventSignup.route"
import * as R_CouponManager from 'src/components/Coupons/R_CouponManager.route'
import * as R_CouponEditor from 'src/components/Coupons/R_CouponEditor.route'
import * as R_MasterInvoice from 'src/components/Payment/pages/MasterInvoice.route'
import * as R_UserEditor from 'src/components/User/Editor/R_UserEditor.route'

import * as R_CompRegsWithBlockedPaymentIntents from "src/components/Admin/R_CompRegsWithBlockedPaymentIntents.route"
import * as R_Mfa from "src/components/User/Mfa.route"

import * as R_TournamentTeamListing from "src/components/Tournaments/R_TournamentTeamListing.route"
import * as R_TournamentTeamAdminListing from "src/components/Tournaments/R_TournamentTeamAdminListing.route"
import * as R_TournamentTeamConfigurator from "src/components/Tournaments/R_TournamentTeamConfigurator.route"

import * as R_SeasonCompetitionAdmin from "src/components/Admin/SeasonCompetitions/pages/R_AdminPage.route"

import * as PlayerEditor from "src/components/PlayerEditor/PlayerEditor.ilx"
import * as R_TournamentTeamCreate from 'src/components/Tournaments/R_TournamentTeamCreate.route'
import * as R_TournamentTeamRegPageItemListing from 'src/components/Tournaments/R_TournamentTeamRegPageItemListing.route'
import * as R_TournamentTeamRegPageItemEditor from 'src/components/Tournaments/R_TournamentTeamRegPageItemEditor.route'
import * as R_TournamentTeamRoster from 'src/components/Tournaments/R_TournamentTeamRoster.route'
import * as R_TournamentHostingManager from 'src/components/Tournaments/R_TournamentHostingManager.route'
import * as R_TournamentTeamReport from 'src/components/Tournaments/R_TournamentTeamReport.route'
import * as R_TournamentRefManager from 'src/components/Tournaments/R_TournamentRefManager.route'
import * as R_GameScheduler from 'src/components/GameScheduler/xlsx/R_GameScheduler.route'
import * as R_GameSchedulerCalendar from 'src/components/GameScheduler/calendar/R_GameSchedulerCalendar.route'
import * as R_Matchmaker from "src/components/GameScheduler/matchmaker/R_Matchmaker.route"
import * as R_GameTeamAssignments from 'src/components/GameScheduler/xlsx/R_GameTeamAssignments.route'
import * as R_SeasonManager from 'src/components/Admin/R_SeasonManager.route'
import * as R_DivisionEditor from 'src/components/Admin/Divisions/R_DivisionEditor.route'
import * as R_TeamEditor from 'src/components/Admin/Teams/R_TeamEditor.route'
import * as R_TeamAssignments from 'src/components/Team/TeamAssignments/R_TeamAssignments.route'

import * as R_TeamSeason from "src/components/Team/R_TeamSeason.route"
import * as R_TeamPools from "src/components/Team/TeamPools/R_TeamPools.route"
import * as R_PlayerRatings from "src/components/PlayerRatings/R_PlayerRatings.route"

import type * as iltypes from "src/interfaces/InleagueApiV1"
import { hasCreateTournamentTeamRoutePermission, isAuthorizedToManageSomeTournament, isAuthorizedToManageSomeTournamentTeam, isAuthorizedToManageTournament } from 'src/components/Tournaments/TournamentTeamUtils'
import { System } from 'src/store/System'

import { User } from 'src/store/User'
import { getCompetitionsOrFail } from 'src/store/Competitions'
import { Client } from 'src/store/Client'
import { tournamentStore } from 'src/components/Tournaments/Store/TournamentStore'

import * as R_ReportBuilder from 'src/components/ReportBuilder/R_ReportBuilder.route'

import * as R_Checkout from 'src/components/Payment/pages/R_Checkout.route'
import * as R_PlayerLookup from 'src/components/Lookup/Player/R_PlayerLookup.route'
import * as R_GameScores from "src/components/Score/R_GameScores.route"
import * as R_RefereeSchedule from 'src/components/RefereeSchedule/R_RefereeSchedule.route'
import { CSSProperties } from 'vue'
import * as R_MojoExports from 'src/components/MojoExports/R_MojoExports.route'
import * as R_BracketBuilder from 'src/components/GameScheduler/brackets/R_BracketBuilder.route'
import * as R_BracketListing from 'src/components/GameScheduler/brackets/R_BracketListing.route'
import { Route as R_BracketView } from "src/components/GameScheduler/brackets/R_BracketView"

// const routes: RouteRecordRaw[] = [
//   {
//     path: '/',
//     component: () => import(() => import('src/components/src/App.vue'),
//   },

//   // Always leave this as last one,
//   // but you can also remove it
//   {
//     path: '/:catchAll(.*)*',
//     component: () => import(() => import('src/components/pages/Error404.vue'),
//   },
const isMobile = process.env.isMobile === true

// a LegacyLink contains exactly one of either `urlTarget` or `eventTarget`
export type LegacyLink = {urlTarget: string, eventTarget?: undefined} | {urlTarget?: undefined, eventTarget: string}

// GUID pattern for vue-router route matching purposes, case insensitive
const pathGuidPattern = "[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}"
const regexGuidPatternNoCase = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
const pathIntPattern = "\\d+"

function guidOr<T>(v: any, fallback: T) : string | T {
  return (typeof v === "string") && regexGuidPatternNoCase.test(v) ? v : fallback;
}

export interface RouteRecordExtension {
  /**
  * Later (in src/router/index.ts) we map from a Record<string, RouteRecordRaw & {extra-stuff}> into a RouteRecordRaw[],
  * injecting each property name P for record N into RouteRecordRaw[N].name;
  * i.e. `keyof typeof routes` is the route names
  * We need to control the names here, to support nested route matching; see https://github.com/vuejs/vue-router/issues/777
  * `disableTopLevelAutoNameInjection` is a kludge to conditionally disable such name injection on a per-record basis
  * If present it must be exactly true
  */
  disableTopLevelAutoNameInjection?: true,
  /**
   * A route might point back into the legacy app; if so, it should have a LegacyLink property
   */
  legacyLink?: LegacyLink,
  meta: {
    /**
     * Either a boolean or a lazy boolean, but not neither, and not both.
     *
     * Effectively "show this item in the sidebar?"
     * Generally they will be grouped under a <disclosure> element, by their "category" property
     *
     * (bool | () => bool) is always a valid truthiness-check-target (as in `!v` where v has the bool or function type)
     * So to disambiguate, we use put the function type under "mainNavLazy"; but we should properly merge these into a DU to encode "is exactly either/or".
     *
     * The eager version uses things known at compile time (are we a mobile build? etc.),
     * whereas the lazy version requires runtime knowledge about the current user or instanceConfig or etc.
     */
    mainNav?: boolean,
    mainNavLazy?: () => boolean
    /**
     * List of roles, one of which is required to access the route.
     * Optional
     *   - undefined means fallback to the alternateAuthRoles function; an empty array means a logged-in user trivially passes.
     *   - "DEFER-TO-BEFORE-ENTER" means wait for the route to be matched to run auth checks, at which point we expect vue-router to invoke the beforeEnter callback
     *     (which we are required to have written). This is primarily for parameterized routes, for example `/editThing/:thingID`, where user permissions will
     *     vary based not only on user identity but also based on `thingID`. This is incompatible with using "static" auth checks to determine if a route is
     *     renderable as a main nav link (because we don't really know the 'actual' route until it's fully parameterized), so `mainNav` is expected to be false
     *     when auth is "DEFER-TO-BEFORE-ENTER".
     * Note this is orthogonal to {requiresAuth, requiresNoAuth}, and that it doesn't make sense to have `requiresNoAuth` but also some required auth roles.
     */
    auth?: "DEFER-TO-BEFORE-ENTER" | Role[]
    /**
     * Sometimes authorization is based on more than auth roles, if a user fails an auth roles permissions check
     * we fall back to this authorization check for a particular route
     */
    alternateAuthRolesCheck?: () => boolean | Promise<boolean>
    /**
     * effectively "must be logged in"
     */
    requiresAuth?: boolean,
    /**
     * effectively "must be NOT logged in"
     */
    requiresNoAuth?: boolean,
    /**
     * Optionally disable scroll behavior on route changes to this route
     * Would be better to do this at the RouterLink level and/or in calls to router.{push,replace}(...)
     */
    noScroll?: boolean
    /**
     * Configuration hook for the container the target component will be mounted in.
     * This is optional -- when not provided, the default of "constrained" will be used.
     */
    containerLayoutStrategy?: "constrained" | "unconstrained"
    useRouterViewRootAsWindowlikeScrollContainer?: boolean,
    /**
     * mostly as a shim to get the "main" background to be a particular color
     * We almost want this as a global? Would probably have to adjust a few things to do that;
     * (mostly we'd have to commit to forcing `--fk-bg-input: white` from the highest point in the tree,
     * which actually seems pretty reasonable).
     */
    kludgyMainContainerStyles?: Readonly<CSSProperties>,
    /**
     * "category" bascially means "nest this inside the $category group in the sidebar"
     * If not present, generally the item will end up as a "non grouped" link in the sidebar (i.e. loose, top-level)
     */
    category?: string,
    /**
     * Offer to collapse/expand the sidebar.
     * When navigating from route A to route B, when route A supports collapse, and route B does not support collapse,
     * and the sidebar is collapse, the sidebar will re-open.
     */
    allowBigModeSidebarCollapse?: boolean
  }
}

const commonKludgyMainContainerStyles : CSSProperties = {
  backgroundColor: "#FBFBFB"
}

const routes: { [key: string]: RouteRecordRaw & RouteRecordExtension} = {
  home: {
    path: '/',
    component: () => import('src/components/Navigational/Dashboard.vue'),
    meta: {
      current: true,
      mainNav: true,
      label: 'Dashboard',
      icon: 'home',
      requiresAuth: true,
      auth: [],
      kludgyMainContainerStyles: commonKludgyMainContainerStyles,
    },
  },
  'player-lookup': {
    path: '/player-lookup',
    component: () => import('src/components/Lookup/Player/R_PlayerLookup.vue'),
    meta: {
      mainNavLazy: () => R_PlayerLookup.hasSomeRoutePermission(),
      label: 'Player Lookup',
      icon: 'search',
      requiresAuth: true,
      auth: "DEFER-TO-BEFORE-ENTER",
      category: 'Players',
    },
    beforeEnter: (to, from, next) => {
      if (R_PlayerLookup.hasSomeRoutePermission()) {
        return next();
      }
      else {
        return _403(to, next)
      }
    }
  },
  'registration-preview': {
    path: '/registration-preview',
    component: () => import('src/components/Registration/registrationPreview/RegistrationPreview.vue'),
    meta: {
      mainNav: false,
      label: 'Registration Preview',
      icon: 'id-badge',
      requiresAuth: true,
      auth: [],
      category: 'Utilities'
    },
  },
  [R_SeasonCompetitionAdmin.RouteNames.main]: {
    path: '/admin-competition',
    component: () => import('src/components/Admin/SeasonCompetitions/pages/R_AdminPage'),
    meta: {
      mainNavLazy: () => R_SeasonCompetitionAdmin.hasSomeRoutePermission(),
      label: 'Program Administration',
      icon: 'id-badge',
      requiresAuth: true,
      auth: "DEFER-TO-BEFORE-ENTER",
      category: 'Competitions',
    },
    beforeEnter: (to, _from , next) => {
        if (R_SeasonCompetitionAdmin.hasSomeRoutePermission()) {
          return next();
        }
        else {
          return _403(to, next)
        }
    },
    children: [
      {
        name: R_SeasonCompetitionAdmin.RouteNames.seasonalSettings,
        path: `seasonal`,
        component: () => import("src/components/NilComponent.vue"),
      },
      {
        name: R_SeasonCompetitionAdmin.RouteNames.tournaments,
        path: `tournaments`,
        component: () => import("src/components/NilComponent.vue"),
      },
      {
        name: R_SeasonCompetitionAdmin.RouteNames.playerEligibility,
        path: "playerEligibility",
        component: () => import("src/components/NilComponent.vue"),
      },
      {
        name: R_SeasonCompetitionAdmin.RouteNames.invitationStatus,
        path: "invitationStatus",
        component: () => import("src/components/NilComponent.vue"),
      }
    ]
  },
  'registration-admin': {
    path: '/registration/admin',
    component: () => import('src/components/Registration/admin/Admin.vue'),
    meta: {
      mainNav: isMobile ? false : true,
      label: 'Registration Questions',
      icon: 'id-badge',
      requiresAuth: true,
      auth: ['registrar'],
      category: 'Competitions'
    },
  },
  [PlayerEditor.RouteName.main]: {
    path: '/player-editor/:playerID/:registrationID?',
    component: () => import('src/components/PlayerEditor/PlayerEditor.vue'),
    props: true,
    meta: {
      mainNav: false,
      label: 'Player Editor',
      icon: 'id-badge',
      requiresAuth: true,
      auth: "DEFER-TO-BEFORE-ENTER",
      category: 'Players'
    },
    beforeEnter: (to, from, next) => {
      if (PlayerEditor.hasRoutePerms()) {
        return next();
      }
      else {
        return _403(to, next)
      }
    }
  },
  'new-page-item': {
    path: '/new-page-item',
    component: () => import('src/components/Registration/admin/pageItem/NewPageItem.vue'),
    meta: {
      mainNav: false,
      label: 'New Page Item',
      icon: 'plus-circle',
      requiresAuth: true,
      auth: [],
      category: 'Utilities'
    },
  },
  'volunteer-lookup': {
    path: '/volunteer-lookup',
    component: () =>
      import('src/components/Lookup/Volunteer/volunteerLookup.vue'),
    meta: {
      mainNav: true,
      label: 'User Search',
      icon: 'search',
      requiresAuth: true,
      auth: ['director', 'Board', 'CompetitionManager', 'registrar', 'refScheduler', 'compManager', 'volunteerAdmin', 'Board', 'scholarshipAdmin', 'ChiefRef', 'webmaster', 'inLeague'],
      category: 'Volunteers',
    },
  },
  'volunteer-details': {
    path: '/volunteer-details/:volunteerID',
    component: () =>
      import('src/components/Lookup/Volunteer/volunteerView.vue'),
    meta: {
      mainNav: false,
      label: 'Volunteer Info',
      icon: 'search',
      requiresAuth: true,
      auth: [],
      category: 'Volunteers',
    },
  },
  'select-volunteer': {
    path: '/select-volunteer/:seasonUID',
    component: () =>
      import('src/components/VolunteerRegistration/SelectVolunteer.vue'),
    meta: {
      mainNav: false,
      label: 'Volunteer Selection',
      icon: 'search',
      requiresAuth: true,
      auth: [],
      category: 'Volunteers',
    },
  },
  'role-selection': {
    path: '/role-selection/:volunteerID/:seasonUID',
    component: () =>
      import('src/components/VolunteerRegistration/SelectRoles.vue'),
    props: true,
    meta: {
      mainNav: false,
      label: 'Volunteer Info',
      icon: 'search',
      requiresAuth: true,
      auth: [],
      category: 'Volunteers',
    },
  },
  'volunteer-verification': {
    path: '/volunteer-verification/:volunteerID/:seasonUID',
    props: true,
    component: () => import('src/components/VolunteerRegistration/VerificationPage.vue'),
    meta: {
      mainNav: false,
      label: 'Review Contact Information',
      icon: 'search',
      requiresAuth: true,
      auth: [],
      category: 'Volunteers',
    },
  },
  'volunteer-elas': {
    path: '/volunteerelas/:volunteerID/:seasonUID',
    component: () =>
      import('src/components/VolunteerRegistration/VolunteerELAs.vue'),
    meta: {
      mainNav: false,
      label: 'Volunteer Info',
      icon: 'search',
      requiresAuth: true,
      auth: [],
      category: 'Volunteers',
    },
  },
  'volunteer-confirmation': {
    path: '/volunteer-confirmation/:volunteerID/:seasonUID',
    component: () =>
      import('src/components/VolunteerRegistration/VolunteerConfirmation.vue'),
    meta: {
      mainNav: false,
      label: 'Volunteer Info',
      icon: 'search',
      requiresAuth: true,
      auth: [],
      category: 'Volunteers',
    },
  },
  // 'registration-admin': {
  //   path: '/registrations/admin',
  //   component: () => import('src/components/Admin/Registration/Layout.vue'),
  //   meta: {
  //     mainNav: !isMobile,
  //     label: 'Registration Admin',
  //     icon: 'users-cog',
  //     requiresAuth: true,
  //     auth: ['inLeague'],
  //     category: 'Competitions',
  //   },
  // },
  'registration-admin.default': {
    path: '/registrations/admin',
    redirect: { name: 'competition-eligibility' },
    meta: {
      mainNav: false,
      auth: [],
      category: 'Competitions'
    }
  },
  'competition-eligibility': {
    path: '/competition-eligibility',
    component: () =>
      import(
        'src/components/Admin/SeasonCompetitions/PlayerEligibility.vue'
      ),
    meta: {
      mainNav: false,
      auth: [],
      category: 'Competitions'
    }
  },
  [R_UserEditor.RouteName.main]: {
    path: '/user-editor/:userID',
    disableTopLevelAutoNameInjection: true,
    name: R_UserEditor.RouteName.main,
    props: R_UserEditor.routeLocationToRouteProps,
    component: () => import('src/components/User/Editor/R_UserEditor'),
    meta: {
      mainNav: false,
      label: 'User Editor',
      icon: 'id-badge',
      requiresAuth: true,
      auth: [],
      category: 'User',
      kludgyMainContainerStyles: commonKludgyMainContainerStyles,
    },
    children: [
      {
        path: `security`,
        name: R_UserEditor.RouteName.security,
        component: () => import("src/components/NilComponent.vue"),
        beforeEnter: async (to, from, next) => {
          const targetUserID = to.params.userID;
          if (User.value.userID !== targetUserID && !authService(User.value.roles, "webmaster")) {
            _403(to, next)
          }
          else {
            next();
          }
        },
      },
      {
        path: `certs`,
        name: R_UserEditor.RouteName.certs,
        component: () => import("src/components/NilComponent.vue"),
        beforeEnter: async (to, from, next) => {
          if (R_UserEditor.authZ_viewVolunteerCerts()) {
            next()
          }
          else {
            _403(to, next);
          }
        },
      },
      {
        path: `log`,
        name: R_UserEditor.RouteName.log,
        component: () => import("src/components/NilComponent.vue"),
        beforeEnter: async (to, from, next) => {
          if (R_UserEditor.authZ_viewUserLog()) {
            next()
          }
          else {
            _403(to, next);
          }
        },
      },
      {
        path: `volunteerPrefs`,
        name: R_UserEditor.RouteName.volunteerPrefs,
        component: () => import("src/components/NilComponent.vue"),
        beforeEnter: async (to, from, next) => {
          if (R_UserEditor.authZ_viewVolunteerPrefs()) {
            next()
          }
          else {
            _403(to, next);
          }
        },
      },
    ]
  },
  'new-user': {
    path: '/new-user/:email?',
    component: () => import('src/components/User/NewUser.vue'),
    meta: {
      mainNav: false,
      label: 'Create New User',
      icon: 'id-badge',
      category: 'Volunteers',
      // this is "always available to everybody", but it really only makes sense in 3 cases:
      // - there is no logged in user (new user signup flow)
      // - the logged in user is adding a family member (effectively new user signup flow, but bound to some family)
      // - some registrar wants to sign up a new user (effectively "just" new user signup flow)
      //
      // we don't show a sidebar link for this at the moment
      alternateAuthRolesCheck: () => true
    },
  },
  'welcome': {
    path: '/welcome',
    component: () => import('src/components/User/NewUserDuplicateCheck.vue'),
    meta: {
      mainNav: false,
      label: 'Create New User',
      icon: 'id-card',
      category: 'Volunteers',
      alternateAuthRolesCheck: () => true
    },
  },
  'content-search': {
    path: '/content-search',
    component: () =>
      import('src/components/Admin/ContentChunks/ContentChunksSearch.vue'),
    meta: {
      mainNav: isMobile ? false : true,
      label: 'Content Search',
      icon: 'users-cog',
      requiresAuth: true,
      auth: ['inLeague', 'webmaster'],
      category: 'Utilities',
    },
  },
  'content-chunk-result': {
    path: '/content-chunk-result/:id',
    component: () =>
      import('src/components/Admin/ContentChunks/ContentChunk.vue'),
    meta: {
      mainNav: false,
      label: 'Content Chunk Result',
      requiresAuth: true,
      auth: ['inLeague', 'webmaster'],
      category: 'Utilities',
    },
  },
  'forgot-password': {
    path: '/forgot-password',
    component: () => import('src/components/User/RequestNewPwd.vue'),
    meta: {
      mainNav: true,
      label: 'Forgot Password',
      icon: 'user-lock',
      requiresAuth: false,
      requiresNoAuth: true,
      auth: [],
      category: 'Utilities',
    },
  },
  'update-password': {
    path: '/update-password',
    component: () => import('src/components/User/UpdatePwd.vue'),
    meta: {
      mainNav: true,
      label: 'Update Password',
      icon: 'user-lock',
      requiresAuth: true,
      auth: [],
      category: 'Utilities',
    },
  },
  'create-new-password': {
    path: '/new-password',
    component: () => import('src/components/User/ResetPassword.vue'),
    meta: {
      mainNav: false,
      label: 'Create New Password',
      icon: 'user-lock',
      requiresAuth: false,
      auth: [],
      category: 'Utilities',
    },
  },
  'event-questions': {
    path: `/event-questions`,
    props: true,
    component: () => import('src/components/Events/R_EventQuestions.vue'),
    meta: {
      mainNav: true,
      label: 'Event Questions',
      icon: 'circle-question',
      requiresAuth: true,
      auth: ["registrar"],
      category: 'Events',
    },
  },
  'event-roster': {
    path: `/event-roster/:eventID(${pathGuidPattern})`,
    props: true,
    component: () => import('src/components/Events/R_EventRoster'),
    beforeEnter: hasEventRoster_navGuard,
    meta: {
      mainNav: false,
      label: 'Event Roster',
      icon: 'calendar-day',
      requiresAuth: true,
      auth: "DEFER-TO-BEFORE-ENTER",
      category: 'Events',
    },
  },
  'event-signin-sheet': {
    path: `/event-signin-sheet/:eventID(${pathGuidPattern})`,
    props: true,
    component: () => import("src/components/Events/R_EventSigninSheet"),
    beforeEnter: hasEventSigninSheet_navGuard,
    meta: {
      mainNav: false,
      label: 'Events',
      icon: 'calendar-day',
      requiresAuth: true,
      auth: "DEFER-TO-BEFORE-ENTER",
      category: 'Events',
      containerLayoutStrategy: "unconstrained",
      kludgyMainContainerStyles: commonKludgyMainContainerStyles,
    },
  },
  'event-list': {
    path: '/event-list',
    component: () => import('src/components/Events/R_EventListing'),
    meta: {
      mainNav: isMobile ? false : true,
      label: 'Events',
      icon: 'calendar-day',
      requiresAuth: true,
      auth: [],
      category: 'Events',
    },
  },
  ...eventEditorRoutes(),
  [R_EventSignup.RouteName.main]: {
    path: `/event-signup/:eventID(${pathGuidPattern})`,
    component: () => import('src/components/Events/R_EventSignup.vue'),
    props: true,
    meta: {
      mainNav: false,
      label: 'Event Signup',
      icon: 'calendar-day',
      requiresAuth: true,
      auth: [],
      category: 'Events'
    },
  },
  scheduleID: {
    path: '/schedule/:competitionID?/:divID?',
    props: true,
    component: () => import('src/components/Schedule/page/R_GameSchedules.vue'),
    meta: {
      mainNav: true,
      label: 'Game Schedule',
      icon: 'calendar',
      requiresAuth: false,
      auth: [],
      category: 'Games',
      kludgyMainContainerStyles: commonKludgyMainContainerStyles,
    },
  },
  messages: {
    path: '/messages',
    component: () => import('src/components/Messenger/MessageList.vue'),
    meta: {
      mainNav: true,
      label: 'My Messages',
      icon: 'comments',
      requiresAuth: true,
      auth: ['Coach', 'hasUnembargoedPlayer'],
      category: 'Messaging',
    },
  },
  "message-thread": {
    path: '/message-thread/:id',
    component: () => import('src/components/Messenger/MessageThread.vue'),
    props: true,
    meta: {
      mainNav: false,
      label: 'Message Thread',
      icon: 'clipboard-check',
      requiresAuth: true,
      auth: ['Coach', 'hasUnembargoedPlayer'],
      category: 'Messaging',
    },
  },
  'new-message':
  {
    path: '/new-message',
    component: () => import('src/components/Messenger/NewMessage.vue'),
    meta: {
      mainNav: false,
      label: 'New Message',
      icon: 'clipboard-check',
      requiresAuth: true,
      auth: ['Coach', 'hasUnembargoedPlayer'],
      category: 'Messaging',
    },
  },
  'referee-scheduler': {
    name: 'referee-scheduler',
    disableTopLevelAutoNameInjection: true,
    path: '/referee-scheduler',
    component: () => import('src/components/RefereeSchedule/R_RefereeSchedule.vue'),
    meta: {
      mainNavLazy: () => R_RefereeSchedule.hasSomeRoutePermission(),
      label: 'Referee Scheduler',
      icon: 'clipboard-check',
      requiresAuth: true,
      auth: "DEFER-TO-BEFORE-ENTER",
      category: 'Referees',
      containerLayoutStrategy: "unconstrained",
      kludgyMainContainerStyles: commonKludgyMainContainerStyles,
      allowBigModeSidebarCollapse: true,
    },
    beforeEnter: (to, _from, next) => {
      if (R_RefereeSchedule.hasSomeRoutePermission()) {
        next()
      }
      else {
        _403(to, next)
      }
    },
    children: [
      {
        // modal
        path: `match-report/:gameID`,
        props: true,
        name: 'match-report',
        component: () => import('src/components/RefereeSchedule/R_MatchReport'),
        meta: {
          noScroll: true
        }
      },
    ],
  },
  'add-player': {
    path: '/add-player/:familyID?',
    component: () => import('src/components/Registration/NewPlayer/AddPlayer.vue'),
    meta: {
      mainNav: isMobile ? false : true,
      label: 'Add Player',
      icon: 'plus-square',
      requiresAuth: true,
      auth: [],
      category: 'Players'
    },
  },
  "family-profile": {
    disableTopLevelAutoNameInjection: true,
    name: FamilyProfile.RouteName.default,
    path: `/family-profile`,
    component: () => import('src/components/FamilyProfile/pages/FamilyProfile.vue'),
    props: (v) : FamilyProfile.Props => {
      const commonDefaultProps : FamilyProfile.Props = { wrapper: { name: FamilyProfile.RouteName.default} };
      const longestMatchName = v.matched[v.matched.length-1].name;
      switch (longestMatchName) {
        case FamilyProfile.RouteName.default: {
          return commonDefaultProps;
        }
        case FamilyProfile.RouteName.singularFamily: {
          const singularFamilyID = typeof v.params.singularFamilyID === "string" ? v.params.singularFamilyID : null;
          if (!singularFamilyID) {
            return commonDefaultProps;
          }
          return {
            wrapper: {
              name: FamilyProfile.RouteName.singularFamily,
              singularFamilyID: singularFamilyID
            }
          }
        }
        case FamilyProfile.RouteName.user: {
          const userID = typeof v.params.userID === "string" ? v.params.userID : null;
          if (!userID) {
            return commonDefaultProps;
          }
          return {
            wrapper: {
              name: FamilyProfile.RouteName.user,
              userID: userID,
            }
          }
        }
        case FamilyProfile.RouteName.child: {
          const childID = typeof v.params.childID === "string" ? v.params.childID : null;
          if (!childID) {
            return commonDefaultProps;
          }
          return {
            wrapper: {
              name: FamilyProfile.RouteName.child,
              childID: childID
            }
          }
        }
        // shouldn't be reachable
        // but, if for some reason we do get here let's have some consistent, non-crashing behavior
        // we could do an exhaustiveCaseGuard here but we are not really in control of what the input is (it comes from a user supplied URL),
        // so it is not guaranteed that longestNameMatch : FamilyProfile.RouteName
        default: {
          return commonDefaultProps;
        }
      }
    },
    children: [
      // we have children routes just to match on seperate ID types, both of which are otherwise indistinguishable GUID types
      {
        path: `:singularFamilyID(${pathGuidPattern})`,
        name: FamilyProfile.RouteName.singularFamily,
        component: () => import("src/components/NilComponent.vue"),
      },
      {
        path: `u/:userID(${pathGuidPattern})`,
        name: FamilyProfile.RouteName.user,
        component: () => import("src/components/NilComponent.vue"),
      },
      {
        path: `c/:childID(${pathGuidPattern})`,
        name: FamilyProfile.RouteName.child,
        component: () => import("src/components/NilComponent.vue"),
      }
    ],
    meta: {
      mainNav: false,
      label: 'Family Profile',
      icon: 'plus-square',
      requiresAuth: true,
      auth: [],
      category: 'Players'
    },
  },
  'edit-content-chunk': {
    path: '/edit-content-chunk/:id',
    component: () => import('src/components/Registration/admin/contentChunk/EditContentChunk.vue'),
    meta: {
      mainNav: false,
      label: 'Edit Registration Content Chunk',
      icon: 'plus-square',
      requiresAuth: true,
      auth: [],
      category: 'Utilities'
    },
  },
  'edit-question': {
    path: '/edit-question/:id',
    component: () => import('src/components/Registration/admin/question/R_EditQuestion.vue'),
    meta: {
      mainNav: false,
      label: 'Edit Registration Question',
      icon: 'pencil-alt',
      requiresAuth: true,
      auth: [],
      category: 'Utilities'
    },
  },
  // this approach at building a workflow does work, but makes it difficult to look at the flowroot/flowentry ("R_VolunteerRegistrationFlow.vue")
  // and see what the flowsteps are built out of; searching for the particular flowstep components brings us here (their names are never uttered from the flowroot),
  // then we need to jump into the flowroot to see their usage; but at their use site, each component's handlers and etc. are cryptically hidden away in args passed to a child router-view
  // which magically references these component names; this can be thought of as a magic import syntax that injects components into R_VolunteerRegistrationFlow.vue
  'volunteer-registration-flow': {
    path: '/volunteer',
    component: () => import('src/components/VolunteerRegistration/R_VolunteerRegistrationFlow.vue'),
    disableTopLevelAutoNameInjection: true,
    props: true,
    children: [
      {
        path: "user",
        name: R_VolunteerRegistrationFlow.FlowStepRouteName.user,
        props: {},
        component: () => import('src/components/VolunteerRegistration/SelectVolunteer2.vue'),
        beforeEnter: R_VolunteerRegistrationFlow.maintenanceNavigationGuard,
      },
      {
        path: ":volunteerID?",
        name: R_VolunteerRegistrationFlow.FlowStepRouteName.season,
        props: { role: "user" },
        component: () => import('src/components/Registration/selections/season-just-emit.vue'),
        beforeEnter: R_VolunteerRegistrationFlow.maintenanceNavigationGuard,
      },
      {
        path: "contact/:volunteerID/:seasonUID",
        name: R_VolunteerRegistrationFlow.FlowStepRouteName.contactInfo,
        props: true,
        component: () => import('src/components/VolunteerRegistration/VerificationPage.vue'),
        beforeEnter: R_VolunteerRegistrationFlow.maintenanceNavigationGuard,
      },
      {
        path: "roles/:volunteerID/:seasonUID",
        name: R_VolunteerRegistrationFlow.FlowStepRouteName.volunteerRoles,
        props: true,
        component: () => import('src/components/VolunteerRegistration/SelectRoles.vue'),
        beforeEnter: R_VolunteerRegistrationFlow.maintenanceNavigationGuard,
      },
      {
        path: "nameAndDob/:volunteerID/:seasonUID",
        name: R_VolunteerRegistrationFlow.FlowStepRouteName.hasNoStackKeyNeedsNameAndDobEdit,
        props: true,
        component: () => import('src/components/VolunteerRegistration/UserNameAndDob.vue'),
        beforeEnter: R_VolunteerRegistrationFlow.maintenanceNavigationGuard,
      },
      {
        path: "elas/:volunteerID/:seasonUID",
        name: R_VolunteerRegistrationFlow.FlowStepRouteName.elas,
        props: true,
        component: () => import('src/components/VolunteerRegistration/VolunteerELAs.vue'),
        beforeEnter: R_VolunteerRegistrationFlow.maintenanceNavigationGuard,
      },
      {
        path: "done/:volunteerID/:seasonUID",
        name: R_VolunteerRegistrationFlow.FlowStepRouteName.done,
        props: true,
        component: () => import('src/components/VolunteerRegistration/VolunteerConfirmation.vue'),
        // maybe should have no guard for this, if the user got all the way through right before the cutoff?
        beforeEnter: R_VolunteerRegistrationFlow.maintenanceNavigationGuard,
      },
      {
        path: "unavailable",
        name: R_VolunteerRegistrationFlow.FlowStepRouteName.unavailable,
        props: true,
        component: () => import('src/components/VolunteerRegistration/Unavailable.vue')
      }
    ],
    meta: {
      mainNav: false,
      label: 'volunteer registration flow',
      icon: 'pencil-alt',
      requiresAuth: true,
      auth: [],
      category: 'Utilities'
    }
  },
  [R_Checkout.RouteNames.main]: {
    path: `/checkout/:invoiceInstanceIDs(${pathIntPattern})+`,
    component: () => import('src/components/Payment/pages/R_Checkout'),
    props: true,
    meta: {
      mainNav: false,
      label: 'Checkout',
      icon: 'dollar-sign',
      requiresAuth: true,
      auth: [],
      kludgyMainContainerStyles: commonKludgyMainContainerStyles,
    },
  },
  'payable-invoice-listing': {
    path: '/invoices/current',
    component: () => import('src/components/Navigational/R_PayableInvoiceListing'),
    meta: {
      mainNav: false,
      label: 'Invoices',
      icon: 'dollar-sign',
      requiresAuth: true,
      auth: [],
    },
  },
  'test-checkout': {
    path: '/test-checkout',
    component: () => import('src/components/Payment/IOSPaymentTool.vue'),
    meta: {
      mainNav: false,
      label: 'Invoices',
      icon: 'dollar-sign',
      requiresAuth: true,
      auth: [],
    },
  },
  [R_MasterInvoice.RouteName.main]: {
    path: '/invoice/:invoiceID',
    component: () => import('src/components/Payment/pages/MasterInvoice.vue'),
    props: true,
    meta: {
      mainNav: false,
      label: 'Invoice',
      icon: 'file-invoice',
      requiresAuth: true,
      auth: [],
    },
  },
  'e-train-u': {
    path: '/eTrainU',
    component: () => import('src/components/ETrainU/ETrainU.vue'),
    meta: {
      mainNav: true,
      label: 'eTrainU',
      icon: 'graduation-cap',
      requiresAuth: true,
      auth: [],
      category: 'Volunteers'
    },
  },
  // 'not-authorized': {
  //   path: '/not-authorized',
  //   component: () => import('src/components/Navigational/pages/NotAuthorizedPage.vue'),
  //   meta: {
  //     mainNav: !isMobile,
  //     label: 'Not Authorized',
  //     icon: 'ban',
  //     requiresAuth: false,
  //     auth: [],
  //   },
  // },
  'purchase-confirmation': {
    path: '/purchase-confirmation',
    component: () =>
      import('src/components/Payment/pages/PurchaseConfirmation.vue'),
    meta: {
      mainNav: false,
      label: 'Event Registration Confirmation',
      icon: 'check-circle',
      requiresAuth: true,
      auth: [],
    },
  },
  'confirm-invoice-void': {
    path: '/confirm-invoice-void/:invoiceID',
    component: () => import('src/components/Payment/ReviewAndVoidInvoice.vue'),
    meta: {
      mainNav: false,
      label: 'Confirm Invoice Void',
      icon: 'check-circle',
      requiresAuth: true,
      auth: [],
    },
  },
  'mobile-select-league': {
    path: '/mobile-select-league',
    component: () =>
      import('src/components/Mobile/Navigational/selectLeague.vue'),
    meta: {
      mainNav: false,
      label: 'Select League',
      icon: 'clipboard-check',
      requiresAuth: false,
      auth: [],
    },
  },
  'mobile-select-league-login': {
    path: '/mobile-select-league-login',
    component: () =>
      import('src/components/Mobile/Navigational/selectLeagueLogin.vue'),
    meta: {
      mainNav: false,
      label: 'Select League',
      icon: 'clipboard-check',
      requiresAuth: false,
      requiresNoAuth: true,
      auth: [],
    },
  },
  // Component for auth testing
  'webmaster-only': {
    path: '/secure',
    component: () => import('src/components/User/SecureTest.vue'),
    meta: {
      mainNav: false,
      label: 'Webmaster Only',
      icon: 'tools',
      requiresAuth: true,
      auth: ['webmaster'],
    },
  },
  forbidden: {
    path: '/forbidden',
    component: () => import('src/components/pages/Forbidden403.vue'),
    meta: {
      mainNav: false,
      label: '403',
      requiresAuth: false,
      auth: [],
    },
  },
  login: {
    path: '/login',
    name: "login",
    disableTopLevelAutoNameInjection: true,
    component: () => import('src/components/Navigational/pages/Login'),
    beforeEnter: (to, from, next) => {
      if (User.isLoggedIn) {
        next({name: "home"})
      }
      else {
        next();
      }
    },
    children: [
      // we have children routes just to match on seperate ID types, both of which are otherwise indistinguishable GUID types
      //
      // mfa init
      //
      {
        path: `mfaInit/options`,
        name: R_Mfa.MfaInitRouteName.options,
        component: () => import("src/components/NilComponent.vue"),
        beforeEnter: (from, to, next) => {
          if (User.loginState.state === "mfa-init-flow") {
            next();
          }
          else {
            next({name: "login"})
          }
        }
      },
      {
        path: `mfaInit/sms`,
        name: R_Mfa.MfaInitRouteName.sms,
        component: () => import("src/components/NilComponent.vue"),
        beforeEnter: (from, to, next) => {
          if (User.loginState.state === "mfa-init-flow") {
            next();
          }
          else {
            next({name: "login"})
          }
        }
      },
      {
        path: `mfaInit/totp`,
        name: R_Mfa.MfaInitRouteName.totp,
        component: () => import("src/components/NilComponent.vue"),
        beforeEnter: (from, to, next) => {
          if (User.loginState.state === "mfa-init-flow") {
            next();
          }
          else {
            next({name: "login"})
          }
        }
      },
      {
        path: `mfaInit/complete`,
        name: R_Mfa.MfaInitRouteName.complete,
        component: () => import("src/components/NilComponent.vue"),
        beforeEnter: (from, to, next) => {
          if (User.loginState.state === "mfa-init-flow-complete") {
            next();
          }
          else {
            next({name: "login"})
          }
        }
      },

      //
      // mfa challenge
      //
      {
        path: `mfaChallenge/options`,
        name: R_Mfa.MfaChallengeRouteName.options,
        component: () => import("src/components/NilComponent.vue"),
        beforeEnter: (from, to, next) => {
          if (User.loginState.state === "mfa-challenge-flow") {
            next();
          }
          else {
            next({name: "login"})
          }
        }
      },
      {
        path: `mfaChallenge/sms`,
        name: R_Mfa.MfaChallengeRouteName.sms,
        component: () => import("src/components/NilComponent.vue"),
        beforeEnter: (from, to, next) => {
          if (User.loginState.state === "mfa-challenge-flow") {
            next();
          }
          else {
            next({name: "login"})
          }
        }
      },
      {
        path: `mfaChallenge/totp`,
        name: R_Mfa.MfaChallengeRouteName.totp,
        component: () => import("src/components/NilComponent.vue"),
        beforeEnter: (from, to, next) => {
          if (User.loginState.state === "mfa-challenge-flow") {
            next();
          }
          else {
            next({name: "login"})
          }
        }
      },
    ],
    meta: {
      mainNav: true,
      label: 'Login',
      icon: 'user',
      requiresAuth: false,
      requiresNoAuth: true,
      auth: [],
    },
  },
  'pay-with-cash-or-check': {
    path: '/pay-with-cash-or-check',
    component: () => import('src/components/Payment/CashOrCheck.vue'),
    meta: {
      mainNav: true,
      label: 'Cash and Check Payments',
      icon: 'dollar-sign',
      requiresAuth: true,
      requiresNoAuth: false,
      auth: ["registrar"],
      category: "Competitions"
    },
  },
  'merge-players': {
    path: MergePlayers.path,
    component: () => import('src/components/Admin/MergePlayers.vue'),
    meta: {
      mainNav: true,
      label: 'Merge players',
      icon: 'merge',
      requiresAuth: true,
      requiresNoAuth: false,
      auth: ["inLeague"],
      category: "Utilities"
    },
  },
  [R_CompRegsWithBlockedPaymentIntents.RouteName.main]: {
    path: '/competitionRegistrations/blocked',
    component: () => import('src/components/Admin/R_CompRegsWithBlockedPaymentIntents'),
    props: (routeInfo) : R_CompRegsWithBlockedPaymentIntents.Props => {
      return {
        detail: {
          name: R_CompRegsWithBlockedPaymentIntents.RouteName["main"],
          query: {
            registrationID: guidOr(routeInfo.query.registrationID, undefined)
          }
        }
      }
    },
    meta: {
      mainNav: true,
      category: "Competitions",
      label: 'Waitlist Manager',
      icon: 'home',
      requiresAuth: true,
      alternateAuthRolesCheck: async () => {
        if (typeof User.value.userData === "string") {
          // not logged in
          return false;
        }

        // we don't know ahead of time which particular competitions we'll be targeting, so we check if this user has some
        // access permission for "any" competition
        return R_CompRegsWithBlockedPaymentIntents.hasSomeRelevantAccessPermission(User.value.userData, "any");
      }
    },
  },
  'mobile-landing': {
    path: '/mobile-landing',
    component: () =>
      import('src/components/Mobile/Navigational/mobileLanding.vue'),
    meta: {
      mainNav: false,
      label: 'Mobile Landing',
      icon: 'home',
      requiresAuth: true,
      auth: [],
    },
  },
  "legacyLink-venueManager": {
    path: '/legacy',
    component: () => import('src/components/NilComponent.vue'),
    legacyLink: {eventTarget: "eventMgr/venues"},
    meta: {
      category: "Events",
      label: 'Venue Manager',
      mainNav: !isMobile,
      icon: 'location-dot',
      requiresAuth: true,
      auth: ["registrar"],
    },
  },
  "legacyLink-donations": {
    path: '/legacy',
    component: () => import('src/components/NilComponent.vue'),
    legacyLink: {eventTarget: "invoices/donations"},
    meta: {
      category: undefined,
      label: "Donations",
      mainNav: true,
      icon: 'fa-gift',
      requiresAuth: true,
      auth: [],
    },
  },
  ...competitionRegistrationJourneyRoutes(),
  ...couponRoutes(),
  ...tournamentRoutes(),
  ...teamRoutes(),
  ...gameScoreRoutes(),
  ...gameSchedulerRoutes(),
  ...seasonManagerRoutes(),
  ...reportBuilderRoutes(),
  ...divisionEditorRoutes(),
  ...playerRatingsRoutes(),
  "mojoExport": {
    path: '/mojoExport',
    component: () => import('src/components/MojoExports/R_MojoExports'),
    meta: {
      mainNavLazy: () => R_MojoExports.hasSomeRoutePermission(),
      category: "Utilities",
      label: "Mojo Export",
      requiresAuth: false,
      icon: 'file-export',
      auth: "DEFER-TO-BEFORE-ENTER",
    },
    beforeEnter: (to, from, next) => {
      if (!R_MojoExports.hasSomeRoutePermission()) {
        _403(to, next)
      }
      else {
        next()
      }
    }
  },
  "claim-account": {
    path: '/claim-account',
    component: () => import('src/components/User/R_ClaimAccount'),
    legacyLink: {eventTarget: "eventMgr/venues"},
    meta: {
      category: "User",
      label: '',
      mainNav: false,
      requiresAuth: false,
      auth: []
    },
  },
  "legacyLink-bulkUploader": {
    path: '/legacy',
    component: () => import('src/components/NilComponent.vue'),
    legacyLink: {eventTarget: "eventMgr/bulkUpload"},
    meta: {
      category: "Events",
      label: 'Bulk Uploader',
      mainNav: !isMobile,
      icon: 'table', // ?
      requiresAuth: true,
      auth: ["registrar"],
    },
  },
  fourOhFour: {
    path: '/:pathMatch(.*)*',
    component: () => import('src/components/pages/Error404.vue'),
    meta: {
      mainNav: false,
      label: '404',
      requiresAuth: false,
      auth: [],
    },
  },
}

export default routes

function eventEditorRoutes() : Record<string, RouteRecordRaw & RouteRecordExtension> {
  return {
    [R_EventEditor.RouteName.new]: {
      path: `/event-editor/new`,
      component: () => import('src/components/Events/R_EventEditor.vue'),
      props: () : R_EventEditor.Props => {
        return {
          detail: {
            name: R_EventEditor.RouteName.new
          }
        }
      },
      meta: {
        mainNav: isMobile ? false : true,
        label: 'New event',
        icon: 'calendar-day',
        requiresAuth: true,
        auth: ["registrar", "eventAdmin"],
        alternateAuthRolesCheck: isCoachForActiveSeason,
        category: 'Events',
      },
    },
    [R_EventEditor.RouteName.edit]: {
      path: `/event-editor/edit/:eventID(${pathGuidPattern})`,
      component: () => import('src/components/Events/R_EventEditor.vue'),
      props: (routeInfo) : R_EventEditor.Props => {
        return {
          detail: {
            name: R_EventEditor.RouteName.edit,
            eventID: routeInfo.params.eventID as string
          }
        }
      },
      meta: {
        mainNav: false,
        label: 'Edit event',
        icon: 'calendar-day',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Events',
      },
      beforeEnter: async (to, from, next) => {
        // user not logged in? can't proceed (does the "requiresAuth" predicate run before this? would be nice)
        if (!User.isLoggedIn) {
          // fixme: what's the default behavior for "go to home, show login modal"
          next({ name: "home", params: {} })
          return;
        }

        if (authService(User.value.roles, "registrar", "eventAdmin")) {
          next();
          return;
        }

        const event = await events.get(axiosInstance, to.params.eventID as string);

        // business rule: if the user email is exactly the event contact email, then the user has registrar-like permission for this event
        if (event.contactEmail.trim() && event.contactEmail.toLowerCase().trim() === User.value.userEmail.toLowerCase().trim()) {
          next();
          return;
        }

        if (!event.teamID) {
          next({ name: "forbidden", params: {} });
          return;
        }

        const isHeadCoachForTeam = !!(User.value.userData as UserData)
          .coachAssignmentsMemento
          .find(v => v.teamID === event.teamID && (v.title === "Head Coach" || v.title === "Co-Coach"));

        if (isHeadCoachForTeam) {
          next();
          return;
        }

        // catch-all
        _403(to, next)
      },
    },
    [R_EventEditor.RouteName.clone]: {
      path: `/event-editor/clone/:sourceEventID(${pathGuidPattern})`,
      component: () => import('src/components/Events/R_EventEditor.vue'),
      props: (routeInfo) : R_EventEditor.Props => {
        return {
          detail: {
            name: R_EventEditor.RouteName.clone,
            sourceEventID: routeInfo.params.sourceEventID as string
          }
        }
      },
      meta: {
        mainNav: false,
        label: 'Edit event',
        icon: 'calendar-day',
        requiresAuth: true,
        auth: ["registrar", "eventAdmin"],
        category: 'Events',
      }
    },
  }

  // todo -- is this logic correct? The intent (in the name) seems correct, but the logic
  // as implemented might be "is coach for any season"?
  function isCoachForActiveSeason() : boolean {
    if (typeof User.value.userData === "string") {
      return false;
    }
    else {
      return AuthService.hasSomeCoachAssignmentTitle(User.value.userData.coachAssignmentsMemento, "Head Coach", "Co-Coach");
    }
  }
}

/**
 * There's no single "has coupon permission", a user "has coupon permission" effectively on a per-coupon basis.
 * E.g. if a coupon is for some event and the user is superuser for that event, they have permission for that coupon, but not necessarly
 * any other coupons.
 *
 * This means to guard coupon routes, we need to know coupon, but we won't know the coupon until we hit the route and do some searching.
 * So while the coupon routes may be accessible by everybody, we only show links to such routes to particular users in cases where we know things about
 * which coupons we're interested in and what permissions said users have.
 *
 * The backend helps out with this by never returning coupons that a user does not have permission to see, so a user who doesn't have
 * appropriate coupon permissions can manually type in routes, but their searches shouldn't yield any results.
 */
function couponRoutes() : Record<string, RouteRecordRaw & RouteRecordExtension> {
  return {
    [R_CouponManager.RouteName.main]: {
      path: '/coupons',
      component: () => import('src/components/Coupons/R_CouponManager'),
      props: (v) : R_CouponManager.Props => {
        return {
          detail: {
            name: R_CouponManager.RouteName.main,
            eventID: guidOr(v.query.eventID, undefined),
          }
        }
      },
      meta: {
        category: 'Competitions',
        label: 'Coupon Manager',
        mainNavLazy: () => authService(User.value.roles, "registrar", "eventAdmin"),
        icon: 'tags',
        requiresAuth: true,
        auth: [],
      },
    },
    [R_CouponEditor.RouteName.new]: {
      path: '/coupons/editor/new',
      component: () => import('src/components/Coupons/R_CouponEditor'),
      props: () : R_CouponEditor.Props => {
        return {detail: {name: R_CouponEditor.RouteName.new}}
      },
      meta: {
        category: 'Competitions',
        label: 'Coupon Manager',
        mainNav: false,
        icon: 'dollar',
        requiresAuth: true,
        auth: [],
      },
    },
    [R_CouponEditor.RouteName.edit]: {
      path: `/coupons/editor/edit/:couponID(${pathGuidPattern})`,
      component: () => import('src/components/Coupons/R_CouponEditor'),
      props: (route) : R_CouponEditor.Props => {
        return {detail: {name: R_CouponEditor.RouteName.edit, couponID: route.params.couponID as string}};
      },
      meta: {
        category: 'Utilities',
        label: 'Coupon Editor (edit existing)',
        mainNav: false,
        icon: 'dollar',
        requiresAuth: true,
        auth: [],
      },
    },
  }
}

function tournamentRoutes() : Record<string, RouteRecordRaw & RouteRecordExtension> {
  return {
    [R_TournamentHostingManager.RouteNames.main]: {
      path: '/tournaments/admin/hosting',
      component: () => import('src/components/Tournaments/R_TournamentHostingManager'),
      meta: {
        mainNavLazy: () => isAuthorizedToManageSomeTournament(),
        label: 'Tournament Setup',
        icon: 'trophy-star',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Tournaments',
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
      },
      beforeEnter: simpleNavGuardOf(() => isAuthorizedToManageSomeTournament()),
    },
    [R_TournamentTeamRegPageItemListing.RouteNames.main]: {
      path: '/tournaments/admin/page-items',
      component: () => import('src/components/Tournaments/R_TournamentTeamRegPageItemListing'),
      props: R_TournamentTeamRegPageItemListing.routeLocationToProps,
      meta: {
        mainNavLazy: () => isAuthorizedToManageSomeTournament(),
        label: 'Edit Registration Form',
        icon: 'tools',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Tournaments'
      },
      beforeEnter: simpleNavGuardOf(() => isAuthorizedToManageSomeTournament()),
      children: [
        {
          path: `preview/:tournamentID(${pathIntPattern})`,
          name: R_TournamentTeamRegPageItemListing.RouteNames.preview,
          component: () => import('src/components/NilComponent.vue'),
        }
      ]
    },
    [R_TournamentTeamRegPageItemEditor.RouteNames.base]: {
      path: `/tournaments/admin/page-items/:tournamentID(${pathIntPattern})`,
      props: route => R_TournamentTeamRegPageItemEditor.routeLocationToProps(route),
      component: () => import('src/components/Tournaments/R_TournamentTeamRegPageItemEditor'),
      beforeEnter: async (to, from, next) => {
        if (to.name === R_TournamentTeamRegPageItemEditor.RouteNames.base) {
          // "base route is same as ./new", probably we could tidy this up
          const redirectTo = R_TournamentTeamRegPageItemEditor.routeDetailToRouteLocation({
            name: R_TournamentTeamRegPageItemEditor.RouteNames.new,
            tournamentID: to.params.tournamentID as iltypes.Integerlike
          })
          next(redirectTo);
        }
        else {
          const noToastAxios = freshAxiosInstance({useCurrentBearerToken: true, responseInterceptors: []})
          const tournamentID = R_TournamentTeamRegPageItemEditor.routeLocationToProps(to).detail.tournamentID;

          let competitionUID : iltypes.Guid | undefined;

          try {
            competitionUID = await tournamentStore
              .maybeGetTournamentIfExists(noToastAxios, {tournamentID})
              .then(tourn => tourn?.value.competitionUID)
          }
          catch {
            // permissions errors? tournament doesn't exist?
            competitionUID = undefined;
          }

          if (!competitionUID || !isAuthorizedToManageTournament(competitionUID, User.value)) {
            _403(to, next);
          }
          else {
            // this will fire for all child routes, too, but that's fine;
            // just nav to the childroute
            next();
          }
        }
      },
      meta: {
        mainNav: false,
        label: '',
        icon: 'id-badge',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Tournaments'
      },
      children: [
        {
          path: `new`,
          name: R_TournamentTeamRegPageItemEditor.RouteNames.new,
          component: () => import('src/components/NilComponent.vue'),
        },
        {
          path: `edit/:pageItemID(${pathIntPattern})`,
          name: R_TournamentTeamRegPageItemEditor.RouteNames.edit,
          component: () => import('src/components/NilComponent.vue'),
        },
      ]
    },
    [R_TournamentTeamAdminListing.RouteName]: {
      path: '/tournaments/teams/admin-listing',
      component: () => import('src/components/Tournaments/R_TournamentTeamAdminListing'),
      meta: {
        mainNavLazy: () => isAuthorizedToManageSomeTournament(),
        label: 'Tournament Team Admin',
        icon: 'clipboard-list',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Tournaments',
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
        containerLayoutStrategy: "unconstrained",
        useRouterViewRootAsWindowlikeScrollContainer: true,
      },
      beforeEnter: simpleNavGuardOf(() => isAuthorizedToManageSomeTournament()),
    },
    [R_TournamentTeamListing.RouteName]: {
      path: '/tournaments/teams/my-teams',
      component: () => import('src/components/Tournaments/R_TournamentTeamListing'),
      meta: {
        // this is mutually exclusive with admin-listing
        mainNavLazy: () => !isAuthorizedToManageSomeTournament() && isAuthorizedToManageSomeTournamentTeam(),
        label: 'My Tournament Teams',
        icon: 'clipboard-list',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Tournaments',
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
        containerLayoutStrategy: "unconstrained",
        useRouterViewRootAsWindowlikeScrollContainer: true,
      },
      beforeEnter: simpleNavGuardOf(() => isAuthorizedToManageSomeTournamentTeam())
    },
    [R_TournamentTeamCreate.RouteNames.base]: {
      path: '/tournaments/teams/create',
      disableTopLevelAutoNameInjection: true,
      name: R_TournamentTeamCreate.RouteNames.base,
      component: () => import('src/components/Tournaments/R_TournamentTeamCreate'),
      props: route => R_TournamentTeamCreate.routeLocationToProps(route),
      meta: {
        mainNavLazy: () => hasCreateTournamentTeamRoutePermission(),
        label: 'Tournament Registration',
        icon: 'circle-plus',
        requiresAuth: false,
        auth: [],
        category: 'Tournaments',
      },
      children: [
        {
          path: "season",
          name: R_TournamentTeamCreate.RouteNames.ChooseSeason,
          component: () => import('src/components/NilComponent.vue'),
        },
        {
          path: `program/:seasonUID(${pathGuidPattern})`,
          name: R_TournamentTeamCreate.RouteNames.ChooseCompetition,
          component: () => import('src/components/NilComponent.vue'),
        },
        {
          path: `div/banner/:seasonUID(${pathGuidPattern})/:competitionUID(${pathGuidPattern})/:tournamentID(${pathIntPattern})`,
          name: R_TournamentTeamCreate.RouteNames.InfoBanner_PreChooseDiv,
          component: () => import('src/components/NilComponent.vue'),
        },
        {
          path: `div/:seasonUID(${pathGuidPattern})/:competitionUID(${pathGuidPattern})/:tournamentID(${pathIntPattern})`,
          name: R_TournamentTeamCreate.RouteNames.ChooseDivision,
          component: () => import('src/components/NilComponent.vue'),
        },
        {
          path: `team/:seasonUID(${pathGuidPattern})/:competitionUID(${pathGuidPattern})/:tournamentID(${pathIntPattern})/:divID(${pathGuidPattern})`,
          name: R_TournamentTeamCreate.RouteNames.CreateTeam,
          component: () => import('src/components/NilComponent.vue'),
        },
        {
          path: `questions/:tournamentTeamID(${pathIntPattern})`,
          name: R_TournamentTeamCreate.RouteNames.Questions,
          component: () => import('src/components/NilComponent.vue'),
        },
        {
          path: `coaches/:tournamentTeamID(${pathIntPattern})`,
          name: R_TournamentTeamCreate.RouteNames.Coaches,
          component: () => import('src/components/NilComponent.vue'),
        },
        {
          path: `referees/:tournamentTeamID(${pathIntPattern})`,
          name: R_TournamentTeamCreate.RouteNames.Referees,
          component: () => import('src/components/NilComponent.vue'),
        },
        {
          path: `complete/:tournamentTeamID(${pathIntPattern})`,
          name: R_TournamentTeamCreate.RouteNames.Complete,
          component: () => import('src/components/NilComponent.vue'),
        },
      ]
    },
    [R_TournamentTeamConfigurator.RouteNames.main]: {
      path: `/tournaments/registration/:tournamentTeamID(${pathIntPattern})`,
      component: () => import('src/components/Tournaments/R_TournamentTeamConfigurator'),
      props: true,
      meta: {
        mainNav: false,
        category: "Tournaments",
        label: 'Tournament Registration',
        icon: 'location-dot',
        requiresAuth: true,
        auth: [],
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
      },
    },
    [R_TournamentTeamReport.RouteName]: {
      path: `/tournaments/reports/a`,
      component: () => import('src/components/Tournaments/R_TournamentTeamReport'),
      meta: {
        mainNavLazy: () => !System.value.isMobile && isAuthorizedToManageSomeTournament(),
        category: "Tournaments",
        label: 'Tournament Overview',
        icon: 'clipboard-list',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
        containerLayoutStrategy: "unconstrained",
        useRouterViewRootAsWindowlikeScrollContainer: true,
        allowBigModeSidebarCollapse: true,
      },
      beforeEnter: simpleNavGuardOf(() => isAuthorizedToManageSomeTournament())
    },
    [R_TournamentRefManager.RouteNames.main]: {
      path: `/tournaments/admin/refs`,
      component: () => import('src/components/Tournaments/R_TournamentRefManager'),
      meta: {
        mainNavLazy: () => R_TournamentRefManager.hasRoutePermission(),
        category: "Tournaments",
        label: 'Tournament Referees',
        icon: 'clipboard-list',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
      },
      beforeEnter: simpleNavGuardOf(() => R_TournamentRefManager.hasRoutePermission()),
    }
  }

  function simpleNavGuardOf(f: () => boolean) : NavigationGuardWithThis<undefined> {
    return (to, _from, next) => {
      if (f()) {
        next()
      }
      else {
        _403(to, next)
      }
    }
  }
}

function competitionRegistrationJourneyRoutes() : Record<string, RouteRecordRaw & RouteRecordExtension> {
  return {
    [R_SelectSeason.RouteNames.main]: {
      path: '/select-season/:role',
      component: () => import('src/components/Registration/selections/R_SelectSeason'),
      props: R_SelectSeason.routeLocationToProps,
      meta: {
        mainNav: false,
        label: 'Select Season',
        icon: 'calendar',
        requiresAuth: true,
        auth: [],
      },
    },
    [R_SelectPlayer.RouteNames.main]: {
      path: '/select-player/:seasonUID/:preselectedPlayerID?',
      component: () => import('src/components/Registration/selections/R_SelectPlayer.vue'),
      props: R_SelectPlayer.routeLocationToProps,
      meta: {
        mainNav: false,
        label: 'Select Player',
        icon: 'id-badge',
        requiresAuth: true,
        auth: [],
        category: 'Players'
      },
    },
    [ContactAndVolunteerDetailsUpdateFlow.routeEntry]: {
      path: `/registration/user-season-update/:childID/:seasonUID/:userListIndex(${pathIntPattern})/:step(${pathIntPattern})`,
      component: () => import('src/components/Registration/selections/R_ContactAndVolunteerDetailsUpdateFlow.vue'),
      props: R_ContactAndVolunteerDetailsUpdateFlow.routeLocationToProps,
      meta: {
        mainNav: false,
        label: 'registration user-season ack',
        icon: 'id-badge',
        requiresAuth: true,
        auth: [],
        category: 'Players'
      }
    },
    [R_SelectCompetitions.RouteNames.main]: {
      path: '/select-competitions/:playerID/:seasonUID',
      component: () => import('src/components/Registration/selections/R_SelectCompetitions.vue'),
      disableTopLevelAutoNameInjection: true,
      props: R_SelectCompetitions.routeLocationToProps,
      children: [
        {
          path: "",
          name: R_SelectCompetitions.RouteNames.selectCompetitions,
          props: true,
          component: () => import('src/components/Registration/selections/competitions'),
        },
        {
          //
          // branch target if its deemed necessary on exit from default child route
          //
          path: "volunteer/:userID/:familyID/:competitionUIDs+",
          props: true,
          name: R_SelectCompetitions.RouteNames.postSelectCompUpdateVolInfo,
          component: () => import('src/components/Registration/selections/competitions-volunteer.vue'),
        }
      ],
      meta: {
        mainNav: false,
        label: 'Select Competitions',
        icon: 'id-badge',
        requiresAuth: true,
        auth: [],
        category: 'Players'
      },
    },
    [R_RegistrationRelationshipCheck.RouteNames.main]: {
      path: '/registration-relationship-check/:seasonUID/:playerID/:competitionUIDs+',
      component: () => import('src/components/Registration/playerUserRelations/R_RegistrationRelationshipCheck.vue'),
      props: R_RegistrationRelationshipCheck.routeLocationToProps,
      meta: {
        mainNav: false,
        label: 'Registeration Review',
        icon: 'id-badge',
        requiresAuth: true,
        auth: [],
        category: 'Players'
      },
    },
    [R_PlayerRegistration.RouteNames.main]: {
      path: '/registration-form/:seasonUID/:playerID/:competitionUIDs+',
      component: () => import('src/components/Registration/registrationForm/R_PlayerRegistration.vue'),
      props: R_PlayerRegistration.routeLocationToProps,
      meta: {
        mainNav: false,
        label: 'Registeration Review',
        icon: 'id-badge',
        requiresAuth: true,
        auth: [],
        category: 'Players'
      },
    },
    [R_SelectPaymentOption.RouteNames.main]: {
      path: '/registration-payment-options/:playerID/:seasonUID/:registrationID/:competitionUIDs+',
      props: R_SelectPaymentOption.routeLocationToProps,
      component: () => import('src/components/Registration/R_SelectPaymentOption'),
      meta: {
        mainNav: false,
        label: '',
        icon: 'id-badge',
        requiresAuth: true,
        auth: [],
        category: 'Players'
      },
    },
    [R_ConfirmRegistration.RouteNames.main]: {
      path: '/confirm-registration/:registrationID/:seasonUID/:playerID/:competitionUIDs+',
      component: () => import('src/components/Registration/confirmation/R_ConfirmRegistration.vue'),
      props: R_ConfirmRegistration.routeLocationToProps,
      meta: {
        mainNav: false,
        label: 'Confirm Registration',
        icon: 'id-badge',
        requiresAuth: true,
        auth: [],
        category: 'Players'
      },
    },
    [R_PlayerRegistrationWaivers.RouteNames.main]: {
      path: '/registration-waiver/:playerID/:seasonUID/:registrationID/:competitionUIDs+',
      props: R_PlayerRegistrationWaivers.routeLocationToProps,
      component: () =>
        import('src/components/Registration/waivers/R_PlayerRegistrationWaivers.vue'),
      meta: {
        mainNav: false,
        label: 'Waivers',
        icon: 'id-badge',
        requiresAuth: true,
        auth: [],
        category: 'Players'
      },
    },
    //
    // n.b. checkout goes here but it's not specifically a "registration route", so it is defined elsewhere
    //
    [R_RegistrationComplete.RouteNames.main]: {
      path: '/registration-complete/:playerID/:seasonUID',
      props: R_RegistrationComplete.routeLocationToProps,
      component: () => import('src/components/Registration/complete/R_RegistrationComplete.vue'),
      meta: {
        mainNav: false,
        label: 'Next Step',
        icon: 'id-badge',
        requiresAuth: true,
        auth: [],
        category: 'Players'
      },
    },
  }
}

function teamRoutes() : Record<string, RouteRecordRaw & RouteRecordExtension> {
  return {
    [R_TeamSeason.RouteNames.Rosters]: {
      path: `/team/seasonal/rosters`,
      props: R_TeamSeason.routeLocationToProps,
      component: () => import('src/components/Team/R_TeamSeason'),
      meta: {
        mainNavLazy: () => R_TeamSeason.hasSomeRosterPermission(),
        label: 'Rosters and Reports',
        icon: 'clipboard-user',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Teams',
        containerLayoutStrategy: "unconstrained",
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
      },
      beforeEnter: (to, _from, next) => {
        if (R_TeamSeason.hasSomeRosterPermission()) {
          next();
        }
        else {
          _403(to, next)
        }
      }
    },
    [R_TeamSeason.RouteNames.Config]: {
      path: `/team/seasonal/config`,
      props: R_TeamSeason.routeLocationToProps,
      component: () => import('src/components/Team/R_TeamSeason'),
      meta: {
        mainNavLazy: () => R_TeamSeason.hasSomeRosterPermission(),
        label: 'Manage My Teams',
        icon: 'tools',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Teams',
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
      },
      beforeEnter: (to, _from, next) => {
        if (R_TeamSeason.hasSomeRosterPermission()) {
          next();
        }
        else {
          _403(to, next)
        }
      }
    },
    [R_TeamEditor.RouteNames.main]: {
      path: `/admin/teams`,
      component: () => import('src/components/Admin/Teams/R_TeamEditor'),
      meta: {
        mainNavLazy: () => R_TeamEditor.hasSomeRoutePermission(),
        label: 'Add / Edit Teams',
        icon: 'people-group',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Teams',
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
        containerLayoutStrategy: "unconstrained",
      },
      beforeEnter: (to, _from, next) => {
        if (R_TeamEditor.hasSomeRoutePermission()) {
          next();
        }
        else {
          _403(to, next)
        }
      }
    },
    [R_TeamAssignments.RouteNames.main]: {
      path: `/player-team-assignments`,
      component: () => import('src/components/Team/TeamAssignments/R_TeamAssignments'),
      meta: {
        mainNavLazy: () => R_TeamAssignments.hasSomeRoutePermission(),
        label: 'Team Assignments',
        icon: 'people-group',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Teams',
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
        containerLayoutStrategy: "unconstrained",
      },
      beforeEnter: (to, _from, next) => {
        if (R_TeamAssignments.hasSomeRoutePermission()) {
          next();
        }
        else {
          _403(to, next)
        }
      }
    },
    [R_TeamPools.RouteNames.main]: {
      path: `/team-pools`,
      component: () => import('src/components/Team/TeamPools/R_TeamPools'),
      meta: {
        mainNavLazy: () => R_TeamPools.hasSomeRoutePermission(),
        label: 'Team Pools',
        icon: 'people-group',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Teams',
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
      },
      beforeEnter: (to, _from, next) => {
        if (R_TeamPools.hasSomeRoutePermission()) {
          next();
        }
        else {
          _403(to, next)
        }
      }
    },
  };
}

function gameScoreRoutes() : Record<string, RouteRecordRaw & RouteRecordExtension> {
  return {
    scores: {
      path: '/scores',
      component: () => import('src/components/Score/R_AllScores.vue'),
      meta: {
        mainNavLazy: () => R_GameScores.hasSomeRoutePermission(),
        label: 'Score Input',
        icon: 'clipboard-check',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Games',
      },
      beforeEnter: (to, from, next) => {
        if (R_GameScores.hasSomeRoutePermission()) {
          next()
        }
        else {
          _403(to, next);
        }
      }
    },
    score: {
      path: '/score/:id',
      component: () => import('src/components/Score/R_GameScores.vue'),
      meta: {
        mainNav: false,
        label: 'Game Score',
        icon: 'clipboard-check',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Games',
      },
      beforeEnter: (to, from, next) => {
        if (R_GameScores.hasSomeRoutePermission()) {
          next()
        }
        else {
          _403(to, next);
        }
      }
    },
  }
}

function gameSchedulerRoutes() : Record<string, RouteRecordRaw & RouteRecordExtension> {
  return {
    [R_GameScheduler.RouteNames.main]: {
      path: `/games/scheduler`,
      component: () => import('src/components/GameScheduler/xlsx/R_GameScheduler'),
      meta: {
        mainNavLazy: () => authService(User.value.roles, "gameScheduler"),
        label: 'Create Games - Upload',
        icon: 'tools',
        requiresAuth: true,
        auth: ["gameScheduler"],
        category: 'Games',
        containerLayoutStrategy: "unconstrained",
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
      },
    },
    [R_GameSchedulerCalendar.RouteNames.main]: {
      path: '/games/scheduler/calendar',
      component: () => import('src/components/GameScheduler/calendar/R_GameSchedulerCalendar'),
      props: R_GameSchedulerCalendar.routeLocationToProps,
      meta: {
        mainNavLazy: () => R_GameSchedulerCalendar.hasSomeRoutePermission(),
        label: 'Game Scheduler',
        icon: 'tools',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Games',
        containerLayoutStrategy: "unconstrained",
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
        useRouterViewRootAsWindowlikeScrollContainer: true,
        allowBigModeSidebarCollapse: true,
      },
      beforeEnter: (from, to, next) => {
        if (R_GameSchedulerCalendar.hasSomeRoutePermission()) {
          next()
        }
        else {
          _403(to, next);
        }
      }
    },
    [R_GameTeamAssignments.RouteNames.main]: {
      path: `/games/scheduler/team-assignments`,
      component: () => import('src/components/GameScheduler/xlsx/R_GameTeamAssignments'),
      meta: {
        mainNavLazy: () => authService(User.value.roles, "gameScheduler"),
        label: 'Upload Match-Ups',
        icon: 'tools',
        requiresAuth: true,
        auth: ["gameScheduler"],
        category: 'Games',
        containerLayoutStrategy: "unconstrained",
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
      },
    },
    [R_Matchmaker.RouteNames.main]: {
      path: `/games/matchmaker`,
      name: R_Matchmaker.RouteNames.main,
      disableTopLevelAutoNameInjection: true,
      component: () => import('src/components/GameScheduler/matchmaker/R_Matchmaker'),
      meta: {
        mainNavLazy: () => R_Matchmaker.hasSomeRoutePermission(),
        label: 'Matchmaker',
        icon: 'tools',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Games',
        containerLayoutStrategy: "unconstrained",
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
      },
      children: [
        {
          path: "1",
          name: R_Matchmaker.RouteNames.phase1,
          component: () => import('src/components/NilComponent.vue'),
        },
        {
          path: "2",
          name: R_Matchmaker.RouteNames.phase2,
          component: () => import('src/components/NilComponent.vue'),
        },
        {
          path: "3",
          name: R_Matchmaker.RouteNames.phase3,
          component: () => import('src/components/NilComponent.vue'),
        },
      ],
      beforeEnter: (to, from, next) => {
        if (!R_Matchmaker.hasSomeRoutePermission()) {
          _403(to, next)
        }
        else {
          if (to.name === R_Matchmaker.RouteNames.main) {
            next({name: R_Matchmaker.RouteNames.phase1, query: to.query})
          }
          else {
            next()
          }
        }
      }
    },
    [R_BracketBuilder.RouteName]: {
      path: `/games/brackets`,
      component: () => import('src/components/GameScheduler/brackets/R_BracketBuilder'),
      meta: {
        mainNav: false,
        label: 'Brackets',
        icon: 'tools',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Games',
        containerLayoutStrategy: "unconstrained",
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
        useRouterViewRootAsWindowlikeScrollContainer: true,
        allowBigModeSidebarCollapse: true,
      },
      beforeEnter: (to, _from, next) => {
        if (R_BracketBuilder.hasSomeRoutePermission()) {
          next()
        }
        else {
          _403(to, next)
        }
      }
    },
    [R_BracketListing.RouteName]: {
      path: `/games/brackets/listing`,
      component: () => import('src/components/GameScheduler/brackets/R_BracketListing'),
      meta: {
        mainNavLazy: () => R_BracketListing.hasSomeRoutePermission(),
        label: 'Brackets',
        icon: 'tools',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Games',
        containerLayoutStrategy: "unconstrained",
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
        useRouterViewRootAsWindowlikeScrollContainer: true,
        allowBigModeSidebarCollapse: true,
      },
      beforeEnter: (to, _from, next) => {
        if (R_BracketListing.hasSomeRoutePermission()) {
          next()
        }
        else {
          _403(to, next)
        }
      }
    },
    [R_BracketView.routeName]: {
      path: `/games/bracket/:bracketID(${pathIntPattern})`,
      component: () => import('src/components/GameScheduler/brackets/R_BracketView'),
      props: true,
      meta: {
        mainNav: false,
        label: '',
        icon: 'tools',
        requiresAuth: false,
        auth: [],
        category: 'Games',
        containerLayoutStrategy: "unconstrained",
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
      },
    }
  }
}

function seasonManagerRoutes() : Record<string, RouteRecordRaw & RouteRecordExtension> {
  return {
    [R_SeasonManager.RouteName.home]: {
      path: `/seasonManager`,
      name: R_SeasonManager.RouteName.home,
      component: () => import('src/components/Admin/R_SeasonManager'),
      disableTopLevelAutoNameInjection: true,
      props: R_SeasonManager.routeLocationToRouteProps,
      meta: {
        mainNavLazy: () => R_SeasonManager.hasRoutePermission(),
        label: 'Season Manager',
        icon: 'tools',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Admin',
        containerLayoutStrategy: "unconstrained",
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
      },
      beforeEnter: (to, _from, next) => {
        if (R_SeasonManager.hasRoutePermission()) {
          next();
        }
        else {
          _403(to, next)
        }
      },
      children: [
        {
          path: `new`,
          name: R_SeasonManager.RouteName.new,
          component: () => import('src/components/NilComponent.vue'),
        },
        {
          path: `edit/:seasonUID(${pathGuidPattern})`,
          name: R_SeasonManager.RouteName.edit,
          component: () => import('src/components/NilComponent.vue'),
        }
      ]
    }
  }
}

function reportBuilderRoutes() : Record<string, RouteRecordRaw & RouteRecordExtension> {
  return {
    [R_ReportBuilder.RouteName.main]: {
      path: `/reportBuilder`,
      component: () => import('src/components/ReportBuilder/R_ReportBuilder'),
      meta: {
        mainNavLazy: () => User.isInleague,
        label: 'Report builder',
        icon: 'tools',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Admin',
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
        containerLayoutStrategy: "unconstrained",
      },
      beforeEnter: (_to, _from, next) => {
        if (User.isInleague) {
          next();
        }
        else {
          next({name: "home"})
        }
      }
    },
  }
}

function divisionEditorRoutes() : Record<string, RouteRecordRaw & RouteRecordExtension> {
  return {
    [R_DivisionEditor.RouteNames.main]: {
      path: `/admin/divisions`,
      component: () => import('src/components/Admin/Divisions/R_DivisionEditor'),
      meta: {
        mainNavLazy: () => R_DivisionEditor.hasSomeRoutePermission(),
        label: 'Master Division List',
        icon: 'tools',
        requiresAuth: true,
        auth: "DEFER-TO-BEFORE-ENTER",
        category: 'Admin',
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
        containerLayoutStrategy: "unconstrained",
      },
      beforeEnter: (to, _from, next) => {
        if (R_DivisionEditor.hasSomeRoutePermission()) {
          next();
        }
        else {
          _403(to, next)
        }
      }
    },
  }
}

function playerRatingsRoutes() : Record<string, RouteRecordRaw & RouteRecordExtension> {
  return {
    [R_PlayerRatings.RouteNames.root]: {
      path: '/playerRatings',
      component: () => import('src/components/PlayerRatings/R_PlayerRatings'),
      meta: {
        mainNavLazy: () => R_PlayerRatings.hasSomeRoutePermission(),
        category: "Players",
        label: "Player Ratings",
        requiresAuth: true,
        icon: 'tools',
        auth: "DEFER-TO-BEFORE-ENTER",
        kludgyMainContainerStyles: commonKludgyMainContainerStyles,
        allowBigModeSidebarCollapse: true,
        containerLayoutStrategy: "unconstrained",
      },
      children: [
        {
          path: `clientConfig`,
          name: R_PlayerRatings.RouteNames.clientConfig,
          component: () => import('src/components/NilComponent.vue'),
        },
        {
          path: `rate`,
          name: R_PlayerRatings.RouteNames.rate,
          component: () => import('src/components/NilComponent.vue'),
        },
        {
          path: `report`,
          name: R_PlayerRatings.RouteNames.report,
          component: () => import('src/components/NilComponent.vue'),
        }
      ],
      beforeEnter: (to, from, next) => {
        if (!R_PlayerRatings.hasSomeRoutePermission()) {
          _403(to, next);
          return;
        }

        if (to.name === R_PlayerRatings.RouteNames.root) {
          next({
            ...to,
            path: undefined, // dumb type thing; named route, not path route
            name: R_PlayerRatings.RouteNames.rate,
          })
        }
        else {
          next()
        }
      }
    },
  }
}

function newUserRouteAuthCheck() : boolean {
  if (!User.isLoggedIn) {
    return true;
  }
  else {
    return false;
  }
}

/**
 * `useRoute()` returns this, assuming it is the "default inleague router"
 */
export type RouteWithInleagueMetadata = RouteRecordExtension & ReturnType<typeof useRoute>

export async function routeAuthPredicateRunner(routeMeta: RouteRecordExtension["meta"]) : Promise<boolean> {
  if (routeMeta.requiresAuth && !User.isLoggedIn) {
    // requires auth, but user is not logged in
    return false;
  }
  if (routeMeta.requiresNoAuth) {
    if (User.isLoggedIn) {
      // requires no auth, but user *is* logged in
      return false;
    }
    else {
      return true;
    }
  }

  const roleRequirements = routeMeta.auth;

  /**
   * `beforeEnter` can do route guard stuff by running arbitrary logic against (store, matched-route)
   * we don't have "matchedRoute" (probably vue-router's `RouteLocationNormalizedLoaded`) here, we only have "some route meta",
   * which can come from either a RouteRecord or a RouteLocationNormalizedLoaded
   */
  if (roleRequirements === "DEFER-TO-BEFORE-ENTER") {
    return true;
  }

  const alternateAuthRolesCheck = routeMeta.alternateAuthRolesCheck;

  //
  // explicit role requirements take highest precedence,
  // hence they are run first. They are not required to be present.
  // On failure of explicit role requirements checks, or in their absence,
  // we fallback to the alternateAuthRolesCheck
  //
  if (roleRequirements) {
    if (roleRequirements.length === 0) {
      return true;
    }
    if (authService(User.value.roles, ...roleRequirements)) {
      return true;
    }
  }

  if (alternateAuthRolesCheck) {
    return await alternateAuthRolesCheck();
  }

  return false;
}

function _403(to: RouteLocationNormalized, next: NavigationGuardNext, opts?: {skipOfferLoginModalIfLoggedOut?: boolean}) : void {
  if (!User.isLoggedIn) {
    if (opts?.skipOfferLoginModalIfLoggedOut) {
      forbidden()
    }
    else {
      System.setRedirectOnLogin({type: "vue-router-route", value: {...to}});
      next({name: "login"})
    }
  }
  else {
    forbidden();
  }

  function forbidden() {
    next({name: "forbidden"})
  }
}

async function hasEventRoster_navGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  // user not logged in? can't proceed (does the "requiresAuth" predicate run before this? would be nice)
  if (!User.isLoggedIn) {
    // fixme: what's the default behavior for "go to home, show login modal"
    next({ name: "home", params: {} })
    return;
  }

  // user is a registrar? this route is always OK
  if (authService(User.value.roles, "registrar", "eventAdmin")) {
    next();
    return;
  }

  // subsequent checks need the event;
  // try to load it (and do so in such a way that the actual route will be able to pull it from the store)
  const eventID = to.params.eventID;
  if (!eventID || typeof eventID !== "string") {
    // pretty much a bug on our part, we should only match this route against a path with a GUID eventID param
    next({ name: "fourOhFour", params: {} })
    return;
  }

  const event = await events.get(axiosInstance, eventID, {expand: ["signups", "rosterLayoutDetail"]});

  // business rule: if the user email is exactly the event contact email, then the user has registrar-like permission for this event
  if (event.contactEmail.trim() && event.contactEmail.toLowerCase().trim() === User.value.userEmail.toLowerCase().trim()) {
    next();
    return;
  }

  // business rule: the event is a team event, and the current user is a coach for that team on the event season
  if (event.teamID && AuthService.isHeadCoachForTeam((User.value.userData as UserData).coachAssignmentsMemento, {teamID: event.teamID, seasonUID: event.seasonUID})) {
    next();
    return;
  }

  // catch-all
  _403(to, next)
}

async function hasEventSigninSheet_navGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  // same as event roster
  return await hasEventRoster_navGuard(to, from, next)
}
