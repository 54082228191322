import { PropType, computed, defineComponent, onMounted, reactive, ref, watch } from "vue";

import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import { axiosInstance } from "src/boot/AxiosInstances";
import { exhaustiveCaseGuard, parseIntOrFail, sortBy } from "src/helpers/utils";
import { MenuTree, freshSelectionsFirstOfEach, menuIsEmpty, payloadsForCompleteSelection } from "src/components/UserInterface/MenuTree"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { AxiosInstance } from "axios";
import { AugmentedRefRow, DivCounts, TournamentOverviewReport } from "./TournamentTeamReport.impl";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { RefAssignmentsByUserID, refAssignmentsByUserID, teamDesignation } from "./TournamentTeamUtils";
import { TournamentTeamOverviewReport } from "src/composables/InleagueApiV1.Tournament";
import { AutoModal, DefaultModalController, DefaultTinySoccerballBusyOverlay } from "../UserInterface/Modal";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { STRLEN_TOURNTEAM_OFFICIAL_COMMENTS_MAX, RefMutablesForm, RefMutablesFormData, TextWithMaxLen, highestArOptions, highestCrOptions, STRLEN_TOURNTEAM_OFFICIAL_BADGELEVEL_MAX } from "./TournamentTeamConfigurator.shared";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { Client } from "src/store/Client";
import { Guid } from "src/interfaces/InleagueApiV1";

export default defineComponent({
  setup() {
    type Awaitables =
      | {readonly ready: false}
      | {
        readonly ready: true,
        readonly menuDef: iltypes.MenuTreeDef<"seasonUID" | "tournamentID", {tournamentID: {competitionName: string, competitionUID: Guid}}>
      }

    const state = ref<Awaitables>({ready: false})

    onMounted(async () => {
      const menuDef = await localStore.getTournamentOverviewReportMenu(axiosInstance)
      state.value = {
        ready: true,
        menuDef,
      }
    })

    return () => {
      if (!state.value.ready) {
        return null;
      }
      return <Impl menuDef={state.value.menuDef}/>
    }
  }
})

const Impl = defineComponent({
  props: {
    menuDef: {
      required: true,
      type: null as any as PropType<iltypes.MenuTreeDef<"seasonUID" | "tournamentID", {tournamentID: {competitionName: string, competitionUID: Guid}}>>
    }
  },
  setup(props) {
    const menuSelections = ref({seasonUID: "", tournamentID: ""})

    const reportForSelectedTournament = ref<{report: TournamentTeamOverviewReport, refAssignmentsByUserID: RefAssignmentsByUserID} | null>(null);

    watch(() => props.menuDef, () => {
      menuSelections.value = freshSelectionsFirstOfEach(props.menuDef);
    }, {immediate: true})

    watch(() => menuSelections, () => {
      // changing a season/tournament needs to invalidate the selected team because it doesn't make sense
      // after a fresh selection but before clicking "get". Probably it should just do-the-load-when-this-changes.
      reportForSelectedTournament.value = null;
    }, {deep: true})

    const doGetReportsForCurrentSelection = async () : Promise<void> => {
      // sanity check, should be integerlike
      const tournamentID = parseIntOrFail(menuSelections.value.tournamentID);
      const report = await localStore.getTournamentOverviewReport(axiosInstance, tournamentID);
      reportForSelectedTournament.value = {report, refAssignmentsByUserID: refAssignmentsByUserID(report)};
    }

    const menuPayloads = computed(() => payloadsForCompleteSelection(props.menuDef, menuSelections.value))

    const mungedReport = computed<TournamentTeamOverviewReport>(() => {
      const unaffiliatedRefs = reportForSelectedTournament.value?.report.unaffiliatedRefs ?? [];
      const refAssignmentsByUserID = reportForSelectedTournament.value?.report.refAssignmentsByUserID ?? {}
      const tournamentTeams = reportForSelectedTournament.value?.report.tournamentTeams ?? []
      const players = reportForSelectedTournament.value?.report.players ?? []

      return {
        unaffiliatedRefs,
        refAssignmentsByUserID,
        tournamentTeams,
        players,
      } satisfies TournamentTeamOverviewReport
    })

    const refEditModalController = reactive((() => {
      const doUpdate = async (row: AugmentedRefRow, form: RefMutablesFormData) : Promise<void> => {
        if (!reportForSelectedTournament.value) {
          return;
        }

        let ref : iltournament.TournamentTeamOverviewReport_Ref | undefined = undefined;

        if (row.infoIfAffiliated) {
          ref = row.infoIfAffiliated.owningTeamReport.tournamentTeamOfficialReferees.find(ref => row.tournamentTeamOfficialID == ref.tournamentTeamOfficialID)
        }
        else {
          ref = reportForSelectedTournament.value.report.unaffiliatedRefs.find(ref => row.tournamentTeamOfficialID == ref.tournamentTeamOfficialID)
        }

        if (!ref) {
          // shouldn't happen
          return;
        }

        try {
          try {
            busy.value = true;

            await iltournament.updateTournamentTeamOfficial(axiosInstance, {
              officialType: iltournament.TournamentTeamOfficialType.REFEREE,
              tournamentTeamOfficialID: row.tournamentTeamOfficialID,
              maxAR: form.maxAR === "" ? null : form.maxAR,
              maxCR: form.maxCR === "" ? null : form.maxCR,
              comments: form.comments.text,
              ref_badgeLevel: form.ref_badgeLevel.text,
            })
          }
          finally {
            busy.value = false;
          }

          ref.comments = form.comments.text;
          ref.ref_maxCR = form.maxCR
          ref.ref_maxAR = form.maxAR

          refEditModalController.close();
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      }

      const doDelete = async (row: AugmentedRefRow) : Promise<void> => {
        if (!reportForSelectedTournament.value) {
          return;
        }

        if (row.infoIfAffiliated) {
          const idx = row.infoIfAffiliated.owningTeamReport.tournamentTeamOfficialReferees.findIndex(ref => row.tournamentTeamOfficialID == ref.tournamentTeamOfficialID)
          if (idx === -1) {
            return;
          }

          await doit();
          row.infoIfAffiliated.owningTeamReport.tournamentTeamOfficialReferees.splice(idx, 1);
          refEditModalController.close();
        }
        else {
          const idx = reportForSelectedTournament.value.report.unaffiliatedRefs.findIndex(ref => row.tournamentTeamOfficialID == ref.tournamentTeamOfficialID)
          if (idx === -1) {
            return;
          }

          await doit();
          reportForSelectedTournament.value.report.unaffiliatedRefs.splice(idx, 1);
          refEditModalController.close();
        }

        async function doit() {
          try {
            try {
              busy.value = true;
              await iltournament.deleteTournamentTeamOfficialByTournamentTeamOfficialID(axiosInstance, {tournamentTeamOfficialID: row.tournamentTeamOfficialID})
            }
            finally {
              busy.value = false;
            }
          }
          catch (err) {
            AxiosErrorWrapper.rethrowIfNotAxiosError(err);
          }
        }
      }

      const busy = ref(false);
      const __form = ref<null | RefMutablesFormData>(null)
      enum Mode { normal, deletionConfirmation }
      const mode = ref(Mode.normal);

      const onOpenCB = (obj: AugmentedRefRow) => {
        mode.value = Mode.normal;
        __form.value = {
          comments: TextWithMaxLen(obj.comments, STRLEN_TOURNTEAM_OFFICIAL_COMMENTS_MAX),
          maxAR: obj.ref_maxAR,
          maxCR: obj.ref_maxCR,
          ref_badgeLevel: TextWithMaxLen(obj.ref_badgeLevel, STRLEN_TOURNTEAM_OFFICIAL_BADGELEVEL_MAX)
        }
      }

      const onCloseCB = (close: () => void) => {
        if (busy.value) {
          return;
        }
        else {
          close();
        }
      }

      return DefaultModalController<AugmentedRefRow>({
        title: () => (
          <>
            <div>Edit Referee</div>
            <div class="border-b border-slate-200"/>
          </>
        ),
        content: row => {
          if (!row || !__form.value) {
            return null;
          }

          return (
            <div>
              <div>{row.firstName} {row.lastName}</div>
              <div class="text-sm">{row.infoIfAffiliated ? teamDesignation(row.infoIfAffiliated.owningTeamReport) : "Unaffiliated"}</div>
              {
                mode.value === Mode.normal
                  ? (
                    <>
                      <RefMutablesForm refForm={__form.value} highestCrOptions={highestCrOptions} highestArOptions={highestArOptions}/>
                      <div class="flex">
                        <div class="flex gap-2">
                          <t-btn margin={false} onClick={() => doUpdate(row, __form.value!)}>
                            Save
                          </t-btn>
                          <t-btn margin={false} color="red" onClick={() => refEditModalController.close()}>
                            <span>Cancel</span>
                          </t-btn>
                        </div>
                        <div class="ml-auto">
                          <t-btn margin={false} color="red" onClick={() => mode.value = Mode.deletionConfirmation}>
                            <FontAwesomeIcon icon={faTrash}/>
                            <span class="ml-2">Delete</span>
                          </t-btn>
                        </div>
                      </div>
                    </>
                  )
                  : mode.value === Mode.deletionConfirmation
                  ? (
                    <>
                      <div class="flex items-center justify-center mb-4">
                        Confirm deletion
                      </div>
                      <div class="flex items-center justify-center gap-2">
                        <t-btn margin={false} onClick={() => doDelete(row)}>
                          <span class="ml-2">Yes, delete this ref</span>
                        </t-btn>
                        <t-btn margin={false} color="red" onClick={() => mode.value = Mode.normal}>
                          <span class="ml-2">No, do not delete</span>
                        </t-btn>
                      </div>
                    </>
                  )
                  : exhaustiveCaseGuard(mode.value)
              }
              {
                busy.value
                  ? <DefaultTinySoccerballBusyOverlay color={Client.value.clientTheme.color}/>
                  : null
              }
            </div>
          )
        }
      }, {onCloseCB, onOpenCB})
    })());

    return () => {
      if (menuIsEmpty(props.menuDef.menu)) {
        return <div>No tournaments with reportable teams available.</div>
      }
      else {
        return (
          <div style="--fk-bg-input: white;">
            <AutoModal controller={refEditModalController}/>
            <MenuTree menuDef={props.menuDef} mut_selections={menuSelections.value}/>

            <t-btn
              onClick={doGetReportsForCurrentSelection}
              disabled={menuSelections.value.tournamentID === ""} class={`${menuSelections.value.tournamentID === "" ? "bg-gray-200" : ""}`} margin={false}
            >
              Get
            </t-btn>

            {
              (() => {
                if (!reportForSelectedTournament.value) {
                  return null;
                }

                if (!menuPayloads.value) {
                  // shouldn't happen, should have menu payloads for the selection at all times we have reports FOR that selection
                  throw Error("unexpected state")
                }

                return (
                  <div class="mt-4">
                    <div class="my-4">
                      <DivCounts report={reportForSelectedTournament.value.report}/>
                    </div>
                    <TournamentOverviewReport
                      seasonName={menuPayloads.value.for_seasonUID.label}
                      competitionName={menuPayloads.value.for_tournamentID.competitionName}
                      report={mungedReport.value}
                      openRefEditModal={(row: AugmentedRefRow) => refEditModalController.open(row)}
                      competitionUID={menuPayloads.value.for_tournamentID.competitionUID}
                    />
                  </div>
                )
              })()
            }
          </div>
        );
      }
    }
  }
})

const localStore = (() => {
  // these neeed to participate in all aspects of crud ops for tourn teams and tourns
  // const menu = ref<null | Promise<iltypes.MenuTreeDef<"seasonUID" | "tournamentID", {tournamentID: {competitionName: string}}>>>(null);
  // const cacheByTournamentID = ref<{[tournamentID: iltypes.Integerlike]: undefined | Promise<iltournament.TournamentTeamOverviewReport[]>}>({})

  const getTournamentOverviewReportMenu = (axios: AxiosInstance) : Promise<iltypes.MenuTreeDef<"seasonUID" | "tournamentID", {tournamentID: {competitionName: string, competitionUID: Guid}}>> => {
    return iltournament.getTournamentOverviewReportMenu(axios)
  }

  const getTournamentOverviewReport = (axios: AxiosInstance, tournamentID: iltypes.Integerlike) : Promise<iltournament.TournamentTeamOverviewReport> => {
    return iltournament
      .getTournamentOverviewReport(axios, {tournamentID})
      .then(v => {
        v.tournamentTeams.sort(sortBy(_ => _.competitionID, "asc"));
        return v;
      })
  }

  return {
    getTournamentOverviewReportMenu,
    getTournamentOverviewReport,
  }
})()
