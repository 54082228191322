import { FormKit, FormKitMessages } from "@formkit/vue"
import { faChevronRight, faExclamation } from "@fortawesome/free-solid-svg-icons"
import { faChevronLeft, faFilterList } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import dayjs, { ManipulateType } from "dayjs"
import { Btn2 } from "src/components/UserInterface/Btn2"
import { TabDef, Tabs } from "src/components/UserInterface/Tabs"
import { GetCompDependentViewOptionsResponse } from "src/composables/InleagueApiV1.GameScheduler"
import { DAYJS_FORMAT_HTML_DATE } from "src/helpers/formatDate"
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise"
import { SetEx, UiOption, clamp, fkKey_reactToChangeValueLikeNormal, vReqT } from "src/helpers/utils"
import { Datelike, Guid, Integerlike } from "src/interfaces/InleagueApiV1"
import { Ref, computed, defineComponent } from "vue"

export const DisplayOptions = defineComponent({
  props: {
    competitionOptions: vReqT<{competitionUID: Guid, competition: string}[]>(),
    selectedCompetitionUIDs: vReqT<SetEx<Guid>>(),
    selectedDivIDs: vReqT<SetEx<Guid>>(),
    compDependentViewOptions: vReqT<ReactiveReifiedPromise<GetCompDependentViewOptionsResponse>>(),
    fields: vReqT<{fieldUID: Guid, fieldName: string, checked: boolean, fieldAbbrev: string}[]>(),
    dateFrom: vReqT<Ref<Datelike>>(),
    dateTo: vReqT<Ref<Datelike>>(),
    focusOnBracketGames: vReqT<Ref<boolean>>(),
    onlyShowSelectedDatesAndFieldsHavingGames: vReqT<boolean>(),
    gridSlicesPerHourOptions: vReqT<UiOption<Integerlike>[]>(),
    gridSlicesPerHour: vReqT<Ref<Integerlike>>(),
    colorSchemeOptions: vReqT<UiOption[]>(),
    colorScheme: vReqT<Ref<string>>(),
    px_perFieldColWidth: vReqT<Ref<Integerlike>>(),
    px_perFieldColWidthMinMax: vReqT<{min: number, max: number}>(),
    px_perHourCellHeight: vReqT<Ref<Integerlike>>(),
    px_perHourCellHeightMinMax: vReqT<{min: number, max: number}>(),
    fullDayDisplay: vReqT<Ref<boolean>>(),
  },
  emits: {
    getGamesAndFieldBlocks: () => true,
    resetFieldCompDivFilters: () => true,
    "updateCompDependentViewOptions": () => true,
    "update:onlyShowSelectedDatesAndFieldsHavingGames": (_: boolean) => true
  },
  setup(props, {emit}) {
    const divOptions = computed(() => {
      if (props.compDependentViewOptions.underlying.status === "resolved") {
        return props.compDependentViewOptions.underlying.data.divisions.map(v => ({label: v.displayName || v.division, value: v.divID}))
      }
      else {
        return null;
      }
    })

    const allCompetitionsChecked = computed({
      get: () => {
        return new SetEx(props.competitionOptions.map(v => v.competitionUID)).intersect(props.selectedCompetitionUIDs).size === props.competitionOptions.length
      },
      set: (checked) => {
        props.selectedCompetitionUIDs.clear()
        if (checked) {
          props.selectedCompetitionUIDs.addMany(props.competitionOptions.map(v => v.competitionUID))
        }
        emit("updateCompDependentViewOptions")
      }
    })

    const allDivsChecked = computed({
      get: () => {
        return !!divOptions.value
          && new SetEx(divOptions.value.map(v => v.value))
            .intersect(props.selectedDivIDs).size === divOptions.value.length
      },
      set: (checked) => {
        if (divOptions.value) {
          props.selectedDivIDs.clear()
          if (checked) {
            props.selectedDivIDs.addMany(divOptions.value.map(v => v.value))
          }
        }
      }
    })

    const allFieldsChecked = computed({
      get: () => {
        return props.fields.every(field => field.checked)
      },
      set: (v) => {
        props.fields.forEach(field => { field.checked = v })
      }
    })

    const tablElemStyle = "display:grid; grid-column:auto; grid-gap:.5em; max-height: 15em; overflow-y:auto;"
    const tabElemClass = "border rounded-md text-sm p-1"
    const tabElemNothingAvailableClass = "text-sm border rounded-md p-1"

    const onlyShowSelectedDatesAndFieldsHavingGames = computed({
      get: () => props.onlyShowSelectedDatesAndFieldsHavingGames,
      set: (v) => emit("update:onlyShowSelectedDatesAndFieldsHavingGames", v),
    })

    const miscTab = () => {
      return (
        <div class={tabElemClass} style={tablElemStyle}>
          <div class="text-sm font-medium">Grid Time Interval:</div>
          <FormKit type="select" options={props.gridSlicesPerHourOptions} v-model={props.gridSlicesPerHour.value}/>
          <div class="text-sm font-medium">Color scheme:</div>
          <FormKit type="select" options={props.colorSchemeOptions} v-model={props.colorScheme.value}/>
          <div class="text-sm font-medium">Per-Field Width</div>
          <FormKit type="number" class="p-1 rounded-md" v-model={props.px_perFieldColWidth.value} style="padding:.25em;"
            onBlur={() => { props.px_perFieldColWidth.value = clamp(+props.px_perFieldColWidth.value, props.px_perFieldColWidthMinMax)}}
          />
          <div class="text-sm font-medium">Row Height</div>
          <FormKit type="number" class="p-1 rounded-md" v-model={props.px_perHourCellHeight.value} style="padding:.25em;"
            onBlur={() => { props.px_perHourCellHeight.value = clamp(+props.px_perHourCellHeight.value, props.px_perHourCellHeightMinMax)}}
          />
          <div class="flex items-center gap-1">
            <FormKit type="checkbox" v-model={props.focusOnBracketGames.value}/>
            Focus bracket games
          </div>
          <div class="mt-1 flex items-center gap-1">
            <FormKit type="checkbox" class="p-1 rounded-md" style="padding:.25em;" onInput={(value: any) => {
              props.fullDayDisplay.value = !!value
            }}/>
            Full day display (show all 24h per day). Can help find games that ended up getting scheduled outside of typical display hours.
          </div>
        </div>
      )
    }

    const competitionsTab = () => {
      if (props.competitionOptions.length === 0) {
        return <div class={tabElemNothingAvailableClass}>No available programs</div>
      }
      return (
        <div class={tabElemClass} style={tablElemStyle}>
          <div class="flex gap-2 items-center">
            <FormKit
              type="checkbox"
              key={fkKey_reactToChangeValueLikeNormal("comps", allCompetitionsChecked.value)}
              value={allCompetitionsChecked.value}
              onInput={() => allCompetitionsChecked.value = !allCompetitionsChecked.value}
              delay={0}
              data-test="all"
            />
            <span class="font-medium">All</span>
          </div>
          {props.competitionOptions.map(comp => {
            return (
              <div class="flex gap-2 items-center">
                <FormKit
                  type="checkbox"
                  data-value={comp.competitionUID}
                  onInput={() => {
                    props.selectedCompetitionUIDs.invert(comp.competitionUID)
                    emit("updateCompDependentViewOptions")
                  }}
                  value={props.selectedCompetitionUIDs.has(comp.competitionUID)}
                  key={fkKey_reactToChangeValueLikeNormal(`comp/${comp.competitionUID}`, props.selectedCompetitionUIDs.has(comp.competitionUID))}
                  delay={0}
                />
                {comp.competition}
              </div>
            )
          })}
        </div>
      )
    }

    const divisionsTab = () => {
      if (!divOptions.value) {
        return <div class={tabElemNothingAvailableClass}>Loading division options...</div>
      }

      if (divOptions.value.length === 0) {
        return <div class={tabElemNothingAvailableClass}>No available divisions.</div>
      }
      return (
        <div class={tabElemClass} style={tablElemStyle}>
          <div class="flex gap-2 items-center">
            <FormKit
              type="checkbox"
              key={fkKey_reactToChangeValueLikeNormal("div/all", allDivsChecked.value)}
              value={allDivsChecked.value}
              onInput={() => allDivsChecked.value = !allDivsChecked.value}
              delay={0}
              data-test="all"
            />
            <span class="font-medium">All</span>
          </div>
          {divOptions.value.map(opt => {
            return (
              <div class="flex gap-2 items-center">
                <FormKit
                  type="checkbox"
                  data-value={opt.value}
                  onInput={() => {props.selectedDivIDs.invert(opt.value)}}
                  value={props.selectedDivIDs.has(opt.value)}
                  key={fkKey_reactToChangeValueLikeNormal(`div/${opt.value}`, props.selectedDivIDs.has(opt.value))}
                  delay={0}
                />
                {opt.label}
              </div>
            )
          })}
        </div>
      )
    }

    const fieldsTab = () => {
      if (props.fields.length === 0) {
        return <div class={tabElemNothingAvailableClass}>No available fields.</div>
      }
      return (
        <div class={tabElemClass} style={tablElemStyle}>
          <div class="flex gap-2 items-center">
          <FormKit
              type="checkbox"
              key={fkKey_reactToChangeValueLikeNormal("fields", allFieldsChecked.value)}
              value={allFieldsChecked.value}
              onInput={() => allFieldsChecked.value = !allFieldsChecked.value}
              data-test="all"
            />
            <span class="font-medium">All</span>
          </div>
          {props.fields.map(field => {
            return (
              <div class="flex gap-2 items-center">
                <FormKit type="checkbox" v-model={field.checked} data-value={field.fieldUID}/>
                {field.fieldName} ({field.fieldAbbrev})
              </div>
            )
          })}
        </div>
      )
    }

    const datesTab = () => {
      return (
        <div class="flex flex-col">
          <div class="rounded-md p-[6px]">
            <div class="text-sm font-medium">From:</div>
            <FormKit
              type="date" name="Date from" v-model={props.dateFrom.value}
              data-test="dateFrom"
              validation={[["required"]]}
            />
            <div class="text-sm font-medium">To:</div>
            <FormKit
              type="date" name="Date to" v-model={props.dateTo.value}
              data-test="dateTo"
              validation={[["required"]]}
            />
            {!lookupTimeSpanIsValid.value ? <div class="my-1 text-sm text-red-600">Dates cannot span more than 92 days (~1 quarter).</div> : null}
            {!dateFromIsOnOrBeforeDateTo.value ? <div class="my-1 text-sm text-red-600">'From' must be on-or-before 'to'</div> : null}
            <div class="flex mt-2 gap-2">
              <Btn2 class="py-1 px-2 flex items-center gap-1 text-xs" onClick={() => bumpDates(-1, "week")}>
                <FontAwesomeIcon icon={faChevronLeft}/>
                <span style="margin-top:-1px;">1 week</span>
              </Btn2>
              <Btn2 class="py-1 px-2 flex items-center gap-1 text-xs" onClick={() => bumpDates(+1, "week")}>
                <span style="margin-top:-1px;">1 week</span>
                <FontAwesomeIcon icon={faChevronRight}/>
              </Btn2>
            </div>
          </div>
          <div class="mt-2 text-sm flex items-center gap-[10px] px-[6px] rounded-md" style="--fk-margin-decorator:none;">
            <FormKit type="checkbox" v-model={onlyShowSelectedDatesAndFieldsHavingGames.value}/>
            <span>Only show selected dates and fields that have games</span>
          </div>
        </div>
      )
    }

    const fieldsAllChecked = computed(() => props.fields.every(v => v.checked))
    const divsAllChecked = computed(() => !!divOptions.value && props.selectedDivIDs.size === divOptions.value?.length)
    const compsAllChecked = computed(() => props.selectedCompetitionUIDs.size === props.competitionOptions.length)

    const tabDefs : TabDef[] = [
      {
        label: "Dates",
        "data-test": "datesTab",
        render: datesTab,
      },
      {
        label: {
          key: "fields",
          render: () => fieldsAllChecked.value
            ? <span>Fields</span>
            : <span>
              <FontAwesomeIcon icon={faFilterList}/>
              <span> Fields</span>
            </span>,
        },
        "data-test": "fieldsTab",
        render: fieldsTab,
      },
      {
        label: {
          key: "programs",
          render: () => compsAllChecked.value
            ? <span>Programs</span>
            : <span>
              <FontAwesomeIcon icon={faFilterList}/>
              <span> Programs</span>
            </span>,
        },
        "data-test": "competitionsTab",
        render: competitionsTab,
      },
      {
        label: {
          key: "divisions",
          // "no div options" meaning "still being loaded in..."
          render: () => !divOptions.value || divsAllChecked.value
            ? <span>Divisions</span>
            : <span>
              <FontAwesomeIcon icon={divOptions.value.length === 0 ? faExclamation : faFilterList}/>
              <span> Divisions</span>
            </span>
        },
        "data-test": "divisionsTab",
        render: divisionsTab,
      },
      {
        label: "Misc",
        "data-test": "miscTab",
        render: miscTab,
      }
    ]

    const bumpDates = (n: number, unit: ManipulateType) => {
      const from = dayjs(props.dateFrom.value)
      const to = dayjs(props.dateTo.value)
      if (from.isValid()) {
        props.dateFrom.value = from.add(n, unit).format(DAYJS_FORMAT_HTML_DATE)
      }
      if (to.isValid()) {
        props.dateTo.value = to.add(n, unit).format(DAYJS_FORMAT_HTML_DATE)
      }
    }

    const lookupTimeSpanIsValid = computed(() => {
      const fromInclusive = dayjs(props.dateFrom.value)
      const toInclusive = dayjs(props.dateTo.value)
      if (!fromInclusive.isValid() || !toInclusive.isValid()) {
        return true; // "indeterminate" for invalid dates
      }
      const deltaDays = toInclusive.diff(fromInclusive.subtract(1, "days"), "days")
      if (deltaDays <= 0) {
        return true; // "indeterminate" - "is from before to" is not part of this check
      }
      else {
        return deltaDays <= 92
      }
    });

    const dateFromIsOnOrBeforeDateTo = computed(() => {
      const from = dayjs(props.dateFrom.value)
      const to = dayjs(props.dateTo.value)
      if (!from.isValid() || !to.isValid()) {
        return true; // "indeterminate" for invalid dates
      }
      return from.isSameOrBefore(to, "days");
    });

    const disableSubmit = computed(() => {
      const ok = lookupTimeSpanIsValid.value
        && dateFromIsOnOrBeforeDateTo.value
      return !ok
    })

    return () => {
      return (
        <div data-test="DisplayOptions">
          <FormKit type="form" form-class="h-full" actions={false} onSubmit={() => {
            if (disableSubmit.value) {
              return;
            }
            else {
              emit("getGamesAndFieldBlocks")
            }
          }}>
            <div class="flex flex-col h-full">
              <div class="flex-grow">
                <Tabs tabDefs={tabDefs}/>
              </div>
              <div style="display:none;">
                <FormKitMessages/>
              </div>
              <div class="mt-auto flex items-center gap-2">
                <Btn2 disabled={disableSubmit.value} type="submit" class="mt-2 px-2 py-1">Update View</Btn2>
                <Btn2
                  type="button"
                  disabled={(fieldsAllChecked.value && compsAllChecked.value && divsAllChecked.value) || props.compDependentViewOptions.underlying.status !== "resolved"}
                  class="mt-2 px-2 py-1"
                  onClick={() => emit("resetFieldCompDivFilters")}
                >Reset field/program/division filters</Btn2>
              </div>
            </div>
          </FormKit>
        </div>
      )
    }
  }
})
