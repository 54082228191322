import { FormKit, FormKitMessages } from "@formkit/vue";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { axiosAuthBackgroundInstance, axiosInstance } from "src/boot/AxiosInstances";
import { PropType, computed, defineComponent, reactive, ref } from "vue";
import { SoccerBall } from "../SVGs";
import { DefaultModalController, AutoModal } from "src/components/UserInterface/Modal";

import * as iltypes from "src/interfaces/InleagueApiV1"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import { FK_nodeRef, UiOption, exhaustiveCaseGuard, parseIntOrFail, vReqT } from "src/helpers/utils";
import { Client } from "src/store/Client";
import { aysoIdLenientFormPattern, type CoachMutablesFormData, STRLEN_TOURNTEAM_OFFICIAL_COMMENTS_MAX, TextWithMaxLen } from "./TournamentTeamConfigurator.shared";
import { Btn2 } from "../UserInterface/Btn2";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPencil, faTrash } from "@fortawesome/pro-solid-svg-icons";

function sortByLastNameFirstName(l: iltournament.TournamentTeamOfficial, r: iltournament.TournamentTeamOfficial) : -1 | 0 | 1 {
  if (l.lastName < r.lastName) {
    return -1;
  }
  else if (l.lastName === r.lastName) {
    if (l.firstName < l.firstName) {
      return -1
    }
    else if (l.firstName === r.firstName) {
      return 0;
    }
    else {
      return 1;
    }
  }
  else {
    return 1;
  }
}

function officialTypeUiString(v: iltournament.TournamentTeamOfficialType) {
  switch (v) {
    case iltournament.TournamentTeamOfficialType.ASST_COACH:
      return "Asst. coach";
    case iltournament.TournamentTeamOfficialType.HEAD_COACH:
      return "Head coach";
    case iltournament.TournamentTeamOfficialType.REFEREE:
      return "Referee";
    default: exhaustiveCaseGuard(v);
  }
}

function isCoachOrAsstCoach(v: iltournament.TournamentTeamOfficial) {
  switch (v.type) {
    case iltournament.TournamentTeamOfficialType.HEAD_COACH: return true;
    case iltournament.TournamentTeamOfficialType.ASST_COACH: return true;
    default: return false;
  }
}

export const CoachRoster = defineComponent({
  props: {
    tournamentTeam: vReqT<iltournament.TournamentTeam>(),
    mut_existingOfficials: {
      required: true,
      type: Array as PropType<iltournament.TournamentTeamOfficial[]>
    },
    canDelete: vReqT<boolean>(),
  },
  setup(props) {
    type CoachType =
      | iltournament.TournamentTeamOfficialType.ASST_COACH
      | iltournament.TournamentTeamOfficialType.HEAD_COACH

    const justCoaches = computed(() => props.mut_existingOfficials.filter(isCoachOrAsstCoach));

    const form = ref((() => {
      const coachType_ = ref<CoachType>(iltournament.TournamentTeamOfficialType.HEAD_COACH)
      const coachType = computed<CoachType>({
        get() : CoachType { return coachType_.value; },
        set(v: any) { coachType_.value = parseIntOrFail(v); }
      });
      return {
        stackSID: "",
        lastName: "",
        coachType
      }
    })())

    const coachTypeOptions : UiOption[] = [
      {label: "Head Coach", value: iltournament.TournamentTeamOfficialType.HEAD_COACH.toString()},
      {label: "Asst. Coach", value: iltournament.TournamentTeamOfficialType.ASST_COACH.toString()}
    ]

    const mostRecentCandidateSearchResult = ref<
      | "no-search-yet"
      | "nothing-found"
      | iltournament.TournamentTeamOfficialCandidate
    >("no-search-yet")

    const doCandidacyLookupByStackSid = async () : Promise<void> => {
      try {
        // TODO: axiosInstance that does not error toast on 404
        const candidate = await iltournament.getTournamentTeamOfficialCandidateByStackSID(
          axiosInstance, {
            binding: {
              type: "tournamentTeam",
              tournamentTeamID: props.tournamentTeam.tournamentTeamID,
            },
            stackSID: form.value.stackSID,
            lastName: form.value.lastName,
            officialType: form.value.coachType,
        });

        if (candidate) {
          mostRecentCandidateSearchResult.value = candidate;
        }
        else {
          mostRecentCandidateSearchResult.value = "nothing-found";
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const doAddCoachByCurrentCandidate = async (addRefForm: CoachMutablesFormData, selectedUserIdIfMatchingInleagueUser: iltypes.Guid | undefined) : Promise<void> => {
      if (typeof mostRecentCandidateSearchResult.value !== "object") {
        throw Error("illegal state")
      }

      try {
        const fresh = await iltournament.createTournamentTeamOfficialByStackSID(
          axiosInstance, {
            binding: {
              type: "tournamentTeam",
              tournamentTeamID: props.tournamentTeam.tournamentTeamID,
            },
            stackSID: mostRecentCandidateSearchResult.value.stackSID,
            lastName: mostRecentCandidateSearchResult.value.lastName,
            officialType: form.value.coachType,
            comments: addRefForm.comments.text,
            selectedUserIdIfMatchingInleagueUser,
            selectedRegionIfWillBeGeneratingUser: undefined,
            maxAR: undefined,
            maxCR: undefined,
            ref_badgeLevel: undefined,
          });
        props.mut_existingOfficials.unshift(fresh); // new to front
        mostRecentCandidateSearchResult.value = "no-search-yet"; // "zero out" the current search, we assume here we've just "consumed" it
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const doRemoveOfficial = async (official: iltournament.TournamentTeamOfficial) : Promise<void> => {
      if (props.tournamentTeam.tournamentTeamID.toString() !== official.tournamentTeamID.toString()) {
        throw Error("AssertionFailure: props out of sync with function args?");
      }

      try {
        await iltournament.deleteTournamentTeamOfficialByTournamentTeamOfficialID(axiosInstance, {tournamentTeamOfficialID: official.tournamentTeamOfficialID});
        const idx = props.mut_existingOfficials.findIndex(v => v.stackSID === official.stackSID);
        if (idx === -1) {
          return;
        }
        props.mut_existingOfficials.splice(idx, 1);
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const editCoachModalController = reactive((() => {
      const busy = ref(false);

      const form = ref<CoachMutablesFormData | null>(null);
      const onOpenCB = (official: iltournament.TournamentTeamOfficial) => {
        form.value = {
          comments: TextWithMaxLen(official.comments, STRLEN_TOURNTEAM_OFFICIAL_COMMENTS_MAX)
        }
      }

      const doUpdate = async (official: iltournament.TournamentTeamOfficial) => {
        if (official.type === iltournament.TournamentTeamOfficialType.REFEREE || !form.value) {
          throw Error("illegal state");
        }

        try {
          try {
            busy.value = true;
            await iltournament.updateTournamentTeamOfficial(axiosAuthBackgroundInstance, {
              tournamentTeamOfficialID: official.tournamentTeamOfficialID,
              officialType: official.type,
              comments: form.value.comments.text
            })
          }
          finally {
            busy.value = false;
          }

          // in-place update locally
          official.comments = form.value.comments.text;

          // all done
          editCoachModalController.close()
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      };

      const doCancel = () => editCoachModalController.close()

      // don't allow the modal to be closed while we're busy
      const onCloseCB = (doClose : () => void) : void => {
        if (busy.value) {
          return;
        }
        else {
          doClose();
        }
      }

      return DefaultModalController<iltournament.TournamentTeamOfficial>({
        title: () => (
          <>
            <div>Edit coach</div>
            <div class="my-1 border-b border-slate-200"/>
          </>
        ),
        content: official => {
          if (!official) {
            return null;
          }

          if (!form.value) {
            throw Error("illegal state")
          }

          return (
            <>
              <div>Coach {official.firstName} {official.lastName}?</div>
              <div style="--fk-margin-outer:none;">
                <FormKit type="textarea" label="Comments" v-model={form.value.comments.text}/>
                <div class="mt-2 flex">
                  <div class="text-xs ml-auto">{form.value.comments.text.length}/{form.value.comments.maxLen}</div>
                </div>
              </div>
              <div class="flex gap-4 mt-4">
                <t-btn data-test="yes" type="button" onClick={() => doUpdate(official)}>Save</t-btn>
                <t-btn data-test="no" type="button" onClick={() => doCancel()} color="red">Cancel</t-btn>
              </div>
              {
                busy.value
                  ? (
                    <div style="position:absolute; top:0; left:0; width:100%; height: 100%;">
                      <div class="w-full h-full" style="background-color: rgba(255,255,255,0.5)">&nbsp;</div>
                      <div style="position: absolute; top: 0; left: 0; margin-left:4px; margin-top:4px">
                        <SoccerBall color={Client.value.clientTheme.color} width=".2in" height=".2in" timeForOneRotation="1.25s"/>
                      </div>
                    </div>
                  )
                  : null
              }
            </>
          )
        }
      }, {onCloseCB, onOpenCB})
    })())

    const deleteCoachConfirmationModalController = reactive((() => {
      const busy = ref(false);

      const doDelete = async (official: iltournament.TournamentTeamOfficial) => {
        try {
          try {
            busy.value = true;
            await doRemoveOfficial(official)
          }
          finally {
            busy.value = false;
          }
          deleteCoachConfirmationModalController.close()
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      };

      const doCancel = () => deleteCoachConfirmationModalController.close()

      // don't allow the modal to be closed while we're busy
      const onCloseCB = (doClose : () => void) : void => {
        if (busy.value) {
          return;
        }
        else {
          doClose();
        }
      }

      return DefaultModalController<iltournament.TournamentTeamOfficial>({
        title: () => (
          <>
            <div>Remove coach confirmation</div>
            <div class="my-1 border-b border-slate-200"/>
          </>
        ),
        content: official => {
          if (!official) {
            return null;
          }
          return (
            <>
              <div>Remove {official.firstName} {official.lastName}?</div>
              <div class="flex gap-4 mt-4">
                <t-btn data-test="yes" type="button" onClick={() => doDelete(official)}>Yes</t-btn>
                <t-btn data-test="no" type="button" onClick={() => doCancel()} color="red">No</t-btn>
              </div>
              {
                busy.value
                  ? (
                    <div style="position:absolute; top:0; left:0; width:100%; height: 100%;">
                      <div class="w-full h-full" style="background-color: rgba(255,255,255,0.5)">&nbsp;</div>
                      <div style="position: absolute; top: 0; left: 0; margin-left:4px; margin-top:4px">
                        <SoccerBall color={Client.value.clientTheme.color} width=".2in" height=".2in" timeForOneRotation="1.25s"/>
                      </div>
                    </div>
                  )
                  : null
              }
            </>
          )
        }
      }, {onCloseCB})
    })())

    const ExistingListing = () : JSX.Element => {
      return (
        <div>
          <div class="font-medium">Current coaches</div>
          {justCoaches.value.length === 0
            ? <div class="flex flex-col items-center justify-center p-2 text-sm">
                <div>No coaches have been submitted with this team registration.</div>
              </div>
            : null
          }
          {props.canDelete
            ? null
            : <>
              <div class="text-sm">Coaches cannot be deleted at this time.</div>
              <div class="border-b my-1"/>
            </>
          }
          <div class="divide-y divide-gray-100">
            {
              justCoaches
                .value
                .sort((l,r) => l.type === iltournament.TournamentTeamOfficialType.HEAD_COACH ? -1 : 1) // head coaches first
                .sort(sortByLastNameFirstName)
                .map(coach => {
                  return (
                    <div class="my-2 flex" data-test={`type=${coach.type}/stackSID=${coach.stackSID}`}>
                      <div>
                        <div>{coach.firstName} {coach.lastName}</div>
                        <div>{coach.stackSID}</div>
                        <div>{officialTypeUiString(coach.type)}</div>
                        <div>
                          <div class="text-xs font-medium">Comments</div>
                          <div class="text-sm">{coach.comments || "N/A"}</div>
                        </div>
                      </div>
                      <div class="ml-auto flex gap-2 items-start">
                        <button
                          type="button"
                          data-test="edit"
                          class={[`p-1 rounded-md cursor-pointer`, "hover:bg-[rgb(0,0,0,.0625)] active:bg-[rgb(0,0,0,.125)]"]}
                          onClick={() => editCoachModalController.open(coach)}
                        >
                          <FontAwesomeIcon icon={faPencil}/>
                          <span class="ml-2">Edit</span>
                        </button>
                        <button
                          type="button"
                          data-test="remove"
                          disabled={!props.canDelete}
                          class={[`p-1 rounded-md cursor-pointer`, props.canDelete ? "hover:bg-[rgb(0,0,0,.0625)] active:bg-[rgb(0,0,0,.125)]" : "invisible"]}
                          onClick={() => deleteCoachConfirmationModalController.open(coach)}
                        >
                          <FontAwesomeIcon icon={faTrash}/>
                          <span class="ml-2">Remove</span>
                        </button>
                      </div>
                    </div>
                  )
                })
            }
          </div>
        </div>
      )
    }

    const aysoIDLookupFormNodeRef = FK_nodeRef();

    return () => (
      <div data-test="CoachRoster">
        <AutoModal data-test="ConfirmDeleteModal" controller={deleteCoachConfirmationModalController}/>
        <AutoModal data-test="editCoachModal" controller={editCoachModalController}/>
        <div>
          <FormKit type="form" actions={false} onSubmit={doCandidacyLookupByStackSid} ref={aysoIDLookupFormNodeRef}>
            <FormKit type="select" data-test="head-or-asst" options={coachTypeOptions} v-model={form.value.coachType}/>
            <FormKit
              type="text"
              v-model={form.value.stackSID}
              label="AYSOID"
              validation={[["required"], ["matches", aysoIdLenientFormPattern.pattern]]}
              validationMessages={{matches: aysoIdLenientFormPattern.msg("AYSOID")}}
              data-test="stackSID"
            />
            <FormKit type="text" v-model={form.value.lastName} label="Last name" validation={[["required"]]} data-test="lastName"/>
            <Btn2 class="px-2 py-1" data-test="submit" type="submit">
              Find user by AYSO ID
            </Btn2>
            <FormKitMessages class="hidden" node={aysoIDLookupFormNodeRef.value?.node}/>
          </FormKit>
          <div>
            {
              mostRecentCandidateSearchResult.value === "no-search-yet"
                ? null
                : mostRecentCandidateSearchResult.value === "nothing-found"
                ? (
                  <>
                    <div class="border-b border-slate-200 my-2"/>
                    <div>No results.</div>
                  </>
                )
                : (
                  <>
                    <div class="border-b border-slate-200 my-2"/>
                    <CandidateSearchResult
                      tournTteamLocalityDescriptor={props.tournamentTeam.localityDescriptor}
                      candidateSearchResult={mostRecentCandidateSearchResult.value}
                      doAddCoachByCurrentCandidate={doAddCoachByCurrentCandidate}
                    />
                  </>
                )
            }
          </div>
          <div class="border-b border-gray-200 my-1"></div>
        </div>
        <ExistingListing/>
      </div>
    )
  }
})

const CoachMutablesForm = defineComponent({
  props: {
    coachForm: vReqT<CoachMutablesFormData>(),
  },
  setup(props) {
    return () => {
      return (
        <div class="flex flex-col" style="--fk-margin-outer:none;">
          <div>
            <div class="text-sm font-medium">Comments</div>
            <textarea v-model={props.coachForm.comments.text} class="w-full min-h-[10em]"/>
          </div>
          <div class="ml-auto mb-2 text-xs">
            {props.coachForm.comments.text.length}/{props.coachForm.comments.maxLen}
          </div>
        </div>
      )
    }
  }
})

const CandidateSearchResult = defineComponent({
  props: {
    tournTteamLocalityDescriptor: vReqT<"local" | "foreign">(),
    candidateSearchResult: vReqT<iltournament.TournamentTeamOfficialCandidate>(),
    doAddCoachByCurrentCandidate: vReqT<(addRefForm: CoachMutablesFormData, selectedUserIdIfMatchingInleagueUser?: iltypes.Guid) => Promise<void>>(),
  },
  setup(props) {
    const addCoachForm = ref<CoachMutablesFormData>({
      comments: TextWithMaxLen("", STRLEN_TOURNTEAM_OFFICIAL_COMMENTS_MAX),
    })

    const selectedUserIdIfMatchingInleagueUsersExisted = ref("")

    return () => {
      if (!props.candidateSearchResult.candidacy.isCandidate) {
        return <div class="text-sm p-2 text-red-600" data-test="coachRosterError">{props.candidateSearchResult.candidacy.error}</div>
      }
      return (
        <>
          {
            props.candidateSearchResult.candidacy.warning
              ? (
                <div data-test="coachRosterWarning">
                  <div>Warning</div>
                  <div class="text-sm p-2">{props.candidateSearchResult.candidacy.warning}</div>
                  <div class="text-sm p-2">Your permissions allow you to override this warning</div>
                </div>
              )
              : null
          }
          <div>Coach name: {props.candidateSearchResult.firstName} {props.candidateSearchResult.lastName} ({props.candidateSearchResult.stackSID})</div>
          <FormKit type="form" onSubmit={() => props.doAddCoachByCurrentCandidate(addCoachForm.value, selectedUserIdIfMatchingInleagueUsersExisted.value)} actions={false}>
            <MatchingInleagueUserSelector/>
            <CoachMutablesForm coachForm={addCoachForm.value}/>
            <div class="flex">
              <div class="ml-auto">
                <t-btn type="submit" data-test="commitToRoster">Submit</t-btn>
              </div>
            </div>
          </FormKit>
        </>
      )
    }

    function MatchingInleagueUserSelector() {
      if (props.tournTteamLocalityDescriptor === "foreign") {
        // Coaches for foreign teams aren't linked via "actual" users so this info is not relevant.
        // Candidates shouldn't even return a "matchingInLeagueUsers" array in this case anyway.
        return null;
      }

      if (!props.candidateSearchResult.matchingInLeagueUsers) {
        return <div>This doesn't match any existing inLeague user. When you add them, the email address on the AYSO record will be used to invite this coach to create an account.</div>
      }
      if (props.candidateSearchResult.matchingInLeagueUsers.length === 0) {
        throw Error("if present, this list must be non-empty") // backend bug?
      }
      else if (props.candidateSearchResult.matchingInLeagueUsers.length === 1) {
        const match = props.candidateSearchResult.matchingInLeagueUsers[0]
        selectedUserIdIfMatchingInleagueUsersExisted.value = match.userID
        return (
          <div>
            <span>This matches inLeague user {match.firstName} {match.lastName} ({match.email})</span>
          </div>
        )
      }
      else {
        const options = props.candidateSearchResult.matchingInLeagueUsers.map(v => ({label: `${v.firstName} ${v.lastName} (${v.email})`, value: v.userID}))
        return (
          <div style="--fk-border:none;">
            <div>This matched multiple inLeague users, please choose one:</div>
            <FormKit type="radio" name="Matching inLeague user" v-model={selectedUserIdIfMatchingInleagueUsersExisted.value} options={options} validation={[["required"]]}/>
          </div>
        )
      }
    }
  }
})
