import { defineComponent, ref, watch } from "vue";
import { FormKit, FormKitMessages } from "@formkit/vue";
import { Btn2, btn2_redEnabledClasses } from "src/components/UserInterface/Btn2";
import { vReqT, copyViaJsonRoundTrip, FK_nodeRef, useAutoFocusOnMount } from "src/helpers/utils";
import { Integerlike } from "src/interfaces/InleagueApiV1";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faLock, faUnlockKeyhole } from "@fortawesome/pro-solid-svg-icons";

export const NeighboringDivisionsExtentModal = defineComponent({
  props: {
    initialExtent: vReqT<{left: number, right: number}>()
  },
  emits: {
    commit: (_: {left: Integerlike, right: Integerlike}) => true,
    cancel: () => true,
  },
  setup(props, ctx) {
    const extent = ref<{left: Integerlike, right: Integerlike}>(copyViaJsonRoundTrip(props.initialExtent))
    const lockstep = ref(props.initialExtent.left === props.initialExtent.right)

    const fkLeft = FK_nodeRef();
    const fkRight = FK_nodeRef()

    watch(() => extent.value.left, () => {
      if (lockstep.value) {
        extent.value.right = extent.value.left
      }
    })

    const toggleLockStep = () => {
      if (!lockstep.value) {
        // transition TO lockstep, adjust so both are now equal
        extent.value.right = extent.value.left
      }

      // invert current value
      lockstep.value = !lockstep.value
    }

    const autoFocusAttr = useAutoFocusOnMount()

    return () => {
      return (
        <div style="--fk-margin-outer: none; --fk-padding-input: .5em;">
          <FormKit type="form" actions={false} onSubmit={() => ctx.emit("commit", extent.value)}>
            <div class="max-w-48" style="display:grid; grid-template-columns: auto auto auto; grid-gap: .25em .5em; align-items:center; ">
              <div>Below</div>
              <FormKit
                type="number"
                ref={fkLeft}
                v-model={extent.value.left}
                validation={[["required"], ["min", 1], ["max", 5]]}
                validationLabel="'Below''"
                {...autoFocusAttr}
                data-test="below"
              />
              <div grid-cell-placeholder/>

              <div style="grid-column:-1/1;">
                <FormKitMessages node={fkLeft.value?.node}/>
              </div>

              <div>Above</div>
              <FormKit
                type="number"
                ref={fkRight}
                disabled={lockstep.value}
                v-model={extent.value.right}
                validation={[["required"], ["min", 1], ["max", 5]]}
                validationLabel="'Above'"
                data-test="above"
              />
              <div>
                <button type="button" class="il-buttonlike-1 rounded p-1" onClick={() => toggleLockStep()}>
                  {lockstep.value
                    ? <FontAwesomeIcon icon={faLock}/>
                    : <FontAwesomeIcon icon={faUnlockKeyhole}/>
                  }
                </button>
              </div>

              <div style="grid-column:-1/1;">
                <FormKitMessages node={fkRight.value?.node}/>
              </div>
            </div>
            <div class="mt-6 flex gap-2">
              <Btn2 class="px-2 py-1" type="submit" data-test="submit-button">OK</Btn2>
              <Btn2 class="px-2 py-1" enabledClasses={btn2_redEnabledClasses} onClick={() => ctx.emit("cancel")}>Cancel</Btn2>
            </div>
          </FormKit>
        </div>
      )
    }
  }
});
