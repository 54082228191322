import { computed, defineComponent, nextTick, ref, watch } from "vue"

import { axiosInstance } from "src/boot/AxiosInstances"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import * as iltypes from "src/interfaces/InleagueApiV1"

import { propsDef } from "./R_TournamentTeamConfigurator.route";
import { arrayDropIf, assertTruthy, exhaustiveCaseGuard, FK_validation_strlen, parseIntOrFail, vReqT, weakEq } from "src/helpers/utils";

import { PlayerRoster } from "./TournamentTeamConfigurator.players"
import { CoachRoster } from "./TournamentTeamConfigurator.coaches"
import { RefereeRoster, RefereeAssignments } from "./TournamentTeamConfigurator.referees";
import { AdminRoster } from "./TournamentTeamConfigurator.admins";

import { NavigationGuardWithThis } from "vue-router";

import authService from "src/helpers/authService";
import { QuestionAnswerMap, TournamentTeamRegistrationQuestionsForm, formAnswersToApiSubmittable } from "./TournamentTeam.questionsForm";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { isAuthorizedToManageTournament, teamDesignation } from "./TournamentTeamUtils";
import { User } from "src/store/User";

import { TournamentTeamExpandedForConfigurator, getTournamentTeamExpandedForConfigurator } from "./TournamentTeamConfigurator.shared";
import { tournamentTeamStore } from "./Store/TournTeamStore";
import { getCanMakeTournamentTeamEdits, updateTournamentTeamName } from "src/composables/InleagueApiV1.Tournament";
import { Guid, Integerlike } from "src/interfaces/InleagueApiV1";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { AutoModal, DefaultModalController_r, DefaultTinySoccerballBusyOverlay, useDefaultNoCloseModalIfBusy } from "../UserInterface/Modal";
import { FormKit } from "@formkit/vue";
import { Btn2, btn2_redEnabledClasses } from "../UserInterface/Btn2";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";

const handleRouteChange : NavigationGuardWithThis<any> = async function (to, _from, next) : Promise<void> {
  const tournamentTeamID = parseIntOrFail(to.params.tournamentTeamID);

  const hasPerms = await tournamentTeamStore.getIsAuthorizedToManageTournamentTeam(axiosInstance, tournamentTeamID)

  if (hasPerms) {
    return next();
  }
  else {
    return next({name: "forbidden"})
  }
}

export default defineComponent({
  name: "R_TournamentRegistration",
  props: propsDef,
  beforeRouteEnter: handleRouteChange,
  beforeRouteUpdate: handleRouteChange,
  setup(props) {


    interface ResolvedBlob {
      readonly registrationPageItems: iltournament.TournTeamRegPageItem[]
      readonly registrationAnswers: iltournament.TournamentRegistrationAnswer[]
      readonly tournamentTeam: TournamentTeamExpandedForConfigurator
      readonly canDeleteRefs: boolean,
      readonly canDeleteCoaches: boolean,
    }
    type Awaitables =
      | {readonly ready: false}
      | ({readonly ready: true} & ResolvedBlob);

    const awaitables = ref<Awaitables>({ready: false})

    // this supports direct assignment to tournamentTeam.admin rather than splicing in place,
    // but we have to pay the "assert lifecycle" tax. We don't do this for `officials` so we probably
    // don't need to do it for team admins...?
    const mut_teamAdmins = computed({
      get() {
        if (!awaitables.value.ready) {
          throw Error("should not be dereferenced when !ready")
        }
        return awaitables.value.tournamentTeam.admin
      },
      set(v) {
        if (!awaitables.value.ready) {
          throw Error("should not be dereferenced when !ready")
        }
        awaitables.value.tournamentTeam.admin = v;
      },
    })

    const reload = async ({tournamentTeamID}: {tournamentTeamID: Integerlike}) : Promise<void> => {
      const fresh = {
        ready: true,
        registrationPageItems: await iltournament.getTournamentTeamRegistrationPageItems(axiosInstance, {tournamentTeamID}),
        registrationAnswers: await iltournament.getTournamentTeamRegistrationAnswers(axiosInstance, {tournamentTeamID}),
        tournamentTeam: await getTournamentTeamExpandedForConfigurator(axiosInstance, tournamentTeamID),
        ...(await getCanMakeTournamentTeamEdits(axiosInstance, {tournamentTeamID})),
      };

      // less "not ready" in the UI, looks a little better
      awaitables.value = {ready: false}
      await nextTick()
      awaitables.value = fresh
    }

    watch(() => props.tournamentTeamID, async () => {
      GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        await reload({tournamentTeamID: props.tournamentTeamID})
      })
    }, {immediate: true});

    const handleSubmitQuestionAnswers = async (answerMap: QuestionAnswerMap) : Promise<void> => {
      try {
        await iltournament.submitTournamentTeamRegistrationAnswers(
          axiosInstance, {
            tournamentTeamID: props.tournamentTeamID,
            answers: formAnswersToApiSubmittable(answerMap)
          });
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const readonlyQuestionIDs = computed<Set<Guid>>(() => {
      if (!awaitables.value.ready) {
        return new Set()
      }

      if (isRegistrar.value) {
        return new Set();
      }

      const canMakeEdits = (() => {
        switch (awaitables.value.tournamentTeam.status) {
          case "PENDING":
            return true;
          case "APPROVED":
          case "DROPPED_BY_HOST":
          case "DROPPED_BY_SUBMITTER":
          case "PAID_AWAITING_APPROVAL":
            return false;
          default: exhaustiveCaseGuard(awaitables.value.tournamentTeam.status);
        }
      })();

      if (canMakeEdits) {
        // "no questions are readonly"
        return new Set();
      }

      return new Set(
        awaitables.value.registrationPageItems
          .filter((v) : v is iltournament.TournTeamRegPageItem_Question => v.type === iltypes.PageItemType.QUESTION)
          .filter((v) => v.containedItem.isEditable)
          .map(v => v.questionID)
      );
    })

    const isRegistrar = computed(() => authService(User.value.roles, "registrar"));
    const isTournAdmin = computed(() => awaitables.value.ready ? isAuthorizedToManageTournament(awaitables.value.tournamentTeam.competitionUID, User.value) : false);

    const editTeamNameModal = (() => {
      const {busy, onCloseCB} = useDefaultNoCloseModalIfBusy()
      const Elem = defineComponent({
        props: {
          initialTeamName: vReqT<string>(),
        },
        emits: {
          commit: (_: {tournTeamName: string}) => true,
          cancel: () => true,
        },
        setup(props, ctx) {
          const teamName = ref(props.initialTeamName)
          return () => {
            return (
              <div style="--fk-padding-input: .35em;">
                <FormKit type="form" actions={false} onSubmit={() => ctx.emit("commit", {tournTeamName: teamName.value})}>
                  <div class="mt-2">
                    <FormKit
                      type="text"
                      data-test="teamName-input"
                      v-model={teamName.value} validation={[["required"], FK_validation_strlen(1, 50)]} validationLabel="Team name"/>
                  </div>
                  <div class="flex gap-2 items-center mt-4">
                    <Btn2 class="px-2 py-1" data-test="submit" type="submit">OK</Btn2>
                    <Btn2 class="px-2 py-1" enabledClasses={btn2_redEnabledClasses} onClick={() => ctx.emit("cancel")}>Cancel</Btn2>
                  </div>
                </FormKit>
              </div>
            )
          }
        }
      })

      const doUpdateTournTeamName = async (args: {tournamentTeamID: Integerlike, tournamentTeamName: string}) : Promise<void> => {
        try {
          await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
            try {
              busy.value = true
              await updateTournamentTeamName(axiosInstance, {tournamentTeamID: args.tournamentTeamID, tournamentTeamName: args.tournamentTeamName})
              await reload(args)
            }
            finally {
              busy.value = false
            }

            editTeamNameModal.close()
          })
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      }
      return DefaultModalController_r<{areaTeamName: string, tournamentTeamID: Integerlike}>({
        title: () => <>
          <div>Edit Tournament Team Name</div>
          <div class="border-b my-2"/>
        </>,
        content: data => {
          if (!data) {
            return null
          }
          return <div>
            <Elem
              initialTeamName={data.areaTeamName}
              onCommit={args => doUpdateTournTeamName({tournamentTeamID: data.tournamentTeamID, tournamentTeamName: args.tournTeamName})}
              onCancel={() => editTeamNameModal.close()}
            />
            <DefaultTinySoccerballBusyOverlay if={busy.value}/>
          </div>
        }
      }, {onCloseCB})
    })();

    return () => (
      <div data-test="R_TournamentRegistration">
        <AutoModal controller={editTeamNameModal} data-test="EditTeamName-modal"/>
        {
          awaitables.value.ready
            ? (
              <>
                <div class="inline-block">
                  Tournament team {teamDesignation(awaitables.value.tournamentTeam)}
                  <div class="my-1 border-b border-dashed border-gray-300"/>
                </div>
                <div class="flex gap-2 items-center">
                  {awaitables.value.tournamentTeam.status !== "DROPPED_BY_HOST" && awaitables.value.tournamentTeam.status !== "DROPPED_BY_SUBMITTER"
                    ? <button
                      type="button"
                      class="rounded-md px-1 il-buttonlike-1"
                      data-test="editTeamName-button"
                      onClick={() => {
                        assertTruthy(awaitables.value.ready)
                        editTeamNameModal.open({tournamentTeamID: props.tournamentTeamID, areaTeamName: awaitables.value.tournamentTeam.areaTeam.teamname})}
                    }>
                      <FontAwesomeIcon icon={faPencilAlt}/>
                    </button>
                    : null
                  }
                  <div data-test="teamName">{awaitables.value.tournamentTeam.areaTeam.teamname}</div>
                </div>
                {
                  (() => {
                    switch (awaitables.value.tournamentTeam.status) {
                      case "DROPPED_BY_HOST":
                        // fallthrough
                      case "DROPPED_BY_SUBMITTER":
                        // do we ever want to configure a dropped team? maybe we want to show details about how it was prior to it being dropped
                        return null;
                      case "PENDING":
                        return (
                          <>
                            {
                              isTournAdmin.value
                                ? (
                                  // tourn admin can edit questions here in pending state
                                  <>
                                    <Rosters resolved={awaitables.value}/>
                                    <TeamManagers resolved={awaitables.value}/>
                                    <Questions resolved={awaitables.value}/>
                                  </>
                                )
                                // a "just" tournTeamManager can edit rosters here
                                : <>
                                  <Rosters resolved={awaitables.value}/>
                                  <TeamManagers resolved={awaitables.value}/>
                                </>
                            }
                          </>
                        );
                      case "PAID_AWAITING_APPROVAL":
                        // fallthrough
                      case "APPROVED":
                        return (
                          <>
                            <Rosters resolved={awaitables.value}/>
                            <TeamManagers resolved={awaitables.value}/>
                            <Questions resolved={awaitables.value}/>
                          </>
                        )
                      default: exhaustiveCaseGuard(awaitables.value.tournamentTeam.status);
                    }

                    function TeamManagers({resolved}: {resolved: ResolvedBlob}) {
                      return (
                        <div class="shadow-md my-6 rounded-md bg-white">
                          <div class="bg-green-800 rounded-t-md p-1 text-white">Team managers</div>
                          <div class="p-2">
                            <AdminRoster
                              tournamentTeamID={props.tournamentTeamID}
                              mut_existingAdmins={mut_teamAdmins}
                              canMakeEdits={true}
                            />
                          </div>
                        </div>
                      )
                    }

                    function Questions({resolved}: {resolved: ResolvedBlob}) {
                      return (
                        <>
                          <div class="shadow-md my-6 rounded-md bg-white">
                            <div class="bg-green-800 p-1 rounded-t-md text-white">
                              <div>Registration questions</div>
                              {
                                !isRegistrar.value ? <div class="text-xs">readonly view of existing answers</div> : null
                              }
                            </div>
                            <div class="p-2">
                              <TournamentTeamRegistrationQuestionsForm
                                key={props.tournamentTeamID}
                                pageItems={resolved.registrationPageItems}
                                existingAnswers={resolved.registrationAnswers}
                                readonlyQuestionIDs={readonlyQuestionIDs.value}
                                doShowPoints={isRegistrar.value}
                                submitLabel="Update"
                                doHideSubmitButton={
                                  // if all questions are readonly, don't show the submit button
                                  resolved
                                    .registrationPageItems
                                    .filter(v => v.type === iltypes.PageItemType.QUESTION).length === readonlyQuestionIDs.value.size
                                }
                                onSubmit={handleSubmitQuestionAnswers}
                              />
                            </div>
                          </div>
                        </>
                      )
                    }
                    function Rosters({resolved}: {resolved: ResolvedBlob}) {
                      return (
                        <>
                          {
                            resolved.tournamentTeam.tournament_playerRosterSubmissionConfig === iltournament.TournTeamPlayerRosterSubmissionConfig.allowed
                              ? (
                                <div class="shadow-md my-6 rounded-md bg-white">
                                  <div class="bg-green-800 rounded-t-md p-1 text-white">Player roster</div>
                                  <div class="p-2">
                                    <PlayerRoster
                                      tournamentTeamID={props.tournamentTeamID}
                                      mut_existingPlayerLinks={resolved.tournamentTeam.playerLinks}
                                      canMakeEdits={true}
                                    />
                                  </div>
                                </div>
                              )
                              : null
                          }
                          <div class="shadow-md my-6 rounded-md bg-white">
                            <div class="bg-green-800 rounded-t-md p-1 text-white">Referee roster</div>
                            <div class="p-2">
                              <RefereeRoster
                                tournTeamOrUndefinedIfUnaffiliatedOrMultiple={resolved.tournamentTeam}
                                targetBinding={{type: "tournamentTeam", tournamentTeamID: props.tournamentTeamID}}
                                mut_existingOfficials={resolved.tournamentTeam.officials}
                                canDelete={resolved.canDeleteRefs}
                                onAddedRef={ref => {
                                  resolved.tournamentTeam.refTournTeamOfficials.push(ref)
                                }}
                                onRemovedRef={({tournamentTeamOfficialID}) => {
                                  resolved.tournamentTeam.refTournTeamOfficials = arrayDropIf(
                                    resolved.tournamentTeam.refTournTeamOfficials,
                                    v => weakEq(tournamentTeamOfficialID, v.tournamentTeamOfficialID)
                                  )
                                }}
                              />
                            </div>
                          </div>
                          <div class="shadow-md my-6 rounded-md bg-white">
                            <div class="bg-green-800 rounded-t-md p-1 text-white">Referee assignments</div>
                            <div class="p-2" style="max-height:800px; overflow:auto;">
                              <RefereeAssignments refOfficials={resolved.tournamentTeam.refTournTeamOfficials}/>
                            </div>
                          </div>
                          <div class="shadow-md my-6 rounded-md bg-white">
                            <div class="bg-green-800 rounded-t-md p-1 text-white">Coach roster</div>
                            <div class="p-2">
                              <CoachRoster
                                tournamentTeam={resolved.tournamentTeam}
                                mut_existingOfficials={resolved.tournamentTeam.officials}
                                canDelete={resolved.canDeleteCoaches}
                              />
                            </div>
                          </div>
                        </>
                      );
                    }
                  })()
                }
              </>
            )
            : null
        }
      </div>
    )
  }
})
