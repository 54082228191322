<template lang="pug">
nav.flex-1.px-2.space-y-1(aria-label='Sidebar' v-if='Object.keys(nonGroupedLinks).length' :class='background')
  router-link.w-full(:to="{name: 'login', force: true}" v-if="!isLoggedIn")
    TBtn(width="w-full")
      div.w-full.tracking-wide.font-bold.text-lg.self-center Login
  router-link.w-full(:to="{name: 'select-season', params: { role: 'player'}}" v-if="isLoggedIn && usersChildren.length>0")
    TBtn(
      data-test="player-registration-sidebar-button"
      width='w-full'
      @click='closeSidebar'
    )
      div.w-full.tracking-wide.font-bold.text-lg.self-center Player Registration
  router-link.w-full(:to="{name: 'add-player'}" v-else-if="isLoggedIn")
    TBtn(
      data-test="player-registration-sidebar-button"
      width='w-full'
      @click='closeSidebar'
    )
      div.w-full.tracking-wide.font-bold.text-lg.self-center Player Registration
  router-link(v-if="isLoggedIn" :to="{name: volunteerRegistrationFlowEntry}")
    TBtn(
      width='w-full'
      @click='closeSidebar'
    )
      div.w-full.tracking-wide.font-bold.text-lg.self-center Volunteer Registration
  router-link(:to="defaultFamilyProfileRouteLocation" v-if="isLoggedIn")
    TBtn(
      width='w-full'
      @click='closeSidebar'
      data-cy="router-link-family-profile"
    )
      div.w-full.tracking-wide.font-bold.text-lg.self-center Family Profile
  div(v-for="item in nonGroupedLinks")
    a(
      v-if="item.legacyLink"
      target="_blank"
      :href="buildLegacyLink(item.legacyLink)"
      :class="['bg-green-800 text-white hover:bg-green-700 hover:text-white', 'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md']"
    )
      font-awesome-icon.mx-2(:icon='["fas", item.meta?.icon]')
      | {{ item.meta?.label }}
    router-link(
      v-else-if="(item.meta?.mainNav || item.meta?.mainNavLazy?.()) && (isLoggedIn || isRef)"
      @click='closeSidebar'
      :to='{name: item.name}'
      :class="[item.meta.current ? 'bg-green-700 text-white' : 'bg-green-800 text-white hover:bg-green-700 hover:text-white', 'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md']"
    )
      font-awesome-icon.mx-2(:icon='["fas", item.meta.icon]')
      div {{ item.meta.label }}
  template(v-for='(category, categoryName) in groupedLinks' :key="`${categoryName}/${currentUserID}`")
    div(
      v-if="category.children.length && (isLoggedIn || !category.meta.requiresAuth)"
      :class="[`rounded-md`, category.meta.current ? 'bg-green-700 text-white' : 'bg-green-800 text-white hover:text-white']"
    )
      div(
        @click="() => toggleNavLinkCategoryState({categoryName})"
        :data-cy="category.meta.label" :class="['cursor-default group w-full pl-1 flex py-2 md:py-0 items-center text-left text-sm font-medium rounded-md focus:outline-none']"
      )
        font-awesome-icon.m-2(:icon='["fas", category.meta.icon]')
        .flex
          div {{ category.meta.label }}
          svg(:class="[navLinkState.categories[categoryName]?.open ? 'text-white rotate-90' : 'text-white', 'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-white transition-colors ease-in-out duration-150']" viewbox='0 0 20 20' aria-hidden='true')
            path(d='M6 6L14 10L6 14V6Z' fill='currentColor')
      //-
      //- "margin-top: -2px" should probably be "no top padding on first contained element"
      //-
      div(
        class="rounded-b-md.pb-1" style="margin-top:-2px;"
        v-if="navLinkState.categories[categoryName]?.open"
      )
        template(v-for='(subItem, i) in category.children')
          template(v-if="(subItem.meta?.mainNav || subItem.meta?.mainNavLazy?.()) && (isLoggedIn || !subItem.meta?.requiresAuth)")
            div.cursor-pointer.group.w-full.flex.items-center.pl-4.pr-2.py-4.text-sm.font-medium.text-white(class="md:py-1 hover:text-white hover:bg-green-700 bg-green-800 rounded-md")
              a(
                data-test="legacy-link"
                :href="buildLegacyLink(subItem.legacyLink)" target="_blank"
                v-if="subItem.legacyLink"
                :key='subItem.legacyLink.urlTarget || subItem.legacyLink.eventTarget' :data-cy='subItem.name' :class="[category.children.length === i+1 ? 'rounded-b-md' : '']"
              )
                font-awesome-icon.mx-2(:icon='["fas", subItem.meta?.icon]')
                | {{ subItem.meta?.label }}
              router-link.flex.items-center(
                v-else
                @click='closeSidebar'
                :key='subItem.name' :data-cy='subItem.name' :to='{name: subItem.name}' :class="[category.children.length === i+1 ? 'rounded-b-md' : '']"
              )
                font-awesome-icon(:icon='["fas", "arrow-right"]' v-if="isCurrentRoute(subItem)")
                font-awesome-icon.mx-2(:icon='["fas", subItem.meta?.icon]' data-test="router-link")
                | {{ subItem.meta?.label }}
</template>

<script lang='ts'>
import { RouteRecordRaw } from 'vue-router'
import { useRouter } from 'vue-router'
import routes, { LegacyLink, RouteRecordExtension, routeAuthPredicateRunner } from 'src/router/routes'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { defineComponent, watch, computed, ref } from 'vue'
import { UserData } from 'src/interfaces/Store/user'
import * as FamilyProfile from "src/components/FamilyProfile/pages/FamilyProfile.ilx"

import * as R_VolunteerRegistrationFlow from 'src/components/VolunteerRegistration/R_VolunteerRegistrationFlow.route'
import { buildLegacyLink } from 'src/boot/auth'

import { System } from 'src/store/System'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'
import { assertTruthy } from 'src/helpers/utils'
import { Guid } from 'src/interfaces/InleagueApiV1'

/**
 * Navlink state currently drives what categories are open/closed.
 * It does __not__ drive what is made available to a user -- that is, permissions checks are separate from this (see `createLinks`)
 * This is a module global because we mount the "links" component in at least 2 different places based on screen size, but conceptually they
 * share the same state.
 */
const navLinkState = ref(localStorage_navlinkState_load(User.userData?.userID || null))

export default defineComponent({
  name: 'Navigational Links',
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  },
  props: {
    background: {
      type: String,
      default: 'bg-green-900',
    }
  },
  setup() {

    const router = useRouter()

    /**
     * non-nested links (e.g., top level, not grouped under some parent category)
     */
    const nonGroupedLinks = ref<(RouteRecordRaw & RouteRecordExtension)[]>([])
    /**
     * groups of links, grouped by category. Groupings are all exactly 1-deep (e.g. a parent category like "teams" has child links and there is no further nesting)
     */
    const groupedLinks = ref(freshGroupedLinkCategories())

    watch(() => navLinkState.value, () => {
      const userID = User.userData?.userID ?? null;
      if (!userID) {
        // if there's no current userID, we don't need to store it.
        return;
      }
      localStorage_navlinkState_persist(userID, navLinkState.value)
    }, {deep: true})

    const toggleNavLinkCategoryState = (args: {categoryName: string}) => {
      const state = navLinkState.value.categories[args.categoryName]
      if (state) {
        state.open = !state.open;
      }
    }

    const getRoles = computed(() => {
      return User.value.roles
    })

    const isLoggedIn = computed(() => {
      return User.isLoggedIn
    })

    const isImpersonating = computed(() => {
      return (User.value.userData as UserData).isImpersonating
    })

    const isRef = computed(() => {
      return (User.value.userData as UserData).isRef
    })

    const openSidebar = computed(() => {
      return System.value.openSidebar
    })

    const isMobile = computed(() => {
      return System.value.isMobile == true
    })

    const usersChildren = computed(() => {
      return (User.value.userData as UserData).belongingChildrenIDs
    })

    const createLinks = async () => {
      const nonGroupedLinks : (RouteRecordRaw & RouteRecordExtension)[] = []
      const groupedLinks = freshGroupedLinkCategories();

      const addToGroupedLinks = (item: RouteRecordRaw & RouteRecordExtension) => {
        if (!item.meta.category) {
          throw "requires an associated category";
        }
        groupedLinks[item.meta.category].children.push(item)
      }

      for (const key in routes) {
        if (key === 'home') {
          // Needed because default '/' route must come last but home comes first
          nonGroupedLinks.unshift(routes[key])
        }
        else if (
          (routes[key].meta?.mainNav || routes[key].meta?.mainNavLazy?.()) &&
          (await routeAuthPredicateRunner(routes[key].meta))
        ) {
          if (routes[key].meta?.category && !isMobile.value) {
            addToGroupedLinks(routes[key])
          }
          else {
            nonGroupedLinks.push(routes[key])
          }
        }
        else {
          // no-op
        }
      }

      return {
        nonGroupedLinks,
        groupedLinks
      }
    }

    const closeSidebar = () => {
      System.directCommit_setOpenSidebar(false)
    }

    const goHome = async () => {
      closeSidebar()
      if(isMobile.value) {
        await router.push({name: 'mobile-landing'})
      } else {
        await router.push({name: 'home'})
      }
    }

    const goToRegistration = async () => {
      closeSidebar()
      if(usersChildren.value.length) {
        await router.push({name: 'select-season', params: { role: 'player'}})
      } else {
        await router.push({name: 'add-player'})
      }
    }

    /**
     * on firstMount, or when login information changes, regenerate links, based on the assumption
     * that user permissions have changed, and that consquently which links we want to offer has changed.
     * It is probably sufficient to only watch userID, where we have
     *    watch                           | userID transition
     *    --------------------------------+-------------------
     *  - isLoggedIn transitions to false | some-userID -> ""
     *  - isLoggedIn transitions to true  | ""          -> some-userID
     *  - isImpersonatingChanges          | oldUserID   -> newUserID
     *
     * Watching `userID` also serves to cover the case watching only isLoggedIn and isImpersonating will miss,
     * where, while impersonating, another impersonation is triggered, and
     *  - isLoggedIn stays true
     *  - isImpersonating stays true
     */
    watch([() => User.value.userID, () => isLoggedIn.value, () => isImpersonating.value, () => System.navLinkRebuildSignal], async () => {
      const freshLinks = await createLinks();
      navLinkState.value = localStorage_navlinkState_load(User.userData?.userID || null);
      nonGroupedLinks.value = freshLinks.nonGroupedLinks
      groupedLinks.value = freshLinks.groupedLinks;
    }, {immediate: true})

    /**
     * Is the "current route" equal to some other route 'X' (or one of X's children)?
     */
    const isCurrentRoute = (route: RouteRecordRaw, depth = 0) : boolean => {
      if (depth === 32) {
        // we shouldn't ever end up this deep, but just in case
        return false;
      }
      return router.currentRoute.value.name === route.name || !!route.children?.some(r => isCurrentRoute(r, depth + 1))
    }

    return {
      isRef,
      isMobile,
      nonGroupedLinks,
      groupedLinks,
      getRoles,
      isLoggedIn,
      openSidebar,
      goHome,
      closeSidebar,
      goToRegistration,
      usersChildren,
      volunteerRegistrationFlowEntry: R_VolunteerRegistrationFlow.FlowStepRouteName.entry,
      buildLegacyLink: ({urlTarget, eventTarget}: LegacyLink) => {
        return buildLegacyLink(Client.value.instanceConfig.appdomain, urlTarget || "", eventTarget || "");
      },
      defaultFamilyProfileRouteLocation: FamilyProfile.asRouteLocationRaw({name: FamilyProfile.RouteName.default}),
      navLinkState,
      toggleNavLinkCategoryState,
      currentUserID: computed(() => User.userData?.userID ?? ""),
      isCurrentRoute,
    }
  },
})

interface RouterLinkGroup {
  meta: {
    current: boolean,
    icon: string,
    requiresAuth: boolean,
    label: string,
    auth: string[],
    category?: string
  },
  children: (RouteRecordRaw & RouteRecordExtension)[]
}

function freshGroupedLinkCategories() : {[key: string]: RouterLinkGroup} {
  return {
    Players: {
      meta: {
        current: false,
        icon: 'child',
        requiresAuth: true,
        label: 'Players',
        auth: []
      },
      children: []
    },
    Volunteers: {
      meta: {
        current: false,
        icon: 'user',
        requiresAuth: true,
        label: 'Users',
        auth: []
      },
      children: []
    },
    Teams: {
      meta: {
        current: false,
        icon: "cog",
        requiresAuth: true,
        label: "Teams",
        auth: []
      },
      children: []
    },
    Games: {
      meta: {
        current: false,
        icon: 'futbol',
        requiresAuth: false,
        label: 'Games',
        auth: [],
      },
      children: []
    },
    Referees: {
      meta: {
        current: false,
        icon: 'id-badge',
        requiresAuth: true,
        label: 'Referee',
        auth: []
      },
      children: []
    },
    Events: {
      meta: {
        current: false,
        icon: 'calendar-day',
        requiresAuth: true,
        label: 'Events',
        auth: [],
        category: 'Events'
      },
      children: []
    },
    Competitions: {
      meta: {
        current: false,
        icon: 'file-certificate',
        requiresAuth: true,
        label: 'Programs',
        auth: []
      },
      children: []
    },
    Divisions: {
      meta: {
        current: false,
        icon: 'file-certificate',
        requiresAuth: true,
        label: 'Division',
        auth: []
      },
      children: []
    },
    Utilities: {
      meta: {
        current: false,
        icon: 'cog',
        requiresAuth: false,
        label: 'Utilities',
        auth: []
      },
      children: []
    },
    Messaging: {
      meta: {
        current: false,
        icon: 'comments',
        label: 'Messaging',
        requiresAuth: true,
        auth: ['Coach', 'hasUnembargoedPlayer'],
      },
      children: []
    },
    Admin: {
      meta: {
        current: false,
        icon: 'users-cog',
        requiresAuth: true,
        label: 'Admin',
        auth: []
      },
      children: []
    },
    Tournaments: {
      meta: {
        current: false,
        icon: "trophy",
        requiresAuth: false,
        label: "Tournaments",
        auth: []
      },
      children: []
    },
  }
}

/**
 * everything is optional because it it comes from localstorage.
 * Need a nice runtime shape validator like zod or something.
 */
interface NavLinkCategoryState {
  open?: boolean
}

interface NavLinkState {
  categories: {
    [category: string]: NavLinkCategoryState | /*no-unchecked*/ undefined
  }
}

function freshNavLinkCategoryState() : NavLinkCategoryState {
  return {
    open: true,
  }
}

function freshNavLinkState() : NavLinkState {
  const result : NavLinkState = {categories: {}}
  for (const key of Object.keys(freshGroupedLinkCategories())) {
    result.categories[key] = freshNavLinkCategoryState()
  }
  return result;
}

function localStorage_navlinkState_getKey(userID: Guid) : string {
  return `navlink-group-state/${userID}`
}

function localStorage_navlinkState_persist(userID: Guid, state: NavLinkState) : void {
  localStorage.setItem(localStorage_navlinkState_getKey(userID), JSON.stringify(state));
}

function localStorage_navlinkState_load(userID: Guid | null) : NavLinkState {
  if (!userID) {
    // if there's no logged in user, load a default navlink state
    return freshNavLinkState()
  }

  try {
    const xobj = JSON.parse(localStorage.getItem(localStorage_navlinkState_getKey(userID)) ?? "")

    // rough shape checks

    if (typeof xobj !== "object" || xobj === null) {
      return freshNavLinkState();
    }

    const navLinkState = xobj as NavLinkState;

    if (typeof navLinkState.categories !== "object" || typeof navLinkState.categories === null) {
      return freshNavLinkState();
    }

    for (const k of Object.keys(navLinkState.categories)) {
      if (typeof navLinkState.categories[k] !== "object" || navLinkState.categories[k] === null) {
        return freshNavLinkState();
      }
    }

    return navLinkState;
  }
  catch {
    return freshNavLinkState();
  }
}
</script>
