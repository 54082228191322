import { computed, defineComponent, onMounted, ref } from "vue";
import { LocationQuery, LocationQueryValueRaw, onBeforeRouteUpdate, useRouter } from "vue-router";
import { authZ_accessRatingsReport, authZ_canDefineRatings, authZ_canDoCoachPlayerRatings, authZ_canDoRegionalPlayerRatings, RouteName, RouteNames } from "./R_PlayerRatings.route";
import { TabDef, Tabs } from "../UserInterface/Tabs";
import { assertIs, assertNonNull, exhaustiveCaseGuard, nextOpaqueVueKey, requireNonNull, useIziToast } from "src/helpers/utils";
import { ClientRatingsConfigurator, ClientRatingFormEvent } from "./ClientRatingsConfigurator";
import { ClientRating, ClientRatingCrudRequest, crudClientRatings, listClientRatings } from "./ClientRatingsConfigurator.io";
import { axiosAuthBackgroundInstance, axiosInstance } from "src/boot/AxiosInstances";
import { withNoScroll } from "src/router/RouterScrollBehavior";
import { QueryParams_RatePlayers, queryParams_ratePlayers, RatePlayers } from "./RatePlayers"
import { Guid, Integerlike, RegistrationID } from "src/interfaces/InleagueApiV1";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { ClientRatingForm } from "./ClientRatingsConfiguratorElems";
import { PlayerRatingsReport, queryParams_playerRatingsReport, QueryParams_PlayerRatingsReport } from "./PlayerRatingsReport";
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { buildFormElemMapping, RegFormElemContainer } from "./RatePlayersForm";
import { listPlayersForRatingsInput, RatingsMode, RegWithRatings, updatePlayerRatings, UpdatePlayerRatingsRequest } from "./PlayerRatings.io";

export default defineComponent({
  setup() {
    const router = useRouter();
    const iziToast = useIziToast();
    const selectedTabIdx = ref(0)
    const clientRatings = ref<null | Readonly<{__renderKey: string, value: {core: ClientRating, custom: ClientRating[], hasSomeExistingCoreRating: boolean}}>>(null)

    /**
     * The contract here is that the `formsByRegID` contains a mapping for every registrationID in `playerRatings`.
     * Lookups by those registrationIDs should never fail.
     */
    const playerRatings = ReactiveReifiedPromise<{
      seasonUID: Guid,
      teamID: Guid,
      ratingsMode: RatingsMode,
      playerRatings: RegWithRatings[],
      formsByRegID: Map<RegistrationID, RegFormElemContainer
    >}>(undefined, {defaultDebounce_ms: 200})
    const ready = ref(false)

    const lastKnownRouteParams = (() => {
      const lastKnownRouteParams = ref<LastKnownRouteParams>({
        [RouteNames.root]: undefined,
        [RouteNames.clientConfig]: undefined,
        [RouteNames.rate]: undefined,
        [RouteNames.report]: undefined,
      })

      const get = <K extends RouteName>(routeName: K) : LastKnownRouteParams[K] => lastKnownRouteParams.value[routeName]
      const set = <K extends RouteName>(routeName: K, q: LastKnownRouteParams[K]) : void => {
        lastKnownRouteParams.value[routeName] = q
      }

      return {
        get,
        set,
        get __debug() { return lastKnownRouteParams.value }
      }
    })();

    const tabDefs = computed<(TabDef & {id: TabID})[]>(() => {
      type TabDefWithID = TabDef & {id: TabID};

      const clientConfig : TabDefWithID = {
        id: TabID.clientConfig,
        "data-test": `tabButton/${TabID.clientConfig}`,
        label: "Rating Configuration",
        render: () => {
          assertNonNull(clientRatings.value)
          return <ClientRatingsConfigurator
            key={clientRatings.value.__renderKey}
            clientRatings={clientRatings.value.value}
            onSubmit={formElems => doUpdateClientConfig(formElems)}
          />
        }
      };

      const ratePlayer : TabDefWithID = {
        id: TabID.rate,
        "data-test": `tabButton/${TabID.rate}`,
        label: "Rate Players",
        render: () => {
          assertNonNull(clientRatings.value)
          return <RatePlayers
            clientRatings={clientRatings.value.value}
            playerRatings={playerRatings.underlying}
            q={readQueryParams(RouteNames.rate, router.currentRoute.value.query)}
            onUpdateQueryParams={q => {
              lastKnownRouteParams.set(RouteNames.rate, q)
              rebindURL("replace", q)

              if (!q) {
                return;
              }

              const {teamID, seasonUID, mode} = q
              if (!teamID || !seasonUID || !mode) {
                return;
              }

              playerRatings.run(async () => {
                const resolvedClientRatings = requireNonNull(clientRatings.value).value
                const ratings = await listPlayersForRatingsInput(axiosAuthBackgroundInstance, {teamID, seasonUID, mode});
                const formsByRegID = buildFormElemMapping(ratings, resolvedClientRatings)
                return {
                  seasonUID,
                  teamID,
                  ratingsMode: mode,
                  playerRatings: ratings,
                  formsByRegID,
                }
              })
            }}
            onSave={async () => doSavePlayerRatings()}
          />
        }
      };

      const report : TabDefWithID = {
        id: TabID.report,
        "data-test": `tabButton/${TabID.report}`,
        label: "Report",
        render: () => <PlayerRatingsReport
          q={readQueryParams(RouteNames.report, router.currentRoute.value.query)}
          onUpdateQueryParams={q => {
            lastKnownRouteParams.set(RouteNames.report, q)
            rebindURL("replace", q)
          }}
        />
      }

      return [
        authZ_canDefineRatings() ? clientConfig : null,
        authZ_canDoCoachPlayerRatings() || authZ_canDoRegionalPlayerRatings() ? ratePlayer : null,
        authZ_accessRatingsReport() ? report : null
      ].filter(v => !!v)
    })

    const tabIdxById = computed(() => {
      // partial because we are pulling from "tabDefs" which is "tabs the user can see",
      // which is not necessarily all the tabIDs we know about
      const result : Partial<Record<TabID, number>> = {}
      tabDefs.value.forEach((e,i) => {
        result[e.id] = i
      })
      return result;
    })
    const tabIdByIdx = computed(() => {
      const result : Record<number, TabID> = {}
      tabDefs.value.forEach((e,i) => {
        result[i] = e.id;
      })
      return result;
    })

    const rebindURL = async (which: "push" | "replace", q: Record<string, LocationQueryValueRaw> | undefined) : Promise<void> => {
      await withNoScroll(async () => {
        const tabID = requireNonNull(tabIdByIdx.value[selectedTabIdx.value])
        const action = which === "push" ? router.push : router.replace;
        await action({
          name: tabID2RouteName(tabID),
          query: {
            ...router.currentRoute.value.query,
            ...q
          }
        })
      })
    }

    const doUpdateClientConfig = async (_: ClientRatingFormEvent) : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        try {
          await crudClientRatings(axiosInstance, {
            rangeLow1: _.core.rangeLow as Integerlike, // caller should have guaranteed this is an intlike
            rangeHigh1: _.core.rangeHigh as Integerlike, // caller should have guaranteed this is an intlike
            2: mungeOne(_[2]),
            3: mungeOne(_[3]),
            4: mungeOne(_[4]),
            5: mungeOne(_[5]),
            6: mungeOne(_[6]),
            7: mungeOne(_[7]),
            8: mungeOne(_[8]),
            9: mungeOne(_[9]),
            10: mungeOne(_[10]),
          });

          clientRatings.value = {
            __renderKey: nextOpaqueVueKey(),
            value: await listClientRatings(axiosInstance),
          }

          await iziToast.success({message: "Ratings configuration updated."})
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      })

      function mungeOne(v: undefined | ClientRatingForm) : ClientRatingCrudRequest | undefined {
        if (!v) {
          return undefined;
        }
        else if (v.type === "numeric") {
          return v;
        }
        else if (v.type === "text") {
          return {
            type: "text",
            ratingID: v.ratingID,
            ratingText: v.ratingText,
            // first 2 should always be present
            textOpt_1: requireNonNull(v.textOpts[0]),
            textOpt_2: requireNonNull(v.textOpts[1]),
            // the rest of them might be missing, which is fine
            textOpt_3: v.textOpts[2]?.trim() || undefined,
            textOpt_4: v.textOpts[3]?.trim() || undefined,
            textOpt_5: v.textOpts[4]?.trim() || undefined,
            textOpt_6: v.textOpts[5]?.trim() || undefined,
            textOpt_7: v.textOpts[6]?.trim() || undefined,
            textOpt_8: v.textOpts[7]?.trim() || undefined,
          }
        }
        else {
          exhaustiveCaseGuard(v)
        }
      }
    }

    const doSavePlayerRatings = async () : Promise<void> => {
      assertIs(playerRatings.underlying.status, "resolved");

      const rowData = playerRatings.underlying.data.playerRatings
      const formsByRegID = playerRatings.underlying.data.formsByRegID
      const seasonUID = playerRatings.underlying.data.seasonUID
      const teamID = playerRatings.underlying.data.teamID
      const ratingsMode = playerRatings.underlying.data.ratingsMode

      const dirty = rowData.map((row) : FormBridge => {
        return {
          registrationID: row.registrationID,
          form: requireNonNull(formsByRegID.get(row.registrationID))
        }
      }).filter(v => {
        // n.b. retain any row that has any dirty value; we then send *all* values for that row (not just dirty values)
        return v.form.core.isDirty() || v.form.custom.some(v => v.isDirty())
      })

      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        try {
          await updatePlayerRatings(axiosInstance, {
            seasonUID: seasonUID,
            teamID: teamID,
            mode: ratingsMode,
            each: dirty.map(bridge2Submittable)
          })

          dirty.forEach(v => {
            v.form.core.commitLocalChanges()
            v.form.custom.forEach(v => v.commitLocalChanges())
          })

          // have to reload this here, we might have changed the 'hasSomeExistingCoreRatings' value,
          clientRatings.value = {
            __renderKey: nextOpaqueVueKey(),
            value: await listClientRatings(axiosInstance),
          }

          iziToast.success({message: "Player ratings updated."})
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      });

      // n.b. sends all values for the row (not just dirty values)
      function bridge2Submittable(v: FormBridge) : UpdatePlayerRatingsRequest["each"][number] {
        return {
          registrationID: v.registrationID,
          core: {
            ratingValue: v.form.core.ratingValue,
            comment: v.form.core.comment,
          },
          custom: v.form.custom.map((v) => {
            return {
              ratingID: v.ratingID,
              ratingValue: v.ratingValue
            }
          })
        }
      }

      type FormBridge = {registrationID: Guid, form: RegFormElemContainer}
    }

    onBeforeRouteUpdate((to, _from , next) => {
      const tabID = getRouteTabInfo(to.name as RouteName).tabID
      const tabIdx = tabIdxById.value[tabID]
      if (typeof tabIdx === "number" && selectedTabIdx.value !== tabIdx) {
        selectedTabIdx.value = tabIdx
      }
      next()
    })

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        clientRatings.value = {
          __renderKey: nextOpaqueVueKey(),
          value: await listClientRatings(axiosInstance),
        }

        const {tabID, routeName} = getRouteTabInfo(router.currentRoute.value.name as RouteName)
        selectedTabIdx.value = requireNonNull(tabIdxById.value[tabID]) // should always have a valid tabID here
        lastKnownRouteParams.set(routeName, readQueryParams(routeName, router.currentRoute.value.query))

        await rebindURL("replace", lastKnownRouteParams.get(routeName));

        ready.value = true;
      })
    })

    return () => {
      if (!ready.value) {
        return null;
      }

      return <div style="max-width:2000px;">
        <h3 class="mb-2">Player Ratings</h3>
        <Tabs
          selectedIndex={selectedTabIdx.value}
          onChangeSelectedIndex={idx => {
            selectedTabIdx.value = idx
            const tabID = tabIdByIdx.value[idx]
            const routeName = tabID2RouteName(tabID)
            const q = lastKnownRouteParams.get(routeName)
            rebindURL("push", q)
          }}
          tabDefs={tabDefs.value}
        />
      </div>
    }
  }
})

enum TabID {
  clientConfig = "clientConfig",
  rate = "rate",
  report = "report"
}

type LastKnownRouteParams = {
  [RouteNames.root]: undefined,
  [RouteNames.clientConfig]: undefined,
  [RouteNames.rate]: undefined | QueryParams_RatePlayers,
  [RouteNames.report]: undefined | QueryParams_PlayerRatingsReport,
}

function readQueryParams<K extends RouteName>(routeName: K, q: LocationQuery) : LastKnownRouteParams[K] {
  switch(routeName) {
    case RouteNames.rate: return queryParams_ratePlayers(q) as any
    case RouteNames.report: return queryParams_playerRatingsReport(q) as any
    default: return undefined;
  }
}

function tabID2RouteName(tabID: TabID) {
  return tabID === TabID.clientConfig
    ? RouteNames.clientConfig
    : tabID === TabID.rate
    ? RouteNames.rate
    : tabID === TabID.report
    ? RouteNames.report
    : exhaustiveCaseGuard(tabID)
}

function getRouteTabInfo(routeName: RouteName) {
  const tabID = (() => {
    switch (routeName) {
      case RouteNames.root:
        // "root" shouldn't happen, we should always mount as one of the possible child routes
        return TabID.rate
      case RouteNames.clientConfig:
        if (authZ_canDefineRatings()) {
          return TabID.clientConfig
        }
        else {
          return TabID.rate
        }
      case RouteNames.rate:
        return TabID.rate;
      case RouteNames.report:
        if (authZ_accessRatingsReport()) {
          return TabID.report
        }
        else {
          return TabID.rate
        }
      default: exhaustiveCaseGuard(routeName)
    }
  })();

  return {tabID, routeName: tabID2RouteName(tabID)}
}
