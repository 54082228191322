<template lang="pug">
div(data-test="PaymentToolsRoot")
  div(v-if="stripeCardElementReady" data-test="stripeCardElementReady")
    //-
    //- This div is intentionally empty
    //-
    //- stripeCardElementReady -- empty element that serves as a playwright hook;
    //- testing for this element's presence in DOM lets us know if stripe has completed its
    //- own mounting logic for its card element.
    //- We can't test directly for the stripe DOM element's presence because it must ALWAYS be present,
    //- even before being fully ready, because it is a container for stripe to place its own content into.
    //-
  .mt-4.m-2.px-2.py-2.border-2.border-solid.border-gray-300.rounded-lg.p-5(
    class='md:px-14 md:m-6 md:mt-6 md:py-6',
    v-if='paymentState && paymentState.requiresPaymentMethod'
  )
    h2.my-4.text-lg.leading-6.font-medium.text-gray-900
      component(:is="msg")
    .flex.flex-col.min-w-full
      .align-middle.inline-block.min-w-full.shadow.overflow-hidden.border-b.border-gray-200(
        class='sm:rounded-lg'
      )
        table.my-4.min-w-full.divide-y.divide-gray-200
          thead(v-if='paymentMethods.length')
            tr
              th.px-2.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider(
                class='md:px-6'
              )
                |
              th.px-2.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider(
                class='md:px-6'
              )
                |
              th.px-2.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider(
                class='md:px-6'
              )
                | Last 4 Digits
              th.px-2.py-3.bg-gray-50.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider.text-left(
                class='md:px-6'
              )
                | {{ exp }}
              th.px-2.py-3.bg-gray-50.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider.text-left(
                class='md:px-6'
              )
                | Remove Card
          tbody.bg-white.divide-y.divide-gray-200
            tr(v-for='paymentMethod in paymentMethods')
              td.px-2.py-4.whitespace-nowrap.text-sm.leading-5.font-medium.text-gray-900.flex.justify-center.items-center(
                class='md:px-6'
              )
                t-singleRadio.w-4(
                  :value='paymentMethod.id',
                  :modelValue='selectedCard',
                  @change='toggleCardSelected',
                  @input='toggleCardSelected'
                )
              td.px-2.py-4.whitespace-nowrap.leading-5.text-lg.text-gray-500(
                class='md:px-6'
              )
                p.text-sm.flex.items-center(
                  v-if='paymentMethod.card.brand === "visa"'
                )
                  font-awesome-icon.mr-2.text-lg(:icon='["fab", "cc-visa"]')
                  | Visa
                p.text-sm.flex.items-center(
                  v-else-if='paymentMethod.card.brand === "discover"'
                )
                  font-awesome-icon.mr-2.text-lg(
                    :icon='["fab", "cc-discover"]'
                  )
                  | Discover
                p.text-sm.flex.items-center(
                  v-else-if='paymentMethod.card.brand === "mastercard"'
                )
                  font-awesome-icon.mr-2.text-lg(
                    :icon='["fab", "cc-mastercard"]'
                  )
                  | Mastercard
                p.text-sm.flex.items-center(
                  v-else-if='paymentMethod.card.brand === "amex"'
                )
                  font-awesome-icon.mr-2.text-lg(:icon='["fab", "cc-amex"]')
                  | Amex
              td.px-2.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500(
                class='md:px-6'
              )
                | ...{{ paymentMethod.card.last4 }}
              td.px-2.py-4.whitespace-nowrap.text-left.text-sm.leading-5.font-medium(
                class='md:px-6'
              )
                | {{ paymentMethod.card.exp_month }}/{{ paymentMethod.card.exp_year }}
              td.px-6.py-4.whitespace-nowrap.text-left.text-sm.text-red-600.cursor-pointer.leading-5.font-medium(
                @click='deleteCard(paymentMethod)',
                class='md:px-14'
              )
                font-awesome-icon(:icon='["fas", "times-circle"]')
            tr
              td.px-2.py-4.whitespace-nowrap.text-right.text-sm.flex.justify-center.items-center.leading-5.font-medium.align-top(
                v-if='paymentMethods.length',
                class='md:px-6'
              )
                t-singleRadio.w-4(
                  value='addCard',
                  :modelValue='selectedCard',
                  @change='toggleCardSelected',
                  @input='toggleCardSelected'
                )
              td.text-sm(
                colspan=4,
                v-if='paymentMethods.length && selectedCard != "addCard"'
              ) Add Payment Method
              td(
                colspan='5',
                v-show='selectedCard === "addCard" || !Object.keys(paymentMethods).length'
              )
                //- save payment method option for non-tournament team registration payments
                //- whereas a tournament team registration payment will always retain the payment method
                t-checkbox.mt-3(
                  v-if="paymentState.entireInvoiceIs !== 'qTournamentTeam(teamReg)'"
                  label='Save payment method',
                  :modelValue='savePaymentMethod',
                  @input='toggleSavePaymentMethod',
                  data-cy='saveCard'
                )
                .grid.grid-cols-1.gap-4.mx-2
                  .border-solid.border-2.border-gray-200.rounded-md.px-2.mr-4(
                    class='md:mr-0'
                  )
                    .px-2.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500
                      | Credit Card
                      #card-element.mt-4(data-test='cardElement')
                      // A Stripe Element will be inserted here.
                      // Used to display form errors.
                      #card-errors(role='alert')
                  div
                    .px-2.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500.border-solid.border-2.border-gray-200.rounded-md(
                      class='md:px-6',
                      v-if='AppleOrGooglePay'
                    )
                      div {{ AppleOrGooglePay === "A" ? "Apple" : AppleOrGooglePay === "G" ? "Google" : "" }} Pay
                      .mx-15(v-if='AppleOrGooglePay === "G"')
                        img.mt-4.self-center(
                          :src='"/app/google-pay-mark.svg"',
                          data-cy='googlePay',
                          @click='showPaymentRequest'
                        )
                      .pr-40(v-if='AppleOrGooglePay === "A"')
                        #payment-request-button.mt-4
        .m-2.flex.flex-row.justify-between
          div
            div.ml-2.font-light {{ error }}
            t-btn.my-4.w-full.mr-8(
              :label="paymentButtonLabel",
              :disable='lockSubmit',
              @click='getCardPaymentMethodAndSubmitPayment',
              :margin='false',
              data-test='submitPayment'
            )
            div(
              class="text-sm font-medium mb-2"
              v-if="paymentState.entireInvoiceIs === 'qTournamentTeam(teamReg)'"
            )
              //- nothing at this time
          div(v-show="showPayWithCashOrCheckButton")
            div.ml-2.font-light {{ error }}
            t-btn.my-4.w-full.mr-8(
              :label="showPayWithCashOrCheckLabel",
              :disable='lockSubmit',
              @click='payByCashOrCheck()',
              :margin='false',
              data-test='payWithCashOrCheck-button'
            )

  .flex.flex-col.items-center.justify-center.px-6.py-2(v-else)
    //- important null guard; TODO: JSXify for flow analysis' sake
    template(v-if="paymentState")
      template(v-if="paymentState.isBlocked")
        //- we're blocked and do not require any payment info
        //- we currently expect that informational blurbs be put on the screen by some other component,
        //- generally the Checkout.vue component which will draw lineitem-state-specific content chunks.
      template(v-else)
        //- no fee, but not blocked, "paying" is OK but "just" finalizes the order.
        div There weren't any fees associated with this invoice. Click below to finalize this order.
        t-btn.mt-2.w-full(:margin='false' @click='submitPayment' data-test="submit-zero-fee")
          span.w-full.text-center Confirm
</template>

<script lang="tsx">
import { defineComponent, ref, computed, Ref, watch, onMounted, nextTick, CSSProperties, onBeforeUnmount } from 'vue'
import { useRouter, useRoute, RouteLocationRaw, onBeforeRouteLeave } from 'vue-router'


import { Screen } from 'quasar'
import {
  loadStripe,
  PaymentMethod,
  PaymentRequestPaymentMethodEvent,
  StripeCardElement,
  StripeCardNumberElement,
  Stripe as StripeInterface,
  StripeErrorType,
  StripeError,
} from '@stripe/stripe-js'

// todo: don't use aliases (just used them to make as small a changeset as possible when changing out types)
import { Event as EventObject, EventSignup as Signups } from "src/composables/InleagueApiV1.Event"
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import { v4 as uuidv4 } from 'uuid'
import { assertNonNull, assertTruthy, exhaustiveCaseGuard, parseFloatOr, parseFloatOrFail } from 'src/helpers/utils'
import { propsDef, emitsDef, maybeGetCompRegLineItems, maybeGetTournamentTeamRegLineItem, maybeGetEventSignups } from "./PaymentTools.ilx"
import { LineItem, LineItemSpecialization } from 'src/interfaces/Store/checkout'
import { AxiosInstance } from 'axios'
import { Guid, Integerlike } from 'src/interfaces/InleagueApiV1'
import * as ilapi from "src/composables/InleagueApiV1"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import * as ilpayemnts from "src/composables/InleagueApiV1.Payments"
import { GlobalInteractionBlockingRequestsInFlight, PayableInvoicesResolver } from "src/store/EventuallyPinia"
import type * as iltypes from "src/interfaces/InleagueApiV1"


import { tournamentTeamStore } from "src/components/Tournaments/Store/TournTeamStore"

import { System } from 'src/store/System'
import { Client } from 'src/store/Client'

import { PayInvoiceArgs } from 'src/composables/InleagueApiV1.Invoice'
import { isSubscriptionInvoice } from './InvoiceUtils'

export default defineComponent({
  name: 'PaymentTools',
  props: propsDef,
  emits: emitsDef,
  components: {
  },
  setup(props, {emit}) {

    const spk = ref(Client.value.stripePublicKey)

    const card = ref({}) as Ref<StripeCardElement>
    const stripeCardElementReady = ref(false);

    const stripe = ref<StripeInterface | null>(null)

    const paymentMethods = ref([]) as Ref<PaymentMethod[]>
    const stripeAccount = ref('')
    const selectedCard = ref('')
    const savePaymentMethod = ref(true)
    const lockSubmit = ref(false)
    const error = ref('')
    const enrolled = ref({}) as Ref<{ [key: string]: Signups }>
    const event = ref({}) as Ref<EventObject>

    const msg = computed(() => {
      const overallTotal = parseFloatOr(props.invoiceInstance.lineItemSum, null)?.toFixed(2) ?? props.invoiceInstance.lineItemSum;

      if (isSubscriptionInvoice(props.invoiceInstance)) {
        return <div>{props.paymentScheduleBlurb}</div>
      }
      else {
        return <div>Your total is: ${overallTotal}</div>
      }
    })

    const exp = ref('Expiration')
    const AppleOrGooglePay = ref(null) as Ref<null | string>
    const paymentRequest = ref<null | import('@stripe/stripe-js').PaymentRequest>(null)

    const route = useRoute()
    const router = useRouter()

    const hasSomePaymentBlockedLineItem = computed(() => {
      for (const lineItem of props.invoiceInstance.lineItems) {
        if (lineItem.paymentBlock_isBlocked) {
          return true;
        }
      }
      return false;
    });

    const submitPayment = async () : Promise<{ok: boolean} | {ok: false, message?: string}> => {
      lockSubmit.value = true;

      try {
        if (hasSomePaymentBlockedLineItem.value) {
          // TODO: this exclusively handles a compreg case, where "the whole compreg is blocked",
          // so should be refactored to be explicit about that.
          const paymentMethodID = selectedCard.value;
          const invoiceInstanceID = props.invoiceInstance.instanceID as Integerlike;
          return await props.attachTentativeStripePaymentMethodToInvoice({instanceID: invoiceInstanceID, paymentMethodID});
        }
        else {
          const result = await props.payInvoice(getPayInvoiceArgs());
          if (!result.ok) {
            error.value = result.message;
          }
          return result;
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        // hm, there WON'T be any NON-axios exceptions here right? Meaning, `rethrowIfNotAxiosError` will always rethrow here?
        // Because the invoked things return the equivalent of Either<OK,Err>, rather than throw axios errors.
        return {ok: false, message: "Sorry, something went wrong"}
      }
      finally {
        lockSubmit.value = false;
      }

      function getPayInvoiceArgs() : PayInvoiceArgs {
        return {
        instanceID: props.invoiceInstance.instanceID,
        idempotencyKey: uuidv4(),
        paymentMethodID: (() => {
            if (!paymentState.value) {
              // shouldn't happen
              return undefined
            }

            if (paymentState.value.entireInvoiceIs === "qTournamentTeam(teamReg)") {
              // for tournTeam teamReg invoices,
              // where teamReg is free but there is an associated non-zero holdPayment,
              // we want to enforce collection of the paymentMethod for the hold payment invoice,
              // before we allow the free registration invoice to be "paid".
              // The backend handles both at once when pushing "payment" for a tournteam reg invoice.
              return selectedCard.value;
            }

            const parsedSum = parseFloatOrFail(props.invoiceInstance.lineItemSum)
            if (parsedSum >= 0.01) {
              return selectedCard.value
            }

            return undefined;
          })(),
          discardCard: (() => {
            if (selectedCard.value === 'addCard' || !paymentMethods.value.length) {
              return !savePaymentMethod.value
            }
            else {
              return undefined;
            }
          })()
        };
      }
    }

    // mounts elements for both card and apple/google pay button
    const mountStripe = async () => {
      // Mount card form

      stripe.value = await loadStripe(spk.value, {
        stripeAccount: stripeAccount.value,
      })

      if (stripe.value) {
        const elements = stripe.value.elements()
        const style = {
          base: {
            color: '#32325d',
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#32325d',
            },
          },
          invalid: {
            fontFamily: 'Arial, sans-serif',
            color: '#fa755a',
            iconColor: '#fa755a',
          },
        }

        card.value = elements.create('card', { style: style })

        if (card.value.mount) {
          if (!document.querySelector("#card-element")) {
            // We didn't mount the card-element container, so there's no target to offer to stripe utils.
            // Presumably, we didn't mount the container because paymentState.requiresPaymentMethod is false
            return;
          }
          card.value.once("ready", () => {
            stripeCardElementReady.value = true;
          })
          card.value.mount('#card-element')
          // user clicks submit but there is an error
          // displaying $theErrorMessage ->
          // user types to try to fix the error ->
          // clear the message ->
          // user clicks submit (again) ->
          // make stripe request, reassign error.value if one was generated
          card.value.on("change", () => error.value = "");
        }

        // Mount apple/google pay button
        const paymentRequestLocal = stripe.value.paymentRequest({
          country: 'US',
          currency: 'usd',
          total: {
            label: `${Client.value.instanceConfig.shortname}`,
            amount: parseFloat(props.invoiceInstance.lineItemSum) * 100,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        })


        const result = await paymentRequestLocal.canMakePayment() as null | Record<"applePay" | "googlePay", boolean>

        if (result) {
          paymentRequest.value = paymentRequestLocal
          if (result.applePay) {
            AppleOrGooglePay.value = 'A'
            const prButton = elements.create('paymentRequestButton', {
              paymentRequest: paymentRequestLocal,
              style: {
                paymentRequestButton: {
                  height: '48px',
                },
              },
            })
            //
            // #payment-request-button is more like "apple-pay-payment-request-button" ?
            //
            // wait for page reflow in response to setting AppleOrGooglePay.value = 'A'
            // otherwise, the element identified by #payment-request-button is not present in DOM, and
            // asking to mount stripe stuff against it fails. Out of an abundance of caution we double await,
            // with intent to "redraw everything" (is it guaranteed that a single await will redraw, or could
            // we already be after that phase when we await the first nexttick)
            //
            // This might not solve the problem but it shouldn't make anything worse.
            //
            // todo: test that this 100% solves the issue
            // related: https://sentry.io/organizations/inleague-llc/issues/3768001648/?project=5661592
            //
            await nextTick();
            await nextTick();

            const targetElementSelector = '#payment-request-button'
            if (document.querySelector(targetElementSelector)) {
              // cool, we're still mounted, and the target element exists
              prButton.mount(targetElementSelector)
            }
            else {
              //
              // Presumably here we have been unmounted, maybe a user hit "back" while we were waiting on one
              // of the awaits above. If we don't guard this, stripe will throw an exception when trying to work with a DOM
              // element that doesn't exist.
              //
              // There's nothing we can do here.
              //
              // see: https://inleague-llc.sentry.io/issues/4404557090/?alert_rule_id=4761582&alert_timestamp=1692373867165&alert_type=email&environment=production&project=5661592&referrer=alert_email
              //
            }
          } else if (result.googlePay) {
            AppleOrGooglePay.value = 'G'
          }
        } else {
          // this was an assignment to a non-null-asserted lefthand side, but the non-null assertion was not correct (i.e. obj still falsy)
          // what does it mean if this element is not present?
          const maybePaymentRequestButton = document.getElementById('payment-request-button');
          if (maybePaymentRequestButton) {
            maybePaymentRequestButton.style.display = 'none'
          }
        }

        paymentRequestLocal.on(
          'paymentmethod',
          (evt: PaymentRequestPaymentMethodEvent) => {
            //// console.log('paymentMethod received', evt)
            if (evt.paymentMethod.id) {
              selectedCard.value = evt.paymentMethod.id
              submitPayment()
                .then(APIResponse => {
                  if (APIResponse.ok) {
                    evt.complete('success')
                  }
                  else {
                    evt.complete('fail')
                  }
                })
                .catch(err => {
                  // console.log('error', err)
                })
            } else {
              evt.complete('fail')
            }
          }
        )
      }
    }

    const showPaymentRequest = () => {
      // This is only for the GooglePay case, yeah?
      // It seems the ApplePay case will be triggered by some stripe callback responsible for the apple case
      // This is not called in the "default" stripe payment case.
      paymentRequest.value?.show()

      // This is duplicative w/ the handler already registered from within `mountStripe` right?
      // TODO: get reproducible tests, check that this would be (undesireably) fired twice (because we've registered 2 separate "onPaymentMethod" handlers)
      // paymentRequest.value.on(
      //   'paymentmethod',
      //   async (evt: PaymentRequestPaymentMethodEvent) => {
      //     if (evt.paymentMethod.id) {
      //       selectedCard.value = evt.paymentMethod.id
      //       await submitPayment().then(APIResponse => {
      //         if (APIResponse.ok) {
      //           evt.complete('success')
      //         }
      //         else {
      //           evt.complete('fail')
      //         }
      //       })
      //     } else {
      //       evt.complete('fail')
      //     }
      //   }
      // )
    }

    const getPaymentMethods = async () => {
      try {
        const r = await getStripePaymentMethodsForImplicitCurrentUser(axiosInstance, {paymentGatewayID: props.invoiceInstance.paymentGatewayID});

        stripeAccount.value = r.stripeAccount;
        paymentMethods.value = r.paymentMethods;

        if (paymentMethods.value.length > 0) {
          const currentUiSelectionIsPresentInResponse = !!paymentMethods.value.filter(pm => pm.id === selectedCard.value);
          if (!currentUiSelectionIsPresentInResponse) {
            selectedCard.value = paymentMethods.value[0].id;
          }
        }
      } catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const toggleCardSelected = (value: string) => {
      // console.log('in toggleCardSelected', value)
      selectedCard.value = value
    }

    const toggleSavePaymentMethod = (value: boolean) => {
      // console.log('vl', value)
      savePaymentMethod.value = value
    }

    const deleteCard = async (paymentMethod: PaymentMethod) => {
      try {
        const response = await axiosInstance.delete(
          `v1/payments/${props.invoiceInstance.paymentGatewayID}/paymentMethods/${paymentMethod.id}`
        )
        // console.log(response.data.data)
        await getPaymentMethods()
      } catch (err) {
        //// console.error(err)
      }
    }

    // IMPORTANT: This gets the payment number from stripe for cards only
    const getCardPaymentMethodAndSubmitPayment = async () : Promise<void> => {
      lockSubmit.value = true

      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        if (selectedCard.value === 'addCard' || !paymentMethods.value.length) {
          try {
            if (stripe.value) {
              const stripeCard = stripe.value
              const response = await stripeCard.createPaymentMethod({
                type: 'card',
                card: card.value,
              })
              if (isStripeError(response)) {
                error.value = response.error.message || "Sorry, something went wrong."
                lockSubmit.value = false;
                return;
              }
              //// console.log('stripe gave us info', response)
              else if (response.paymentMethod) {
                selectedCard.value = response.paymentMethod.id
              }
              else {
                // ? not an error, but no paymentMethod?
                // this code has been falling though, so we'll let it do that;
                // But probably can never get here?
              }
            }
          } catch (err: any) {
            //// console.log('error in purchase', err)
            error.value = err.response.message
            lockSubmit.value = false
            return
          }
        }
      })

      await submitPayment()
    }

    const payByCashOrCheck = () => {
      emit("payWithCashOrCheck");
    }

    /**
     * fixme: see propsDef for `total` -- it's undefined, and a string?
     */
    const mungedTotal = computed<number | null>(() => parseFloatOr(props.total, null));

    /**
     * Conceptually, an invoice can be composed of many heterogenous line item types,
     * like a compreg and 2 event signups and a tournament team registration all on one invoice.
     * But in practice, an invoice is always homegenous, with the exception of donation line items being included
     * on compreg invoices. This may change in the future; but for now this encodes the use case.
     *
     * `requiresPaymentMethod` and `isBlocked` are related but ultimately orthogonal:
     * requiresPaymentMethod=0 isBlocked=0 -> totally zero-fee invoice, click to finalize
     * requiresPaymentMethod=0 isBlocked=1 -> totally zero-fee invoice, can't finalize until block is removed (e.g. a waitlisted compreg)
     * requiresPaymentMethod=1 isBlocked=0 -> has-fee invoice, click to submit payment method and finalize
     * requiresPaymentMethod=1 isBlocked=1 -> has-fee invoice, need to collect payment method but cannot finalize
     */
    interface AggregateInvoiceTypeAndPaymentState {
      /**
       * whether we need to collect payment information, either to collect payment immediately or store for future use
       * (i.e. in the "is blocked" case)
       */
      requiresPaymentMethod: boolean,
      /**
       * whether we can positively attempt to pay/complete this invoice
       */
      isBlocked: boolean,
      entireInvoiceIs: "qEventSignup" | "qCompetitionRegistration" | "qTournamentTeam(teamReg)"
      // could be separate decomposed flags, but want to handle them all as a single enum
      status: "fee/blocked" | "fee/not-blocked" | "zero-fee/blocked" | "zero-fee/not-blocked"
    }

    const paymentState = computed<AggregateInvoiceTypeAndPaymentState | null>(() => {
      const result = maybeGetCompRegState()
        || maybeGetEventSignupState()
        || maybeGetTournamentTeamRegState();

      if (result) {
        return result;
      }
      else {
        // this represents one of a few possible scenarios:
        // - bug on our part (we didn't search properly for the line items?)
        // - the invoice has no associated line items, generally because the invoice was voided (why did we mount `PaymentTools` if that's the case?)
        // - maybe a data race? as in, `props.invoiceInstance` is currently `undefined`, which can happen if one of:
        //   - router.params.invoiceID is invalid, or not yet bound
        //   - request to load invoice has fired but has not yet resolved
        return null;
      }

      function maybeGetCompRegState() : AggregateInvoiceTypeAndPaymentState | null {
        const compRegLineItems = maybeGetCompRegLineItems(props.invoiceInstance);
        if (compRegLineItems) {
          assertTruthy(compRegLineItems.length > 0, "if we got an array it has at least one element");
          const hasFee : boolean = mungedTotal.value !== null && mungedTotal.value >= 0.01;
          const isBlocked : boolean = compRegLineItems.some(v => !!v.paymentBlock_isBlocked);
          return {
            requiresPaymentMethod: hasFee,
            isBlocked,
            entireInvoiceIs: "qCompetitionRegistration",
            status: quadStateZeroFeeAndBlocked(hasFee, isBlocked)
          }
        }
        return null;
      }

      function maybeGetEventSignupState() : AggregateInvoiceTypeAndPaymentState | null {
        const maybeEventSignups = maybeGetEventSignups(props.invoiceInstance);
        if (maybeEventSignups) {
          const hasFee : boolean = mungedTotal.value !== null && mungedTotal.value >= 0.01;
          // we don't currently block eventsignup invoice lineitems, but presumably we could at some point start doing so
          const isBlocked : boolean = !!maybeEventSignups.some(_ => _.paymentBlock_isBlocked);
          return {
            requiresPaymentMethod: hasFee,
            isBlocked,
            entireInvoiceIs: "qEventSignup",
            status: quadStateZeroFeeAndBlocked(hasFee, isBlocked)
          }
        }
        return null;
      }

      function maybeGetTournamentTeamRegState() : AggregateInvoiceTypeAndPaymentState | null {
        const maybeIsTournamentTeamReg = maybeGetTournamentTeamRegLineItem(props.invoiceInstance);
        if (maybeIsTournamentTeamReg) {
          if (props.tournamentTeamRegHoldPaymentInvoice === null) {
            throw Error("TournamentTeamReg invoice without associated hold payment invoice");
          }

          const hasRegFee : boolean = mungedTotal.value !== null && mungedTotal.value >= 0.01;
          const hasHoldPaymentFee = !!parseFloatOr(props.tournamentTeamRegHoldPaymentInvoice.lineItemSum, null)

          // right now it is not expected that a tournteamreg invoice is ever blocked, but let's not hardcode `blocked=false` here
          const isBlocked : boolean = !!maybeIsTournamentTeamReg.paymentBlock_isBlocked;

          return {
            // A tournament team registration requires payment info if it has a reg fee OR a hold payment fee,
            // where the hold payment fee is a separate invoice that may be
            // collected at some point the future, at a registrar's discretion, based on some
            // league based rules.
            requiresPaymentMethod: hasRegFee || hasHoldPaymentFee,
            isBlocked,
            entireInvoiceIs: "qTournamentTeam(teamReg)",
            status: quadStateZeroFeeAndBlocked(hasRegFee, isBlocked)
          }
        }
        return null;
      }

      function quadStateZeroFeeAndBlocked(hasFee: boolean, isBlocked: boolean) : AggregateInvoiceTypeAndPaymentState["status"] {
        if (!hasFee && !isBlocked) {
          return "zero-fee/not-blocked";
        }
        else if (!hasFee && isBlocked) {
          return "zero-fee/blocked"
        }
        else if (hasFee && !isBlocked) {
          return "fee/not-blocked";
        }
        else if (hasFee && isBlocked) {
          return "fee/blocked";
        }
        else {
          throw Error("unreachable");
        }
      }
    })

    const paymentButtonLabel = computed<string>(() => {
      const NO_BUTTON_SO_NO_LABEL = "";
      if (!paymentState.value) {
        return "";
      }

      switch (paymentState.value.entireInvoiceIs) {
        case "qCompetitionRegistration": {
          switch (paymentState.value.status) {
            case "fee/blocked":
              return "Submit payment information";
            case "fee/not-blocked":
              return "Submit payment";
            case "zero-fee/blocked":
              return NO_BUTTON_SO_NO_LABEL;
            case "zero-fee/not-blocked":
              return NO_BUTTON_SO_NO_LABEL;
            default: exhaustiveCaseGuard(paymentState.value.status);
          }
        }
        case "qEventSignup": {
          switch (paymentState.value.status) {
            case "fee/blocked":
              throw new Error("expected unreachable in the current qEventSignup case (event signups aren't ever marked blocked)")
            case "fee/not-blocked":
              return "Submit payment";
            case "zero-fee/blocked":
              throw new Error("expected unreachable in the current qEventSignup case (event signups aren't ever marked blocked)")
            case "zero-fee/not-blocked":
              return NO_BUTTON_SO_NO_LABEL;
            default: exhaustiveCaseGuard(paymentState.value.status);
          }
        }
        case "qTournamentTeam(teamReg)": {
          switch (paymentState.value.status) {
            case "fee/blocked":
              throw new Error("expected unreachable in the current qTournamentTeam(teamReg) case (tournament team registrations aren't ever marked blocked)")
            case "fee/not-blocked":
              return "Submit payment";
            case "zero-fee/blocked":
              throw new Error("expected unreachable in the current qTournamentTeam(teamReg) case (tournament team registrations aren't ever marked blocked)")
            case "zero-fee/not-blocked":
              return "Submit payment information";
            default: exhaustiveCaseGuard(paymentState.value.status);
          }
        }
        default: return exhaustiveCaseGuard(paymentState.value.entireInvoiceIs);
      }
    })

    onMounted(async () => {
      if (Screen.width < 768) {
        exp.value = 'Exp.'
      }

      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        await getPaymentMethods()
        await mountStripe()
      });
    })

    onBeforeUnmount(() => {
      // paranoiac safenav
      card.value?.unmount?.();
    })

    return {
      getCardPaymentMethodAndSubmitPayment,
      deleteCard,
      toggleSavePaymentMethod,
      toggleCardSelected,
      showPaymentRequest,
      mountStripe,
      submitPayment,
      router,
      route,
      AppleOrGooglePay,
      exp,
      msg,
      event,
      enrolled,
      error,
      lockSubmit,
      savePaymentMethod,
      selectedCard,
      stripeAccount,
      paymentMethods,
      stripe,
      card,
      spk,
      payByCashOrCheck,
      hasSomePaymentBlockedLineItem,
      paymentState,
      paymentButtonLabel,
      stripeCardElementReady,
    }
  },
})

const stripeErrorTypes = [
  'api_connection_error',
  'api_error',
  'authentication_error',
  'card_error',
  'idempotency_error',
  'invalid_request_error',
  'rate_limit_error',
  'validation_error'
] as readonly StripeErrorType[];

function isStripeError(v: any): v is { error: StripeError } {
  const errType = v?.error?.type;
  if (!errType) {
    return false;
  }
  for (const stripeErrorType of stripeErrorTypes) {
    if (errType === stripeErrorType) {
      return true;
    }
  }
  return false;
}

/**
 * user is specified via AxiosInstance bearerToken
 */
async function getStripePaymentMethodsForImplicitCurrentUser(ax: AxiosInstance, args: {paymentGatewayID: string}) {
  const response = await ax.get(`v1/payments/${args.paymentGatewayID}/paymentMethods`)
  return {
    stripeAccount: response.headers["x-stripeaccount"],
    paymentMethods: response.data.data
  }
}
</script>

<style scoped>
/* Variables */
* {
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}
form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}
input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
}
.result-message {
  line-height: 22px;
  font-size: 16px;
}
.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}
.hidden {
  display: none;
}
#card-error {
  color: rgb(105, 115, 134);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}
#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  width: 100%;
  background: white;
}
#payment-request-button {
  margin-bottom: 32px;
}
</style>
