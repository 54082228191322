/**
 * List out tournament teams, by (season -> comp)
 * Offer links to actions for the discovered tournament teams
 */

import { PropType, Ref, computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { FormKit } from "@formkit/vue";

import { AxiosErrorWrapper, axiosInstance } from "src/boot/axios";
import { EscapedRegExp, UiOption, checkedObjectAssign, flowCapture, useIziToast, isGuid, unsafe_objectKeys, parseIntOr, sortByDayJS, sortByMany, parseIntOrFail, useWatchLater, UiOptions } from "src/helpers/utils";


import * as iltypes from "src/interfaces/InleagueApiV1"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"

import { NavigationGuardWithThis, RouterLink, useRouter } from "vue-router";
import * as R_TournamentTeamConfigurator from "./R_TournamentTeamConfigurator.route";

import { hasCreateTournamentTeamRoutePermission, isAuthorizedToManageSomeTournament, isAuthorizedToManageTournament, teamDesignation, tournTeamStatusUiString } from "./TournamentTeamUtils"
import { tournamentTeamStore } from "./Store/TournTeamStore"

import * as R_TournamentTeamCreate from "./R_TournamentTeamCreate.route";

import type { ExpandedTournamentTeam } from "./TournamentTeamListing.utils"

import * as Modal from "src/components/UserInterface/Modal"
import { HoldPayment } from "./TournamentTeamListing.utils.holdPayment";
import { PayViaCheckFormData, PayViaCheckForm } from "src/components/Tournaments/TournamentTeamListing.utils.payViaCheckForm"

import * as R_MasterInvoice from 'src/components/Payment/pages/MasterInvoice.route'
import authService from "src/helpers/authService";

import { sortBy } from "src/helpers/utils"
import { User } from "src/store/User";
import { Client } from "src/store/Client";
import * as ClearOnLogout from "src/store/ClearOnLogout"
import { SelectManyPane, Props as SelectManyProps, OnEmits as SelectManyEmits, SlotProps as SelectManySlotProps } from "../RefereeSchedule/SelectManyPane";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ColDef, SortArrow, freshSortState, getColDefHtml } from "src/modules/TableUtils";
import { Menu, MenuItem } from "./Menu";
import { Paginated } from "src/modules/PaginationUtils";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { SimplePaginationNav } from "src/modules/PaginationElements";
import dayjs from "dayjs";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import { tournTeamListing_menu_competitions, tournTeamListing_menu_seasons } from "src/composables/InleagueApiV1.Tournament";
import { AxiosInstance } from "axios";
import { Competition, Guid, Season } from "src/interfaces/InleagueApiV1";
import { FilterManager, TournamentDisplayFilter, TournTeamsWithManagePerms } from "./TournTeamUiFilter";

const savedState = (() => {
  const selectedSeasonUID = ref<Guid | "">("");
  const selectedCompetitionUID = ref<Guid | "">("");

  const filterForm = ref(TournamentDisplayFilter())

  const clear = () : void => {
    selectedSeasonUID.value = "";
    selectedCompetitionUID.value = "";
    filterForm.value = TournamentDisplayFilter()
  }

  return {
    selectedSeasonUID,
    selectedCompetitionUID,
    filterForm,
    clear
  }
})()

ClearOnLogout.register(savedState);

const routeChangeHandler : NavigationGuardWithThis<undefined> = (_to, _from, next) => {
  const ok = hasCreateTournamentTeamRoutePermission() || isAuthorizedToManageSomeTournament();
  if (ok) {
    next();
  }
  else {
    next({name: "forbidden"})
  }
}

export const TournamentTeamListing = defineComponent({
  name: "R_TournamentTeamListing",
  props: {
    /**
     * "admin" - admin mode, some tournament manager doing things
     * "registrant" - registrant looking at their registered tourn teams
     */
    mode: {
      required: true,
      type: null as any as PropType<"admin" | "registrant">
    }
  },
  beforeRouteEnter: routeChangeHandler,
  beforeRouteUpdate: routeChangeHandler,
  setup(props) {
    const iziToast = useIziToast();
    const router = useRouter();
    const ready = ref(false);
    const seasons = ref<readonly iltypes.Season[]>([])
    const competitions = ref<readonly iltypes.Competition[]>([])
    const tournamentTeams = ref<TournTeamsWithManagePerms>({
      tournTeams: [] as readonly ExpandedTournamentTeam[],
      manageTournTeamPerms: {} as {[tournamentTeamID: iltypes.Integerlike]: undefined | 0 | 1}
    })

    const {
      selectedCompetitionUID,
      selectedSeasonUID,
      filterForm,
    } = savedState;

    const queryParams = {
      seasonUID: "suid",
      competitionUID: "cuid"
    } as const;
    const Q_SUID = queryParams.seasonUID;
    const Q_CUID = queryParams.competitionUID;

    enum ColName {
      createdDate = "createdDate",
      tournamentTeamName = "tournamentTeamName",
      region = "region",
      coaches = "coaches",
      referees = "referees",
      status = "status",
      division = "division",
      actions = "actions",
      teamLetter = "teamLetter",
    }
    const colDefs : ColDef<ExpandedTournamentTeam>[] = [
      {
        id: ColName.actions,
        label: "Actions",
        html: v => {
          const availableMenuItems : JSX.Element[] = []
          if (hasConfigureTeam()) {
            availableMenuItems.push(<ConfigureTeam/>)
          }

          if (hasCompleteThisRegistration()) {
            availableMenuItems.push(<CompleteThisRegistration/>)
          }

          if (hasApproveViaCheck()) {
            availableMenuItems.push(<ApproveViaCheck/>)
          }

          if (hasForceUpdateCheckInfo()) {
            availableMenuItems.push(<ForceUpdateCheckInfo/>)
          }

          if (hasApprove()) {
            availableMenuItems.push(<Approve/>)
          }

          if (hasDrop()) {
            availableMenuItems.push(<Drop/>)
          }

          if (hasCollectHoldPayment()) {
            availableMenuItems.push(<CollectHoldPayment/>)
          }

          const commonMenuButtonProps = {
            type: "button" as const,
            class: "hover:bg-slate-100 active:bg-slate-200 group flex w-full items-center rounded-md px-2 py-2 text-sm"
          }

          return (
            <div>
              {
                availableMenuItems.length > 0
                  ? (
                    <Menu data-test="actionsMenu" anchor="left">
                      {{
                        button: () => (
                          <t-btn type="button" margin={false} data-test="actions">
                            {/*negative margin to adjust for padding, that we cannot seem to remove?*/}
                            <FontAwesomeIcon class="-ml-2" icon={faEllipsisVertical}/>
                            <span class="ml-2">Actions</span>
                          </t-btn>
                        ),
                        menuItems: () => <>{availableMenuItems}</>
                      }}
                    </Menu>
                  )
                  : <span class="text-sm">No actions available</span>
              }
            </div>
          );

          function hasConfigureTeam() : boolean {
            switch (v.status) {
              case "PENDING":
              case "PAID_AWAITING_APPROVAL":
              case "APPROVED":
                // here, offer it to anyone who can manage it
                return !!tournamentTeams.value.manageTournTeamPerms[v.tournamentTeamID]
              default:
                return false;
            }
          }
          function ConfigureTeam() {
            return (
              <MenuItem>
                <RouterLink to={R_TournamentTeamConfigurator.routeDetailToRouteLocation({tournamentTeamID: v.tournamentTeamID})}>
                  <button {...commonMenuButtonProps} data-test="configure">
                    Configure team
                  </button>
                </RouterLink>
              </MenuItem>
            )
          }

          function hasCompleteThisRegistration() {
            switch (v.status) {
              case "PENDING":
                return isAdminForTournTeam(v)
              default:
                return false;
            }
          }
          function CompleteThisRegistration() {
            return (
              <MenuItem>
                <RouterLink to={R_TournamentTeamCreate.routeDetailToRouteLocation({name: R_TournamentTeamCreate.RouteNames.Questions, tournamentTeamID: v.tournamentTeamID})}>
                  <button {...commonMenuButtonProps}>
                    Complete this registration
                  </button>
                </RouterLink>
              </MenuItem>
            )
          }

          function hasApproveViaCheck() {
            switch (v.status) {
              case "PENDING":
                // might want to ensure that there is an existing invoice (even if zero fee), which serves as an proxy indicator for
                // "registrant got all the way through registration and created an invoice but hasn't paid yet"
                return isAdminForTourn(v.competitionUID)
              default: return false;
            }
          }
          function ApproveViaCheck() {
            return (
              <MenuItem>
                <button {...commonMenuButtonProps} data-test="payViaCheck" onClick={() => payViaCheckModal.open(v)}>
                  <span>Approve via check payment</span>
                </button>
              </MenuItem>
            )
          }

          function hasForceUpdateCheckInfo() {
            // a registrar (not a tourn manager) can force update the check info, maybe to fix typos or etc.
            // we could support tourn managers too, just need to also tweak backend perms
            if (v.payViaCheck_checkNo) {
              return authService(User.value.roles, "registrar")
            }
            return false;
          }
          function ForceUpdateCheckInfo() {
            return (
              <MenuItem>
                <button {...commonMenuButtonProps} data-test="forceUpdatePayViaCheck" onClick={() => forceUpdatePayViaCheckModal.open(v)}>
                  <span>Update check payment details</span>
                </button>
              </MenuItem>
            )
          }

          /*
          ? <span class="text-blue-700 underline cursor-pointer" data-test="forceUpdatePayViaCheck" onClick={() => forceUpdatePayViaCheckModal.open(tournTeam)}>Paid via check {tournTeam.payViaCheck_checkNo}</span>
                : <span>Paid via check {tournTeam.payViaCheck_checkNo}</span>
              : null
          */
          function hasDrop() {
            switch (v.status) {
              case "DROPPED_BY_HOST":
                // fallthrough
              case "DROPPED_BY_SUBMITTER":
                return false;
              default:
                return isAdminForTourn(v.competitionUID) || isAdminForTournTeam(v)
            }
          }
          function Drop() {
            return (
              <MenuItem>
                <button {...commonMenuButtonProps} onClick={() => dropTournamentTeamModal.open(v)}>
                  <span>Drop</span>
                </button>
              </MenuItem>
            )
          }

          function hasApprove() {
            switch (v.status) {
              case "PAID_AWAITING_APPROVAL":
                return isAdminForTourn(v.competitionUID)
              default:
                return false;
            }
          }
          function Approve() {
            return (
              <MenuItem>
                <button {...commonMenuButtonProps} onClick={() => approvalModal.open(v)}>
                  <span>Approve</span>
                </button>
              </MenuItem>
            )
          }

          function hasCollectHoldPayment() {
            switch (v.status) {
              case "APPROVED":
                return isAdminForTourn(v.competitionUID)
              default:
                return false;
            }
          }
          function CollectHoldPayment() {
            return (
              <MenuItem>
                <button {...commonMenuButtonProps} data-test="collectHoldPayment" onClick={() => collectHoldPaymentModal.open(v)}>
                  <span>Charge referee deposit</span>
                </button>
              </MenuItem>
            )
          }
        },
        xlsx: "never"
      },
      {
        id: ColName.division,
        label: "Division",
        html: v => `${v.div_gender}${v.div_divNum}`,
        sort: {
          cb: sortByMany(sortBy(_ => parseIntOr(_.div_divNum, -1)), sortBy(_ => _.div_gender)),
          dir: "not-sorted"
        }
      },
      {
        id: ColName.region,
        label: "Region",
        html: v => v.team.region.toString(),
        sort: {
          cb: sortBy(_ => parseIntOr(_.team.region, -1)),
          dir: "not-sorted",
        }
      },
      {
        id: ColName.teamLetter,
        label: "Team letter",
        html: v => v.mungedTeamLetter.id,
        sort: {
          cb: sortBy(_ => _.mungedTeamLetter.id),
          dir: "not-sorted",
        }
      },
      {
        id: ColName.tournamentTeamName,
        label: "Tournament team name",
        html: v => v.areaTeam.teamname,
        sort: {
          cb: sortBy(_ => _.areaTeam.teamname),
          dir: "not-sorted"
        },
      },
      {
        id: ColName.createdDate,
        label: "Date created",
        html: v => {
          const d = dayjs(v.dateCreated)
          const date = d.format("M/DD/YY")
          const time = d.format("h:mm a")
          return <div>
            <div>{date}</div>
            <div class="text-xs">{time}</div>
          </div>
        },
        sort: {
          cb: sortByDayJS(_ => dayjs(_.dateCreated)),
          dir: "desc"
        },
      },
      {
        id: ColName.coaches,
        label: "Coaches",
        html: v => {
          const headCoaches = v.coachTournTeamOfficials.filter(v => v.title === "Head Coach")
          const coCoaches = v.coachTournTeamOfficials.filter(v => v.title === "Co-Coach")

          if (headCoaches.length === 0 && coCoaches.length === 0) {
            return <div class="text-sm">N/A</div>
          }

          return (
            <div class="text-sm">
              <div class="text-xs"><u>Head&nbsp;Coaches</u></div>
              {
                headCoaches.length > 0
                  ? headCoaches.map(c => <div data-test={`headCoach/userID=${c.userID}`}>{c.firstName} {c.lastName}</div>)
                  : <div>N/A</div>
              }

              <div class="text-xs"><u>Asst.&nbsp;Coaches</u></div>
              {
                coCoaches.length > 0
                  ? coCoaches.map(c => <div>{c.firstName} {c.lastName}</div>)
                  : <div>N/A</div>
              }
            </div>
          )
        }
      },
      {
        id: ColName.referees,
        label: "Referees",
        html: v => {
          const refs = v.refTournTeamOfficials
          if (refs.length === 0) {
            return <div class="text-sm">N/A</div>
          }
          return <div>
            {refs.map(ref => {
              return <div class="text-sm" data-test={`ref/userID=${ref.userID}`}>
                {ref.firstName} {ref.lastName}
              </div>
            })}
          </div>
        }
      },
      {
        id: ColName.status,
        label: "Status",
        html: v => {
          return (
            <>
              <div>{tournTeamStatusUiString(v)}</div>
              {
                v.payViaCheck_checkNo
                  ? <div>Paid via check {v.payViaCheck_checkNo}</div>
                  : null
              }
              {
                (isAdminForTourn(v.competitionUID) || isAdminForTournTeam(v)) && v.invoiceInstanceID_registration
                  ? (
                    <RouterLink data-test="invoice-link" to={R_MasterInvoice.routeDetailToRoutePath({name: "master-invoice", invoiceID: v.invoiceInstanceID_registration})} {...{target:"_blank"}}>
                      <div class="text-xs il-link">Registration&nbsp;invoice&nbsp;({v.invoiceInstanceID_registration})</div>
                    </RouterLink>
                  )
                  : null
              }
            </>
          )
        },
        xlsx: v => tournTeamStatusUiString(v),
        sort: {
          cb: sortBy(tournTeamStatusUiString),
          dir: "not-sorted"
        }
      },
    ]

    const itemsPerPageOptions : UiOption[] = [
      {label: "All", value: "ALL"},
      {label: "25", value: "25"},
      {label: "50", value: "50"},
    ]
    const itemsPerPage = ref(itemsPerPageOptions[0].value);

    const sortState = reactive(freshSortState(colDefs))
    // initial sort config as follows
    sortState.reconfigure([
      {colID: ColName.division, dir: "asc"},
      {colID: ColName.region, dir: "asc"},
      {colID: ColName.teamLetter, dir: "asc"}
    ])

    const menuLoader = MenuLoaderFactory()
    const filterManager = FilterManager(filterForm, tournamentTeams);
    const filteredSortedRows = computed(() => [...filterManager.filteredTournTeams].sort(sortState.asSorter()))
    const pagination = computed(() => Paginated(parseIntOr(itemsPerPage.value, "ALL"), filteredSortedRows))

    const seasonOptions = computed<UiOptions>(() => {
      if (seasons.value.length === 0) {
        return {
          disabled: true,
          options: [{label: "No available options", value: ""}]
        }
      }
      else {
        return {
          disabled: false,
          options: seasons.value.map(season => ({label: season.seasonName, value: season.seasonUID}))
        }
      }
    });

    const competitionOptions = computed<UiOptions>(() => {
      if (selectedSeasonUID.value === "") {
        return {
          disabled: true,
          options: [{label: "Select a season", value: ""}]
        }
      }
      if (competitions.value.length === 0) {
        return {
          disabled: true,
          options: [{label: "No available options", value: ""}]
        }
      }
      else {
        return {
          disabled: false,
          options: competitions.value.map(comp => ({label: comp.competition, value: comp.competitionUID}))
        }
      }
    })

    /**
     * if a competition and season are selected, then a record of both those values; otherwise, null.
     */
    const compSeasonSelection = computed<null | {competitionUID: iltypes.Guid, seasonUID: iltypes.Guid}>(() => {
      return selectedCompetitionUID.value && selectedSeasonUID.value
        ? {competitionUID: selectedCompetitionUID.value, seasonUID: selectedSeasonUID.value}
        : null;
    })

    const doRefreshMenuCompetitions = async ({seasonUID}: {seasonUID: Guid | ""}) => {
      if (seasonUID) {
        competitions.value = await menuLoader.getMenuCompetitions({seasonUID})
      }
      else {
        competitions.value = []
      }

      if (!competitions.value.find(c => c.competitionUID === selectedCompetitionUID.value)) {
        // first non-nil option
        selectedCompetitionUID.value = competitionOptions.value.options.find(v => !!v.value)?.value ?? ""
      }
    }

    const updateQueryString = () => {
      router.replace({
        ...router.currentRoute.value,
        query: {
          ...router.currentRoute.value.query,
          [Q_SUID]: selectedSeasonUID.value || undefined,
          [Q_CUID]: selectedCompetitionUID.value || undefined,
        }
      })
    }

    const watchers = {
      // TODO: watcher direction should be 1 way, __into__ the query param, and we read from it once, on page mount
      queryParamSeasonUID: useWatchLater(() => router.currentRoute.value.query[Q_SUID], () => {
        if (typeof router.currentRoute.value.query[Q_SUID] === "string" && isGuid(router.currentRoute.value.query[Q_SUID])) {
          const routerSeasonUID = router.currentRoute.value.query[Q_SUID].toUpperCase();
          if (seasonOptions.value.options.find(v => v.value === routerSeasonUID)) {
            selectedSeasonUID.value = router.currentRoute.value.query[Q_SUID].toUpperCase();
          }
          else {
            selectedSeasonUID.value = ""
          }
        }
      }, {immediate: true}),
      // TODO: watcher direction should be 1 way, __into__ the query param, and we read from it once, on page mount
      queryParamCompetitionUID: useWatchLater(() => router.currentRoute.value.query[Q_CUID], () => {
        if (typeof router.currentRoute.value.query[Q_CUID] === "string" && isGuid(router.currentRoute.value.query[Q_CUID])) {
          const routerCompetitionUID = router.currentRoute.value.query[Q_CUID].toUpperCase();
          if (competitionOptions.value.options.find(v => v.value === routerCompetitionUID)) {
            selectedCompetitionUID.value = router.currentRoute.value.query[Q_CUID].toUpperCase();
          }
          else {
            selectedCompetitionUID.value = ""
          }
        }
      }, {immediate: true}),
      // when selected season changes, load in the appropriate competitions
      // TODO: don't use a watch, just inline this as necessary after relevant assignments
      selectedSeasonUID: useWatchLater(() => selectedSeasonUID.value, async () => {
        doRefreshMenuCompetitions({seasonUID: selectedSeasonUID.value})
        updateQueryString();
      }, {immediate: true}),
      // when a (comp, season) is fully selected, load in the appropriate tournteams
      // TODO: don't use a watch, just inline this as necessary after relevant assignments
      fullSelectionWatcher: useWatchLater(() => compSeasonSelection.value, async () => {
        if (compSeasonSelection.value) {
          const {competitionUID, seasonUID} = flowCapture(compSeasonSelection.value);
          await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
            const tournTeams = (await tournamentTeamStore.getTournamentTeams(seasonUID)).filter(team => team.competitionUID === competitionUID);
            const manageTournTeamPerms = await tournamentTeamStore.getIsAuthorizedToManageTournamentTeamsPermissionsMap(axiosInstance, {tournamentTeamIDs: tournTeams.map(_ => _.tournamentTeamID)});
            tournamentTeams.value = {
              tournTeams,
              manageTournTeamPerms
            }
          })
        }
        else {
          tournamentTeams.value = {
            tournTeams: [],
            manageTournTeamPerms: {}
          }
        }
        updateQueryString();
      }, {immediate: true})
    } as const;

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          seasons.value = await menuLoader.getMenuSeasons(axiosInstance)

          watchers.queryParamSeasonUID.start()

          if (selectedSeasonUID.value === "") {
            // first non-nil option
            selectedSeasonUID.value = seasonOptions.value.options.find(v => !!v.value)?.value ?? ""
          }

          await doRefreshMenuCompetitions({seasonUID: selectedSeasonUID.value})

          watchers.queryParamCompetitionUID.start()

          watchers.selectedSeasonUID.start()

          watchers.fullSelectionWatcher.start()

          ready.value = true;
      })
    })

    const approvalModal = reactive((() => {
      const busy = ref(false);

      const onCloseCB = (cb: () => void) : void => {
        if (busy.value) {
          return;
        }
        else {
          cb();
        }
      };

      const doApprove = async (tournamentTeam: ExpandedTournamentTeam) : Promise<void> => {
        try {
          try {
            busy.value = true;
            const fresh = await iltournament.approveTournamentTeam(axiosInstance, {tournamentTeamID: tournamentTeam.tournamentTeamID})
            tournamentTeam.status = fresh.status;
          }
          finally {
            busy.value = false;
          }
          tournamentTeam.status = "APPROVED";
          approvalModal.close();
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      }

      return Modal.DefaultModalController<ExpandedTournamentTeam>({
        title: () => (
          <>
            <div>Approve this team?</div>
            <div class="my-2 border-b border-slate-200"/>
          </>
        ),
        content: tournamentTeam => {
          if (!tournamentTeam) {
            return null;
          }
          return (
            <>
              <div>
                <div class="flex gap-4">
                  <t-btn margin={false} onClick={() => doApprove(tournamentTeam)}>Yes</t-btn>
                  <t-btn margin={false} color="red" onClick={() => approvalModal.close()}>No</t-btn>
                </div>
              </div>
              {
                busy.value ? <Modal.DefaultTinySoccerballBusyOverlay color={Client.value.clientTheme.color} /> : null
              }
            </>
          )
        }
      }, {onCloseCB})
    })());

    const collectHoldPaymentModal = reactive((() => {
      const requestsInFlight = ref(0);
      const busy = computed(() => requestsInFlight.value > 0);

      const onCloseCB = (cb: () => void) => {
        if (busy.value) {
          return;
        }
        else {
          cb();
        }
      }

      return Modal.DefaultModalController<ExpandedTournamentTeam>({
        title: () => (
          <>
            <div>Charge referee deposit</div>
            <div class="my-2 border-b border-slate-200"/>
          </>
        ),
        content: tournamentTeam => {
          if (!tournamentTeam) {
            return null;
          }
          return (
            <>
              <HoldPayment
                tournamentTeam={tournamentTeam}
                onCancel={() => collectHoldPaymentModal.close()}
                onRequestComplete={() => {requestsInFlight.value -= 1}}
                onRequestInFlight={() => {requestsInFlight.value += 1}}
                onComplete={() => { collectHoldPaymentModal.close(); }}
              />
              {
                busy.value
                  ? <Modal.DefaultTinySoccerballBusyOverlay color={Client.value.clientTheme.color}/>
                  : null
              }
            </>
          )
        }
      }, {onCloseCB});
    })());

    const dropTournamentTeamModal = reactive((() => {
      const busy = ref(false);

      const onCloseCB = (cb: () => void) : void => {
        if (busy.value) {
          return;
        }
        else {
          cb();
        }
      };

      const doDrop = async (tournamentTeam: ExpandedTournamentTeam) : Promise<void> => {
        try {
          try {
            busy.value = true;
            const fresh = await iltournament.dropTournamentTeam(axiosInstance, {tournamentTeamID: tournamentTeam.tournamentTeamID})
            tournamentTeam.status = fresh.status;
          }
          finally {
            busy.value = false;
          }
          dropTournamentTeamModal.close();
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      }

      return Modal.DefaultModalController<ExpandedTournamentTeam>({
        title: () => (
          <>
            <div>Drop tournament team</div>
            <div class="my-2 border-b border-slate-200"/>
          </>
        ),
        content: tournamentTeam => {
          if (!tournamentTeam) {
            return null;
          }
          return (
            <>
              <div>
                <div class="mb-2">Drop {teamDesignation(tournamentTeam)}?</div>
                <div class="flex gap-4">
                  <t-btn margin={false} onClick={() => doDrop(tournamentTeam)}>Yes</t-btn>
                  <t-btn margin={false} color="red" onClick={() => dropTournamentTeamModal.close()}>No</t-btn>
                </div>
              </div>
              {
                busy.value ? <Modal.DefaultTinySoccerballBusyOverlay color={Client.value.clientTheme.color} /> : null
              }
            </>
          )
        }
      }, {onCloseCB})
    })());

    const payViaCheckModal = reactive((() => {
      const busy = ref(false);

      const onCloseCB = (cb: () => void) : void => {
        if (busy.value) {
          return;
        }
        else {
          cb();
        }
      };

      const formData_ = ref(PayViaCheckFormData.fresh())
      const onOpenCB = () => {
        formData_.value = PayViaCheckFormData.fresh();
      }

      const doPayViaCheck = async (tournamentTeam: ExpandedTournamentTeam, data: PayViaCheckFormData) : Promise<void> => {
        const checkNo = flowCapture(data.checkNo);
        try {
          try {
            busy.value = true;
            const fresh = await iltournament.payViaCheck(axiosInstance, {tournamentTeamID: tournamentTeam.tournamentTeamID, checkNo: data.checkNo, comments: data.comments})
            checkedObjectAssign(tournamentTeam, fresh);
          }
          finally {
            busy.value = false;
          }
          iziToast.success({message: `Marked approved via check ${checkNo}`})
          payViaCheckModal.close();
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      }

      return Modal.DefaultModalController<ExpandedTournamentTeam>({
        title: () => (
          <>
            <div>Approve this tournament via a check payment:</div>
            <div class="my-2 border-b border-slate-200"/>
          </>
        ),
        content: tournamentTeam => {
          if (!tournamentTeam) {
            return null;
          }
          return (
            <>
              <PayViaCheckForm key={tournamentTeam.tournamentTeamID}
                tournamentTeam={tournamentTeam}
                confirm={() => doPayViaCheck(tournamentTeam, formData_.value)}
                cancel={() => payViaCheckModal.close()}
                mut_formData={formData_.value}
              />
              {
                busy.value ? <Modal.DefaultTinySoccerballBusyOverlay color={Client.value.clientTheme.color} /> : null
              }
            </>
          )
        }
      }, {onCloseCB, onOpenCB})
    })());

    const forceUpdatePayViaCheckModal = reactive((() => {
      const busy = ref(false);

      const onCloseCB = (cb: () => void) : void => {
        if (busy.value) {
          return;
        }
        else {
          cb();
        }
      };

      const formData_ = ref(PayViaCheckFormData.fresh())
      const onOpenCB = (data: ExpandedTournamentTeam) => {
        formData_.value = PayViaCheckFormData.freshFromExisting(data);
      }

      const doForceUpdatePayViaCheck = async (tournamentTeam: ExpandedTournamentTeam, data: PayViaCheckFormData) : Promise<void> => {
        try {
          try {
            busy.value = true;
            const fresh = await iltournament.forceUpdatePayViaCheck(axiosInstance, {tournamentTeamID: tournamentTeam.tournamentTeamID, checkNo: data.checkNo, comments: data.comments})
            checkedObjectAssign(tournamentTeam, fresh);
          }
          finally {
            busy.value = false;
          }
          iziToast.success({message: "Changes saved."})
          forceUpdatePayViaCheckModal.close();
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      }

      return Modal.DefaultModalController<ExpandedTournamentTeam>({
        title: () => (
          <>
            <div>Force update paid-via-check info</div>
            <div class="my-2 border-b border-slate-200"/>
          </>
        ),
        content: tournamentTeam => {
          if (!tournamentTeam) {
            return null;
          }
          return (
            <>
              <PayViaCheckForm key={tournamentTeam.tournamentTeamID}
                tournamentTeam={tournamentTeam}
                confirm={() => doForceUpdatePayViaCheck(tournamentTeam, formData_.value)}
                cancel={() => forceUpdatePayViaCheckModal.close()}
                mut_formData={formData_.value}
              />
              {
                busy.value ? <Modal.DefaultTinySoccerballBusyOverlay color={Client.value.clientTheme.color} /> : null
              }
            </>
          )
        }
      }, {onCloseCB, onOpenCB})
    })());

    const isAdminForTourn = (competitionUID: iltypes.Guid) : boolean => {
      return isAuthorizedToManageTournament(competitionUID, User.value);
    }

    const isAdminForTournTeam = (v: ExpandedTournamentTeam) : boolean => v.admin.some(admin => admin.userID === User.value.userID);

    /**
     * We have here a unfortunate workaround for overflow-x/overflow-y not playing nice together inside a div->table->menu->menuItems
     * Basically we want:
     *  - a div container D with some max width, with overflow-x:auto (scroll when there's overflow)
     *  - a table T inside D that might overflow the container and trigger scrolling
     *  - a menu inside each row of T that is absolute with respect to it's <td> and that:
     *    - is positioned visibily on top of D, overflowing D if necessary (not causing scroll)
     *
     * But setting D to overflow-x:auto seems to force overflow-y:scroll? Or something like that.
     * So as a workaround we observe relevant box dim changes and manually update as necessary. The end result is not quite
     * as pretty as a "purely hovering menu", but it's better than the menu appearing in a position that requires D to be scrolled
     * to make it visible.
     *
     * maybe related: https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue
     */
    const tableContainerRef = ref<HTMLElement | undefined>();
    let observer : MutationObserver | null = null;
    onUnmounted(() => observer?.disconnect())
    watch (() => tableContainerRef.value, () => {
      const targetNode = tableContainerRef.value;
      if (!targetNode) {
        return;
      }

      // disconnect the old observer if it exists
      observer?.disconnect();
      observer = null;

      const config : MutationObserverInit = { attributes: true, childList: true, subtree: true };
      let lastKnownHeight : string | null = null

      const callback : MutationCallback = () => {
        if (!tableContainerRef.value) {
          // should be defined by this point
          return;
        }

        const someMenuIsOpen = !!targetNode.querySelector(`[data-MenuItems]`)

        if (someMenuIsOpen) {
          if (lastKnownHeight === tableContainerRef.value.style.height) {
            // break the cycle of infintely triggering mutation observer callbacks on changing the height,
            // where each iteration adds bottom padding
            return;
          }
          if (tableContainerRef.value.scrollHeight === tableContainerRef.value.clientHeight) {
            // no need to adjust it, it's already the appropriate size
            // (is this enough to make the `lastKnownHeight` check redundant?)
            return;
          }

          const scrollHeightPx = tableContainerRef.value.scrollHeight + "px"
          tableContainerRef.value.style.height = `calc(2em + ${scrollHeightPx})`;
          lastKnownHeight = tableContainerRef.value.style.height;
        }
        else {
          tableContainerRef.value.style.height = "";
          lastKnownHeight = null;
        }
      };

      observer = new MutationObserver(callback);
      observer.observe(targetNode, config);
    })

    return () => {
      if (!ready.value) {
        return null;
      }

      return (
        <div style="--fk-bg-input: white;">
          <Modal.AutoModal controller={approvalModal}/>
          <Modal.AutoModal controller={collectHoldPaymentModal}/>
          <Modal.AutoModal controller={dropTournamentTeamModal}/>
          <Modal.AutoModal controller={payViaCheckModal} data-test="payViaCheckModal"/>
          <Modal.AutoModal controller={forceUpdatePayViaCheckModal} data-test="forceUpdatePayViaCheckModal"/>

          <div>
            <FormKit type="select" disabled={seasonOptions.value.disabled} options={seasonOptions.value.options} v-model={selectedSeasonUID.value} label="Season" validation={[["required"]]} data-test="seasonUID"/>
            <FormKit type="select" disabled={competitionOptions.value.disabled} options={competitionOptions.value.options} v-model={selectedCompetitionUID.value} label="Program" validation={[["required"]]} data-test="competitionUID"/>
          </div>


          <div>
            <div class="max-w-xl">
              <SelectManyPane {...filterManager.selectManyPropsAndHandlers}>
                {
                  ({isOpen, open}: SelectManySlotProps["default"]) => {
                    return (
                      <div>
                        <span
                          onClick={() => open()}
                          class={`text-blue-700 cursor-pointer underline bg-white ${isOpen.value ? 'bg-slate-200' : 'hover:bg-slate-100 active:bg-slate-200'}`}
                          style="border-radius:50%; padding:.5em; margin-left:-.5em;"
                        >
                          <FontAwesomeIcon {...{style: "outline:none;"}} icon={faListCheck} v-tooltip={{content: 'Select multiple'}}/>
                        </span>
                        <span class="ml-1" >{filterManager.statusOptionsMessage}</span>
                      </div>
                    )
                  }
                }
              </SelectManyPane>
            </div>
          </div>
          <div ref={tableContainerRef} class="mt-4">
            <table class="bg-white"
              style={{
                borderCollapse: "collapse",
                borderRadius: "6px",
                borderStyle: "hidden", // rounded border stuff
                boxShadow: "0 0 0 1px #DDD", // rounded border stuff
                width: "100%",
                maxWidth: "1800px",
              }}
            >
              <tr>
                {
                  colDefs.map(colDef => {
                    return (
                      <th class="p-2 text-left whitespace-nowrap">
                        <div class="flex items-center text-sm">
                          <span>{colDef.label}</span>
                          {
                            ((sorter?: typeof sortState.sortersByColID["<someid>"]) => {
                              return sorter
                                ? (
                                  <span class="ml-1" onClick={() => sorter.sortAndPrioritize()}>
                                    <SortArrow class="p-1 rounded-md" dir={sorter.dir}/>
                                  </span>
                                )
                                : null
                            })(sortState.sortersByColID[colDef.id])
                          }
                        </div>
                      </th>
                    )
                  })
                }
              </tr>

              {
                pagination.value.pageData.itemsThisPage.length === 0
                  ? (
                    <tr>
                      <td colspan="999">
                        <div class="flex items-center justify-center p-4"><span>Nothing found</span></div>
                      </td>
                    </tr>
                  )
                  : null
              }

              {
                pagination.value.pageData.itemsThisPage.map((entry, i) => {
                  const rowBgColor = i % 2 ? "bg-gray-100" : ""
                  return (
                    <tr key={entry.tournamentTeamID} data-test={entry.tournamentTeamID} class="overflow-y-visible">
                      {
                        colDefs.map(colDef => {
                          return (
                            <td class={`${rowBgColor} p-2 text-left align-top ${colDef.id === ColName.coaches || colDef.id === ColName.referees ? "whitespace-nowrap" : ""}`}>
                              {
                                getColDefHtml(colDef, entry)
                              }
                            </td>
                          )
                        })
                      }
                    </tr>
                  )
                })
              }
              {
                pagination.value.pageData.count_totalItems > 0
                  ? (
                    <tr>
                      <td colspan="999" class="p-2">
                        <div class="flex w-full">
                          <div class="ml-auto">
                            <SimplePaginationNav mut_pagination={pagination.value} mut_itemsPerPage={itemsPerPage} itemsPerPageOptions={itemsPerPageOptions}/>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                  : null
              }
            </table>
          </div>
        </div>
      )

    }
  }
})

function MenuLoaderFactory() {
  const __candidateCompsBySeasonUID : {[seasonUID: Guid]: undefined | Promise<Competition[]>} = {}
  const getComps = ({seasonUID}: {seasonUID: Guid}) => {
    if (__candidateCompsBySeasonUID[seasonUID]) {
      return __candidateCompsBySeasonUID[seasonUID]
    }
    else {
      return __candidateCompsBySeasonUID[seasonUID] = tournTeamListing_menu_competitions(axiosInstance, {seasonUID})
    }
  }

  const seasonSort = sortBy<Season>(_ => _.seasonID, "desc")
  const compSort = sortBy<Competition>(_ => parseIntOrFail(_.competitionID), "asc")

  async function getMenuSeasons(ax: AxiosInstance) : Promise<Season[]> {
    return await tournTeamListing_menu_seasons(ax).then(vs => vs.sort(seasonSort))
  }

  async function getMenuCompetitions(args: {seasonUID: Guid}) : Promise<Competition[]> {
    return await getComps(args).then(vs => vs.sort(compSort))
  }

  return {
    getMenuSeasons,
    getMenuCompetitions
  }
}
