import { defineComponent, reactive, ref, watch } from "vue";
import RefereeModalImpl from "./RefereeModalImpl.vue"

import { propsDef, emitsDef, type Props, RefereeSchedulerMode } from "./RefereeModalShared";
import { AutoModal, DefaultModalController } from "../UserInterface/Modal";
import { exhaustiveCaseGuard, vReqT } from "src/helpers/utils";
import { AugmentedGame } from "./RefereeScheduleTable.ilx";
import { CompetitionScope } from "src/interfaces/InleagueApiV1";

export const RefereeModal = defineComponent({
  props: {
    ...propsDef,
    // can be null here, but we don't forward it unless it is non-null
    augmentedGame: vReqT<AugmentedGame | null>(),
    showModal: vReqT<boolean>(),
  },
  emits: emitsDef,
  setup(props, {emit}) {
    const forwardedProps = ref<Props | null>(null)

    /**
     * `deep` might not be necessary, but we'd have to reason about that.
     */
    watch(() => props.augmentedGame, () => {
      if (!props.augmentedGame) {
        forwardedProps.value = null
      }
      else {
        forwardedProps.value = {
          teams: props.teams,
          field: props.field,
          augmentedGame: JSON.parse(JSON.stringify(props.augmentedGame)),
          refSlotOptionsForSelectedCompetition: props.refSlotOptionsForSelectedCompetition,
          searchConfig: props.searchConfig,
        }
      }
    }, {immediate: true, deep: true})

    const controller = reactive((() => {
      return DefaultModalController<void>({
        title: () => <>
          <div>Referee Slots</div>
          <div class="border-b"/>
        </>,
        content: () => {
          if (forwardedProps.value === null) {
            // we shouldn't actually ever observe this, owing to the `immediate:true` of the watch that assigns it
            return null;
          }
          else {
            return <RefereeModalImpl {...forwardedProps.value} onClose={() => emit("close")} onReloadGame={event => emit("reloadGame", event)}/>
          }
        }
      }, {onCloseCB: (close) => {
        // silly loop around, something like
        // "close"
        // -> get here, we're open, emit close event
        // parent gets close event, sets their "open" prop to false,
        // we notice it change to closed, we handle it by calling controller.close
        // -> back here now, but isOpen is false, so close the thing
        if (!isOpen) {
          close()
        }
        else {
          emit("close")
        }
      }})
    })())

    let isOpen = false;
    watch(() => props.showModal, () => {
      if (props.showModal) {
        isOpen = true
        controller.open()
      }
      else {
        if (isOpen) {
          isOpen = false
          controller.close()
        }
      }
    }, {immediate: true})

    return () => {
      return <AutoModal data-test="referee-modal" class="max-w-screen-xl" controller={controller}/>
    }
  }
})

export type RefereeCandidateSearchConfig = {
  activeOnly: boolean,
  activeRiskStatus: boolean,
  localOnly: boolean,
}

/**
 *
 * @param mode We shouldn't expose the modal to "self scheduler" users, so mode is mostly irrelevant. But it's probably good that we continue to clarify
 * our intent through the different involved components.
 */
export function refereeCandidateSearchConfig(mode: RefereeSchedulerMode, game: AugmentedGame) : RefereeCandidateSearchConfig {
  return {
    activeOnly: false,
    activeRiskStatus: mode === "self-scheduler" ? true
      : game.competition.refSchedulerAdminModeIgnoreRiskStatus ? false
      : true,
    localOnly: (() => {
      switch (game.competition.scopeID) {
        case CompetitionScope.Regional:
          return true;
        case CompetitionScope.Area:
          // fallthrough
        case CompetitionScope.National:
          // fallthrough
        case CompetitionScope.Sectional:
          return false;
        case "":
          // fallthrough
        case null:
          // handle unlikely potentially nullish values
          return false;
        default: exhaustiveCaseGuard(game.competition.scopeID)
      }
    })(),
  }
}
