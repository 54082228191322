import { ExtractPropTypes } from "vue";
import { AugmentedGame, RefSlotOption } from "./RefereeScheduleTable.ilx";
import * as iltypes from "src/interfaces/InleagueApiV1"
import { vOptT, vReqT } from "src/helpers/utils";
import { RefereeCandidateSearchConfig } from "./RefereeModal";

export const propsDef = {
  /**
   * todo: document meaning of this
   */
  teams: vReqT<string>(),
  /**
   * required?
   * todo: document meaning of this
   */
  field: vOptT<string>(),
  /**
   * the game from obj from which we pull ref info.
   * We make a deep copy of it here, and the form we pass it to writes directly into it.
   */
  augmentedGame: vReqT<AugmentedGame>(),
  refSlotOptionsForSelectedCompetition: vReqT<RefSlotOption[]>(),
  searchConfig: vReqT<RefereeCandidateSearchConfig>(),
} as const;

export const emitsDef = {
  /**
   * some action happend where we need to tell the parent to reload a game
   */
  reloadGame: (_: {gameID: iltypes.Guid}) => true,
  close: () => true,
} as const;

export type Props = ExtractPropTypes<typeof propsDef>

export type RefereeSchedulerMode = "admin" | "self-scheduler"
