/**
 * This is a combo box that only handles the case of searching for some inLeague user.
 * See also "AutoSearch2"
 */

import { defineComponent, ref, watch, computed } from 'vue'
import { vOptT, vReqT } from 'src/helpers/utils';

export const UserSearchComboBox = defineComponent({
  name: 'UserSearchComboBox',
  props: {
    label: vOptT(""),
    name: vReqT<string>(),
    type: vReqT<string>(),
    modelValue: vReqT<{displayValue: string; ID: string}>(),
    options: vReqT<{ID: string, firstName: string, lastName: string, email: string}[]>(),
    placeholder: vOptT(""),
    hasSearched: vOptT(false),
    autoComplete: vOptT(true)
  },
  emits: {
    input: (_: string | {displayValue: string, ID: string}) => true,
    assignID: (_: string) => true,
  },
  setup(props, ctx) {
    /**
     * This represents the index the mouse is hovering over, or the arrow keys have navigated to.
     * It is the index that will be selected on enter keypress, and is the "highlighted" option.
     * (hence we may not have already informed the parent that this option is now "the current value")
     */
    const selectedIndex = ref(0)
    const showDropdown = ref(true)

    const inputValue = computed({
      get() {
        return props.modelValue.displayValue
      },
      set(val: string) {
        ctx.emit('input', val)
        ctx.emit('assignID', '')
      },
    })

    const toggleDropdown = (bool: boolean) => {
      setTimeout(() => {
        showDropdown.value = bool
      }, 200)
    }

    const filteredOptions = computed(() => {
      const re = new RegExp(inputValue.value.trim(), "i");
      return props
        .options
        .filter(opt => {
          return re.test(opt.firstName)
            || re.test(opt.lastName)
            || re.test(opt.email)
            || re.test(`${opt.firstName} ${opt.lastName}`)
        })
    })

    const selectOptionWorker = (index: number) => {
      const selected = filteredOptions.value[index];
      if (!selected) {
        // can happen on enter keypress, if there are no options
        return;
      }

      ctx.emit('input', {
        displayValue: `${selected.firstName} ${selected.lastName}`,
        ID: selected.ID
      });

      toggleDropdown(false)
    }

    /**
     * select some option via explicit index,
     * generally in response to a {click,tap} of a particular option
     */
    const selectOptionViaIndex = (index: number) => {
      selectOptionWorker(index);
    }

    /**
     * on enter keypress, use the selected (i.e. "tentative") selection index as the target, and make the selection
     */
    const selectOptionViaEnterKeypress = () => {
      selectOptionWorker(selectedIndex.value);
    }

    /**
     * on down-arrow, update the tentative selection as appropriate
     */
    const increment = () => {
      if (selectedIndex.value + 1 < filteredOptions.value.length) {
        selectedIndex.value++
      } else {
        selectedIndex.value = 0
      }
    }

    /**
     * on up-arrow, update the tentative selection as appropriate
     */
    const decrement = () => {
      if (selectedIndex.value - 1 >= 0) {
        selectedIndex.value--
      } else {
        selectedIndex.value = filteredOptions.value.length - 1
      }
    }

    watch(
      () => props.hasSearched,
      val => {
        if (val) {
          showDropdown.value = true
        }
      }
    )

    // TODO: move up/down should adjust pane scroll position when necessary
    // (when moving to the "next" option where that option is outside of the visible area of the container)
    const handleInputKeyDown = (evt: KeyboardEvent) => {
      switch (evt.key) {
        case "Enter": {
          // vue-sfc handler def was: @keydown.enter.prevent="evt => selectOptionViaEnterKeypress()"
          // n.b. we expect to receive enter keypresses from
          evt.preventDefault()
          selectOptionViaEnterKeypress()
          return
        }
        case "ArrowDown": {
          // vue-sfc handler def was: @keydown.down.prevent="increment"
          evt.preventDefault()
          increment()
          return
        }
        case "ArrowUp": {
          // vue-sfc handler def was: @keydown.up.prevent="decrement"
          evt.preventDefault()
          decrement()
          return
        }
        default: {
          return;
        }
      }
    }

    const rootRef = ref<HTMLElement | null>(null)

    return () => {
      return (
        <div>
          <div class={{relative: showDropdown.value}} data-type={props.type} ref={rootRef}>
            <input
              type="text"
              class="mt-1 rounded-md shadow-sm text-black form-input block w-full"
              value={props.modelValue.displayValue}
              onInput={e => ctx.emit("input", {
                ID: props.modelValue.ID,
                displayValue: (e.target as HTMLInputElement).value || ""
              })}
              autocomplete="off"
              onKeydown={evt => handleInputKeyDown(evt)}
              placeholder={props.placeholder}
              onFocus={() => toggleDropdown(true)}
              onBlur={evt => {
                if (rootRef.value?.contains(evt.relatedTarget as any)) {
                  // This is like "clicked on the scroll bar in the options list",
                  // so we don't want to close the dropdown.
                  return;
                }

                toggleDropdown(false)
              }}
            />
            <ul
              class="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              v-show={showDropdown.value && filteredOptions.value.length && !props.modelValue.ID}
              tabindex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
              onClick={() => toggleDropdown(false)}
              onKeydown={evt => {
                // vue-sfc handler def was: @keydown.enter.prevent="toggleDropdown(false)"
                if (evt.key !== "Enter") {
                  return;
                }
                evt.preventDefault()
                toggleDropdown(false)
              }}
            >
              {filteredOptions.value.map((option, index) => {
                return <li
                  key={option.ID}
                  data-test={option.ID}
                  class={["text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9", index === selectedIndex.value ? "bg-gray-200" : ""]}
                  onMouseenter={() => { selectedIndex.value = index }}
                  onClick={() => selectOptionViaIndex(index)}
                >
                  {ctx.slots.item?.(option)}
                </li>
              })}

            </ul>
          </div>
        </div>
      )
    }
  },
})
